<template>
	<div class="payment-summary">
		<div class="payment-summary-row">
			<p class="mb-0">{{ $t('paymentSummary.reservedEnergy') }}</p>
			<div>{{ data.reservedEnergy }} kWh</div>
		</div>
		<div class="payment-summary-row" v-if="data.time">
			<p class="mb-0">{{ $t('paymentSummary.time') }}</p>
			<div>{{ data.time }} {{ $t('chargerView.minutes') }}</div>
		</div>
		<div class="payment-summary-row" v-if="priceSettings.parkingEnapled">
			<p class="mb-0">{{ $t('paymentSummary.amount') }}</p>
			<div>{{ data.amount }} {{ $t('currency') }}</div>
		</div>
		<div class="payment-summary-row" v-if="priceSettings.parkingEnapled">
			<p class="mb-0">{{ $t('paymentSummary.amountToHold') }}</p>
			<div>{{ priceSettings.parkingToHoldGross }} {{ $t('currency') }}</div>
		</div>
		<div class="payment-summary-row">
			<p class="mb-0">{{ $t('paymentSummary.moneyToHold') }}</p>
			<div>{{ moneyToHold }} {{ $t('currency') }}</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'PaymentSummaryCard',
	props: {
		data: Object,
		priceSettings: Object
	},
	computed: {
		moneyToHold() {
			if (this.priceSettings.parkingEnabled) {
				return this.priceSettings.parkingToHoldGross + this.data.amount;
			}
			return this.data.amount;
		}
	}
};
</script>

<style scoped>
.payment-summary {
	padding: 10px;
	max-width: 40rem;
	margin: 15px auto;
	box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
	border-radius: 5px;
}

.payment-summary-row {
	font-size: 1rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
	min-height: 50px;
	border-bottom: 1px solid #eee;
}
</style>
