<template>
	<button @click="click()" class="credit-card-btn" :style="isSelected ? { border: '3px solid var(--sky-500)' } : {}">
		<div class="credit-card-btn-header">
			<div>
				<span class="default-text-title smaller-text-title" v-if="defaultCard">{{ $t('cards.default-card') }}</span>
			</div>
			<div class="d-flex justify-content-end mb-0">
				<p class="mb-0" :style="{ 'font-size': '12px' }">{{$t('credit-card-button.personal-card')}}</p>
			</div>
		</div>
		<div class="credit-card-btn-body" :class="defaultCard ? 'mt-3' : 'mt-2'">
			<div class="centered chip-container">
				<img src="@/assets/chip.png" alt="" />
			</div>
			<div v-if="cardMask" class="card-mask-container2">{{ '•••• •••• •••• ' + cardMask.slice(-4) }}</div>
		</div>
		<div class="credit-card-btn-footer">
			<span :style="{ color: 'white', 'font-weight': 'normal', 'margin-right': '8px' }">{{ formatDate(cardExpiry) }}</span>
		</div>
	</button>
</template>
<script>
export default {
	props: {
		cardMask: String,
		cardExpiry: String,
		defaultCard: Boolean,
		selected: Boolean
	},
	data() {
		return {
			formattedCardMask: ''
		};
	},
	computed: {
		isSelected() {
			return this.selected ? this.selected : false;
		}
	},
	methods: {
		formatDate(dateStr) {
			const date = new Date(dateStr);
      return `${date.getFullYear()}/${date.getMonth() + 1}`;
		},
		click() {
			this.$emit('click');
		}
	},
	emits: ['click']
};
</script>
<style scoped>
.credit-card-btn {
	position: relative;
	border: none;
	display: flex;
	flex-direction: column;
	padding: 0;
	width: 200px;
	height: 100px;
	border-radius: 8px;
	overflow: hidden;
	background: linear-gradient(to right, #3a0b60bd, #d176ba), url('~@/assets/word.png');
  background-position: center;
  background-repeat: no-repeat;
	background-size: cover;
	color: white;
	font-weight: normal;
}

.credit-card-btn-header {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 4px 5px;
}

.credit-card-btn-body {
	display: flex;
	gap: 10px;
	margin: 0 auto;
}

.chip-container {
	width: 28px;
	height: 20px;
	overflow: hidden;
	border-radius: 4px;
}

.chip-container img {
	width: 100%;
}

.smaller-text-title {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 10px;
	padding: 2px 5px;
}

.credit-card-btn-footer {
	position: absolute;
	bottom: 0;
	width: 100%;
	display: flex;
	justify-content: end;
	align-items: center;
	font-size: 12px;
}
</style>
