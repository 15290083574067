<template>
	<div :class="open ? 'dialog-wrapper' : ''">
		<dialog :open="open" class="dialog">
			<div class="dialog-header flex-between">
				<h3 class="dialog-title">{{ title }}</h3>
				<button @click="close" class="dialog-close-btn">
					<i class="pi pi-times"></i>
				</button>
			</div>
			<div class="dialog-body flex-1">
				<p v-if="detail" class="mb-0">{{ detailText }}</p>
				<slot v-else></slot>
			</div>
			<div class="dialog-footer">
				<button @click="confirm" class="confirm-btn">{{ confirmButtonText }}</button>
			</div>
		</dialog>
	</div>
</template>
<script>
export default {
	props: {
		open: Boolean,
		title: String,
		detail: String,
		confirmButton: String
	},
	computed: {
		confirmButtonText() {
			return this.confirmButton || 'Igen';
		},
		detailText() {
			return this.detail ? this.detail : 'Biztosan folytatja?';
		}
	},
	methods: {
		close() {
			this.$emit('close');
		},
		confirm() {
			this.$emit('confirm');
		}
	},
	emits: ['close', 'confirm']
};
</script>
<style scoped>
.dialog-wrapper {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	backdrop-filter: blur(0.2px);
	background-color: rgba(97, 97, 97, 0.3);
	justify-content: center;
	align-items: center;
	z-index: 1000;
}

.dialog {
	width: 80%;
	min-width: 250px;
	max-width: 700px;
	min-height: 230px;
	height: 240px;
	padding: 12px 20px;
	border: 1px solid rgb(235, 235, 235);
	border-radius: 8px;
	box-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
}

/* .dialog-header {
} */

.dialog-title {
	font-size: 1.5rem;
	font-weight: normal;
}

.dialog-close-btn {
	background: transparent;
	border: none;
}

.dialog-body {
	flex-grow: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 70%;
}

.dialog-footer {
	display: flex;
	justify-content: end;
}

.confirm-btn {
	background-color: var(--active);
	color: white;
	border: none;
	border-radius: 6px;
	padding: 6px 20px;
}
</style>
