import { render, staticRenderFns } from "./WalletCardButton.vue?vue&type=template&id=b2c8de38&scoped=true"
import script from "./WalletCardButton.vue?vue&type=script&lang=js"
export * from "./WalletCardButton.vue?vue&type=script&lang=js"
import style0 from "./WalletCardButton.vue?vue&type=style&index=0&id=b2c8de38&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b2c8de38",
  null
  
)

export default component.exports