import { render, staticRenderFns } from "./VuexLicenseView.vue?vue&type=template&id=1b18baaa&scoped=true"
import script from "./VuexLicenseView.vue?vue&type=script&lang=js"
export * from "./VuexLicenseView.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b18baaa",
  null
  
)

export default component.exports