<template>
	<div v-if="parking">
		<div class="p-2 mb-4">
			<div class="no-shadow-container">
				<div class="card-body">
					<!--        STARTING IN X SECONDS  -->
					<div v-if="starting" class="parking-card-title text-center">
						<h6 class="card-title my-1">
							{{ $t('parking-details.starting', [timeUntilStart]) }}
						</h6>
					</div>

					<template v-if="parking.parkingStatus === 'STARTED'">
						<div class="parking-card-title text-center">
							<h6 class="mb-1 text-uppercase text-muted" style="font-size: 0.8rem">
								{{ $t('parking-details.totalTime') }}
							</h6>
							<h2 class="card-title m-0">
								{{ elapsedTime }}
							</h2>
							<div class="text-danger">
								<h6 class="mt-1 mb-0 text-uppercase text-muted" style="font-size: 0.7rem">
									{{ $t('parking-details.discountTime') }}
								</h6>
								<h5 class="card-title m-0">
									{{ discountTime }}
								</h5>
							</div>
						</div>

						<div class="info-container centered flex-column mx-auto my-3" style="max-width: 400px" v-if="parking.chargeCode">
							<p><i class="pi pi-info-circle" style="color: var(--info-icon)"></i> {{ $t('parking-details.charge-code') }}</p>
							<h4>
								{{ parking.chargeCode }}
							</h4>
						</div>
						<div class="start-charging d-flex justify-content-center mx-auto mb-3">
							<!-- <button class="btn btn-success" @click="startCharging">
								<font-awesome-icon class="fa mr-3" icon="charging-station" style="vertical-align: middle; margin-bottom: 2px" />
								{{ $t('parking-details.start-charging') }}
							</button> -->
							<PrimeButton @click="startCharging" :style="{ 'border-radius': '30px', 'min-height': '52px', 'min-width': '200px', padding: '0px 20px', 'background-color': '#04af43', border: 'none' }" class="new-btn centered">
								<i class="pi pi-bolt"></i>
								{{ $t('parking-details.start-charging') }}
							</PrimeButton>
						</div>
					</template>

					<template v-if="parking.parkingStatus === 'FINISHING'">
						<div class="parking-card-title text-center" v-if="!showOpenButton">
							<h4 class="card-title my-1">
								{{ $t('parking-details.thanks-for-parking') }}
							</h4>
						</div>
						<div class="parking-card-title text-center" v-else>
							<h4 class="card-title my-1">
								{{ $t('parking-details.please-leave') }}
							</h4>
						</div>
					</template>

					<div class="card-text">
						<div class="parking-card-text">
							<div class="gray-row">
								<p class="mb-0">{{ $t('parking-details.est-cost') }}</p>
								<p class="mb-0">{{ parking.amount }} Ft</p>
							</div>
							<div class="gray-row">
								<p class="mb-0">{{ $t('parking-details.gate-name') }}</p>

								<p class="mb-0">{{ parking.parkingLot.name }}</p>
							</div>
							<div v-if="tariff" class="gray-row">
								<p class="mb-0">{{ $t('parking-details.price') }}</p>
								<p class="mb-0">
									<small v-if="tariff.freeParking" class="text-success font-weight-bold">{{ $t('parkingView.free') }}</small>
									<small v-else class="text-danger font-weight-bold">{{ $t('location-panel.price-parking', [tariff.grossUnitParking]) }}</small>
								</p>
							</div>
							<div v-if="tariff" class="gray-row">
								<p class="mb-0">{{ $t('parking-details.freeMinutes') }}</p>
								<p class="mb-0">
									{{ freeParkingTime }}
								</p>
							</div>
						</div>
					</div>

					<template v-if="parking.parkingStatus === 'STARTING'">
						<div v-if="openingGate" class="btn btn-success disabled location-panel-parking-button">
							<font-awesome-icon class="fa-spin mr-2" icon="spinner" />
							{{ $t('parkingView.openingGate') }}
						</div>
						<template v-else-if="showOpenButton">
							<button v-if="canOpen" class="btn btn-success location-panel-parking-button w-100" @click="openGate">
								<font-awesome-icon class="fa mr-3" icon="unlock" style="vertical-align: middle; margin-bottom: 2px" />
								{{ $t('parkingView.openGate') }}
							</button>

							<div v-else class="alert alert-info">
								<font-awesome-icon class="fa-spin mr-2" icon="spinner" />
								{{ $t('parkingView.stopNearGate') }}
							</div>
						</template>
					</template>

					<template v-if="parking.parkingStatus === 'STARTED'">
						<!--  PAYMENT STATUS  -->
						<template v-if="parking.paymentStartedAt">
							<!-- PAYMENT STARTED -->
							<template v-if="!parking.paymentFailed && !parking.paymentFinishedAt">
								<div>
									<div class="alert alert-info mb-2">
										<font-awesome-icon class="fa-spin mr-2" icon="spinner" />
										{{ $t('parkingView.paymentStarted') }}
									</div>
								</div>

								<button v-if="parking.paymentMethod === 'SIMPLEPAY' && parking.paymentUrl" class="btn btn-success location-panel-parking-button" @click="stopParking">
									<font-awesome-icon class="fa mr-3" icon="money-check-alt" style="vertical-align: middle; margin-bottom: 2px" />
									{{ $t('parkingView.paymenturl') }}
								</button>
							</template>

							<!-- PAYMENT FINISHING -->
							<div v-else-if="!parking.paymentFailed && parking.paymentFinishedAt" class="d-flex justify-content-center flex-column">
								<div class="alert alert-info">
									<font-awesome-icon class="fa-spin mr-2" icon="spinner" />
									{{ $t('parkingView.paymentFinishing') }}
								</div>
							</div>

							<!-- PAYMENT FAILED -->
							<div v-else-if="parking.paymentFailed">
								<div class="alert alert-danger mb-3">
									<font-awesome-icon class="mr-2" icon="exclamation-triangle" />
									{{ $t('parkingView.paymentFailed') }}
								</div>
							</div>
						</template>

						<!--  PAYMENT PROCESS  -->
						<template v-if="!parking.paymentStartedAt || parking.paymentFailed">
							<template v-if="!canStop">
								<div class="alert alert-info">
									<font-awesome-icon class="fa-spin mr-2" icon="spinner" />
									{{ $t('parkingView.chargingInProgress') }}
								</div>
							</template>

							<template v-else-if="isFree && !casualUser">
								<div class="w-100 d-flex justify-content-center mt-3">
									<button class="btn btn-danger location-panel-parking-button" @click="stopParking">
										<font-awesome-icon class="fa mr-3" icon="pause" style="vertical-align: middle; margin-bottom: 2px" />
										{{ $t('parkingView.finish') }}
									</button>
								</div>
							</template>
							<div v-else-if="stopBtnVisible" class="mt-3" style="max-width: 400px; margin: 0 auto">
								<!-- <button class="btn btn-danger location-panel-parking-button" @click="stop()">
									<font-awesome-icon class="fa mr-3" icon="pause" style="vertical-align: middle; margin-bottom: 2px" />
									{{ $t('parkingView.stopParking') }}
								</button> -->
								<PrimeButton @click="stop()" class="centered w-100" :style="{ 'border-radius': '6px', 'min-height': '52px', 'min-width': '200px', padding: '0px 20px', 'background-color': '#ef4444', border: 'none' }">
									<i class="pi pi-power-off"></i>
									{{ $t('parkingView.stopParking') }}
								</PrimeButton>
							</div>

							<CasualParkingSurvey v-if="tariff && stopped && casualUser" :app="app" :msgs="msgs" :priceSettings="tariff" @email="email = $event" @selectedAll="casualSurveySelected = $event" />

							<template v-if="isFree && stopped && email">
								<div class="w-100 d-flex justify-content-center mt-3">
									<button class="btn btn-danger location-panel-parking-button" @click="stopParking">
										<font-awesome-icon class="fa mr-3" icon="pause" style="vertical-align: middle; margin-bottom: 2px" />
										{{ $t('parkingView.finish') }}
									</button>
								</div>
							</template>

							<template v-else-if="stopped">
								<div v-if="showPaymentMethods" class="d-flex flex-wrap">
									<div class="my-1 mx-auto">
										<button :class="{ highlightselected: selectedMode === 'SIMPLEPAY' }" class="credit-card credit-card-simple simple-pay" @click="selectedMode = 'SIMPLEPAY'">
											<img alt="simplepay logo" class="img-fluid" src="../assets/simplepay/simplepay.png" />
										</button>
									</div>
									<div v-for="card in creditCards" :key="card.cardId" class="my-1 mx-auto">
										<button :class="{ highlightselected: selectedMode === 'CARD' && card.cardId === selectedCard }" class="credit-card credit-card-card" @click="selectCard(card)">
											<span class="card-number">{{ card.cardMask }}</span>
											<span class="card-comment">{{ $t('charger-start.payWithCard') }}</span>
											<span class="card-expiry">{{ formatDate(card.expiry) }}</span>
										</button>
									</div>
									<div v-if="showWallet" class="my-1 mx-auto">
										<button :class="{ highlightselected: selectedMode === 'WALLET' }" class="credit-card credit-card-wallet" @click="selectedMode = 'WALLET'">
											<span class="card-number">{{ $t('menu.wallet') }}</span>
											<span class="card-ballance">{{ walletBalance }}<span style="font-size: 0.8em"> Ft</span></span>
										</button>
									</div>
								</div>

								<template v-if="selectedMode">
									<div v-if="!casualUser" class="form-group mt-4">
										<select v-if="billingDatas && billingDatas.length > 0" v-model="selectedBillingData" class="form-control">
											<option v-for="billingData in billingDatas" :key="billingData.id" :value="billingData">{{ billingData.payerName }}, {{ billingData.address.full }}</option>
										</select>

										<div v-if="!billingDatas || (billingDatas || {}).length === 0" class="alert alert-warning">
											<div>
												<font-awesome-icon class="mr-2" icon="exclamation-triangle" size="2x" />
												<span style="font-size: 1.1rem">{{ $t('charger-view.no-billing-inf') }}</span>
											</div>
											<router-link to="/billing">
												{{ $t('charger-view.please-add-billing-info') }}
											</router-link>
										</div>
									</div>

									<div class="w-100 d-flex justify-content-center mt-3">
										<button class="btn btn-success location-panel-parking-button" @click="stopParking">
											<font-awesome-icon class="fa mr-3" icon="money-check-alt" style="vertical-align: middle; margin-bottom: 2px" />
											{{ $t('parkingView.pay') }}
										</button>
									</div>
								</template>
							</template>
						</template>
					</template>

					<template v-if="parking.parkingStatus === 'FINISHING'">
						<div v-if="parkingWillFinishIn && (openingGate || showOpenButton)">
							<div class="alert alert-info">
								<font-awesome-icon class="mr-2 text-warning" icon="exclamation-triangle" />
								<div>
									<h6>
										{{
											$t('parkingView.parkingWilRestartIn', {
												x: parkingWillFinishIn
											})
										}}
									</h6>
									<p>
										{{ $t('parkingView.pleaseLeave') }}
									</p>
								</div>
							</div>
						</div>
						<div v-if="openingGate" class="btn btn-success disabled location-panel-parking-button">
							<font-awesome-icon class="fa-spin mr-2" icon="spinner" />
							{{ $t('parkingView.openingGate') }}
						</div>
						<template v-else-if="showOpenButton">
							<button v-if="canOpen" class="btn btn-success location-panel-parking-button w-100" @click="openGate">
								<font-awesome-icon class="fa mr-3" icon="unlock" style="vertical-align: middle; margin-bottom: 2px" />
								{{ $t('parkingView.openGate') }}
							</button>

							<div v-else class="alert alert-info">
								<font-awesome-icon class="fa-spin mr-2" icon="spinner" />
								{{ $t('parkingView.stopNearGate') }}
							</div>
						</template>
					</template>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import CasualParkingSurvey from '@/components/CasualParkingSurvey.vue';

export default {
	name: 'ParkingDetailsPanel',
	components: { CasualParkingSurvey },
	props: {
		shouldRoute: Boolean,
		app: Object,
		msgs: Object
	},
	data() {
		return {
			openingGate: false,
			email: null,
			casualSurveySelected: false,
			canOpen: false,
			timeUntilStart: null,
			timeUntilStartTimer: null,
			billingDatas: [],
			selectedBillingData: null,
			selectedMode: null,
			selectedCard: null,
			walletBalance: 0,
			creditCards: [],
			stopped: false,
			refreshTimer: null,
			canStop: true,
			canOpenInterval: null
		};
	},
	computed: {
		tariff() {
			if (!this.parking) return null;
			return this.parking.parkingTariff;
		},
		parkingWillFinishIn() {
			if (!this.parking) return null;
			if (!this.parking.parkingWillFinishAt) return null;
			const time = new Date(this.parking.parkingWillFinishAt);
			return `${time.getHours().toString().padStart(2, '0')}:${time.getMinutes().toString().padStart(2, '0')}`;
		},
		freeParkingTime() {
			const minsT = this.$t('parkingView.minutes');
			const hoursT = this.$t(`parkingView.hours`);
			if (!this.tariff) return `0 ${minsT}`;
			const minutes = this.tariff.freeMinutes;
			if (minutes === 0) return `0 ${minsT}`;
			if (minutes < 60) return `${minutes} ${minsT}`;
			const hours = Math.floor(minutes / 60);
			if (minutes % 60 === 0) return `${hours} ${hoursT}`;
			return `${Math.floor(minutes / 60)} ${hoursT} ${minutes % 60} ${minsT}`;
		},
		showWallet() {
			if (!this.parking) return false;
			return this.walletBalance >= this.parking.amount;
		},
		starting() {
			return this.parking.parkingStatus === 'STARTING' && this.parking.inOpenRequestSent;
		},
		stopBtnVisible() {
			return this.parking.parkingStatus === 'STARTED' && !this.stopped;
		},
		parking() {
			return this.$store.getters.getParking;
		},
		showPaymentMethods() {
			return (this.casualUser && this.stopped && this.email && this.casualSurveySelected) || (!this.casualUser && this.stopped);
		},
		showOpenButton() {
			return (this.parking.parkingStatus === 'FINISHING' && !this.parking.outOpenRequestSent) || (this.parking.parkingStatus === 'STARTING' && !this.parking.inOpenRequestSent);
		},
		elapsedTime() {
			if (!this.parking || !this.parking.parkingFrom) {
				return null;
			}
			let now = new Date();
			if (this.parking.parkingTo) {
				now = new Date(this.parking.parkingTo);
			}
			const start = Date.parse(this.parking.parkingFrom);
			const diff = Math.abs(now - start);
			let hours = Math.floor(diff / (1000 * 60 * 60));
			let minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));

			if (isNaN(hours)) hours = 0;
			if (isNaN(minutes)) minutes = 0;

			return `${hours} ${this.$t('parkingView.hours')} ${minutes} ${this.$t('parkingView.minutes')}`;
		},
		discountTime() {
			let discountHours = this.parking.discountHours;
			const freeMinutes = (this.tariff || {}).freeMinutes || 0;
			if (freeMinutes > 0) {
				discountHours += freeMinutes / 60;
			}
			const hours = Math.floor(discountHours);
			const minutes = Math.floor((discountHours - hours) * 60);

			return `${hours} ${this.$t('parkingView.hours')} ${minutes} ${this.$t('parkingView.minutes')}`;
		},
		isFree() {
			if (!this.parking) return false;
			return this.parking.free || this.parking.amount === 0;
		}
	},
	asyncComputed: {
		async loggedInUserData() {
			return await this.$store.getters.getLoggedInUserData;
		},
		async casualUser() {
			if (!(await this.loggedInUserData)) return true;
			return this.loggedInUserData.casualUser;
		}
	},
	watch: {
		parking() {
			this.subToCanOpen();
		}
	},
	methods: {
		startCharging() {
			this.$router.push({ name: 'index', params: { command: 'showLocation', locationId: this.parking.parkingLot.location.id, tab: 'charger' } });
		},
		stop() {
			this.stopped = true;
		},
		async stopParking() {
			const parking = await this.$rest.stopParking((this.selectedBillingData || {}).id, this.selectedMode, this.selectedCard, this.email);
			if (!parking) return;
			if (!this.parking.paymentFailed && this.parking.paymentUrl) {
				window.location.href = this.parking.paymentUrl;
			}
		},
		selectCard(card) {
			this.selectedMode = 'CARD';
			this.selectedCard = card.cardId;
		},
		async openGate() {
			this.$ws.command('openGate');
			this.openingGate = true;
			setTimeout(() => {
				this.openingGate = false;
			}, 5000);
		},
		startTimeUntilStartTimer() {
			this.timeUntilStartTimer = setInterval(() => {
				this.calculateTimeUntilStart();
			}, 1000);
		},
		calculateTimeUntilStart() {
			if (!this.parking || !this.parking.inOpenRequestSent || !this.parking.parkingLot) {
				this.timeUntilStart = null;
				return;
			}
			const now = new Date();
			const start = Date.parse(this.parking.inOpenRequestSent);
			const seconds = Math.abs(now - start) / 1000;

			this.timeUntilStart = Math.max(0, Math.round(this.parking.parkingLot.parkingTimeInSeconds - seconds));

			if (this.timeUntilStart === 0) {
				clearInterval(this.timeUntilStartTimer);
			}
		},
		async listBillingDatas() {
			//console.log('listBillingDatas', await this.casualUser)
			if (this.casualUser) return;
			this.$rest.listBillingDataForCurrentUser((responseData) => {
				this.billingDatas = responseData ? responseData : [];
				this.selectedBillingData = null;

				for (var i = 0; i < this.billingDatas.length; i++) {
					this.selectedBillingData = this.billingDatas[i];
					if (this.selectedBillingData.defaultBillingData) {
						break;
					}
				}
			});
		},
		async requestWalletBalance() {
			if (await this.casualUser) return;
			this.$rest.getWalletBalance((responseData) => {
				if (responseData.result) {
					this.walletBalance = responseData.data.ballance;
				}
			});
		},
		async requestCreditCards() {
			if (await this.casualUser) return;
			this.$rest.getCreditCards((data) => {
				this.creditCards = data;
			});
		},
		onCanOpen(canOpen) {
			this.canOpen = canOpen;
		},
		formatDate(dateStr) {
			const date = new Date(dateStr);
			return `${date.getFullYear()}/${date.getMonth() + 1}`;
		},
		subToCanOpen() {
			if (!this.parking) {
				this.$ws.unsubscribe('/can/open', this.onCanOpen);
				return;
			}

			this.$ws.subscribe('/can/open', this.onCanOpen);
			this.$ws.command('getCanOpen');
			this.canOpenInterval = setInterval(() => {
				if (this.showOpenButton) {
					this.$ws.command('getCanOpen');
				}
			}, 1000);
		},
		subToCanStop() {
			if (!this.parking) {
				this.$ws.unsubscribe('/can/stop', this.onCanStop);
				return;
			}

			this.$ws.subscribe('/can/stop', this.onCanStop);
			setTimeout(() => {
				this.$ws.command('getCanStop');
			}, 200);
			clearInterval(this.canStopInterval);
			this.canStopInterval = setInterval(() => {
				this.$ws.command('getCanStop');
			}, 3000);
		},
		refresh(count = 0) {
			this.$ws.command('getParkingSession');

			clearTimeout(this.refreshTimer);
			if (count > 30) return console.error('refresh timeout');

			this.refreshTimer = setTimeout(() => {
				if (this.parking) return;
				this.refresh(count + 1);
			}, 1000);
		},
		onCanStop(canStop) {
			this.canStop = canStop;
		}
	},
	mounted() {
		this.refresh();
		this.subToCanStop();
		this.startTimeUntilStartTimer();
		this.listBillingDatas();
		this.requestWalletBalance();
		this.requestCreditCards();

		this.$ws.onOpen(() => {
			this.refresh();
			this.subToCanStop();
			this.subToCanOpen();
		});
	},
	beforeDestroy() {
		clearInterval(this.canStopInterval);
		clearInterval(this.canOpenInterval);
		this.$ws.unsubscribe('/can/open', this.onCanOpen);
		this.$ws.removeOnOpen(this.refresh);
		clearInterval(this.elapsedTimeTimer);
	},
	emits: []
};
</script>

<style scoped>
.parking-panel {
	border-radius: 15px;
}

@-webkit-keyframes progress-bar-stripes-back {
	from {
		background-position: 0 0;
	}
	to {
		background-position: 1rem 0;
	}
}

@keyframes progress-bar-stripes-back {
	from {
		background-position: 0 0;
	}
	to {
		background-position: 1rem 0;
	}
}

.progress-bar-animated {
	-webkit-animation: progress-bar-stripes-back 1s linear infinite !important;
	animation: progress-bar-stripes-back 1s linear infinite !important;
}

.parking-progress-text {
	margin-top: 0.5rem;
	font-weight: bold;
	color: darkblue;
}

.parking-progress-text-finished {
	margin-top: 0.5rem;
	font-weight: bold;
	color: green;
}

.bigger-alert {
	font-size: 1.3rem;
}

.parking-progress-container {
	margin-bottom: 3rem;
}

.bigger-font {
	font-size: 1.2rem;
}

.parking-progress {
	height: 20px !important;
	border-radius: 1rem !important;
}

.parking-card-title {
	margin-bottom: 1.3rem;
	margin-top: 1rem;
}

.stop-btn-row {
	margin-top: 3rem;
}

.parking-card-text {
	display: flex;
	flex-direction: column;
	gap: 5px;
	max-width: 35rem;
	margin: auto;
	margin-bottom: 10px;
}

.spin-icon {
	margin-top: 1rem;
}

.parking-card-container {
	max-width: 40rem;
	margin: auto;
	box-shadow: 0 0 5px grey;
	background-color: lightcyan;
	border-radius: 10px;
}

.credit-card.simple-pay,
.credit-card {
	height: 60px !important;
	width: 7rem !important;
}

.credit-card-wallet {
}

.card-number {
	top: 3px !important;
}

.highlightselected {
	border: 2px solid #28a745 !important;
}

.card-number {
	font-size: 11px;
}

.card-comment {
	font-size: 8px;
}

.card-expiry {
	font-size: 8px;
}

.location-panel-parking-button {
	width: 100%;
}
</style>
