<template>
	<div>
		<div style="position: relative" class="p-2 rounded-lg charger-card-container">
			<div>
				<div v-if="!charger.shortName" class="font-weight-bold">
					{{ charger.name }}
				</div>

				<div class="media">
					<div class="location-panel-charger-name d-flex flex-column justify-content-center align-items-center align-self-center mr-2" :style="connectorStateClass === 'connector-state-preparing' ? 'background-color: var(--mode-color); color: white; border-color: var(--mode-color); box-shadow: 0 0 4px rgba(0, 0, 0, 0.9);' : ''" v-if="charger.shortName">
						{{ charger.shortName }}
					</div>

					<div class="media-body align-self-center">
						<div v-if="connectorStateKey === 'NEW_NOT_CONNECTED'" class="connector-state-unavailable">
							{{ $t('location-panel.charger-state-new') }}
						</div>
						<div v-else-if="connectorStateKey === 'UNKNOWN'" :class="['connector-state', connectorStateClass]">
							{{ $t('location-panel.communicationError') }}
						</div>
						<div v-else-if="connectorState" class="new-green" :class="['connector-state', connectorStateClass]">
							{{ connectorState }}
							<span v-if="connectorStateClass === 'connector-state-preparing'"> - {{ $t('location-panel.cable-connected') }}</span>
						</div>

						<template v-if="charger.tariff">
							<div v-if="charger.tariff.freeCharging" class="text-success">{{ $t('location-panel.free') }}</div>
							<div v-else class="text-danger">{{ $t('location-panel.price', [charger.tariff.unitChargingGross]) }}</div>
						</template>

						<div class="d-flex">
							<div>{{ performance }} {{ currentType }}</div>

							<div v-for="(outlet, index) in outlets" :key="index" class="outlet-item ml-3">
								<img v-if="outlet === 'Type 2'" class="charger-head-location" src="../assets/connector/TYPE2_ico.png" />
								<img v-if="outlet === 'CHAdeMO'" class="charger-head-location" src="../assets/connector/CHADEMO_ico.png" />
								<img v-if="outlet === 'CCS'" class="charger-head-location" src="../assets/connector/CCS_ico.png" />
								<span>{{ outlet }}</span>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div v-if="canStart">
				<div v-if="!charger.tariff" class="alert alert-warning mt-1">
					<font-awesome-icon icon="exclamation-triangle" size="lg" class="mr-2" />
					{{ $t('location-panel.error-no-tariff') }}
				</div>

				<template v-else-if="!thereIsRunningSession">
					<button @click="$emit('showStartCharging', charger.id)" class="btn stretched-link border-none start-charging-btn" :style="{ 'background-color': 'var(--newbtn-green)', 'min-width': '250px', border: 'none' }" :class="this.connectorStateKey === 'PREPARING' ? 'btn-success' : 'btn-secondary'">
						<i class="pi pi-play"></i>
						{{ $t('chargerView.startCharging') }}
					</button>
				</template>

				<div v-else-if="thereIsRunningSession" class="alert alert-warning mt-1 mb-0">
					<font-awesome-icon icon="exclamation-triangle" size="lg" class="mr-2" />
					{{ $t('location-panel.error-already-charging') }}
					<div class="text-center">
						<button @click="navigateToChargeHistory()" class="btn btn-outline-secondary">
							<font-awesome-icon icon="chevron-left" />
							{{ $t('chargerView.goToChargeHistory') }}
						</button>
					</div>
				</div>
			</div>
		</div>

		<div style="position: relative" class="mt-1">
			<button class="charger-info-toggle-button" @click="toggleInfo(charger.id)">
				<font-awesome-icon icon="chevron-down" class="mr-1 animated-flip" :class="{ 'do-flip': showInfo }" />
				<template v-if="showInfo">
					{{ $t('location-panel.hide-details') }}
				</template>
				<template v-else>
					{{ $t('location-panel.more-details') }}
				</template>
			</button>

			<div :id="'chargerDetails-' + charger.id" class="collapse row">
				<div v-if="charger.tariffLines && charger.tariffLines.length > 0" class="col-12">
					<hr />
					<div>{{ $t('location-panel.charger-tariff') }}:</div>
					<ul class="mb-0">
						<li v-for="tl in charger.tariffLines" :key="tl">{{ tl }}</li>
					</ul>
				</div>

				<div class="col-md-6 col-xl-4" v-for="tv in traitDetails" :key="tv.trait.id">
					<hr />
					{{ tv.trait.name }}:
					<TraitValue :tv="tv" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import jQuery from 'jquery';
import TraitValue from '@/components/TraitValue.vue';
import { GlobalEventEmitter } from '@/plugins/GlobalEventEmitter';

export default {
	name: 'LocationPanelCharger',
	props: { charger: Object },
	components: { TraitValue },
	data() {
		return {
			performance: '',
			currentType: '',
			outlets: [],
			connectorState: '',
			connectorStateKey: '',
			thereIsRunningSession: true,
			traitDetails: [],
			showInfo: false,
			showPanel: true
		};
	},
	methods: {
		navigateToChargeHistory() {
			this.$router.push('/charge-history');
		},
		toggleInfo(chargerId) {
			this.showInfo = !this.showInfo;
			jQuery('#chargerDetails-' + chargerId).collapse(this.showInfo ? 'show' : 'hide');
		},
		togglePanel(chargerId) {
			this.showPanel = !this.showPanel;
			jQuery('#chargerPanel-' + chargerId).collapse(this.showPanel ? 'show' : 'hide');
		},
		isTraitValueEmpty(tv) {
			if (!tv || !tv.trait || !tv.trait.type) {
				return true;
			}
			if (tv.trait.type == 'TEXT' && !tv.value) {
				return true;
			}
			if (tv.trait.type == 'LIST_SINGLE' && !tv.listItem) {
				return true;
			}
			if (tv.trait.type == 'LIST_MULTIPLE' && !tv.listItems) {
				return true;
			}
			if (tv.trait.type == 'LIST_CUSTOM' && !tv.listStrings) {
				return true;
			}
			return false;
		},
		onMounted() {
			this.$rest.getCurrentCharge(false, (responseData) => {
				if (!responseData) {
					this.thereIsRunningSession = false;
				}
			});

			if (this.charger) {
				if (this.charger.staticTraits) {
					this.traitDetails = [];

					for(var i = 0; i < this.charger.staticTraits.length; i++) {
						var tv = this.charger.staticTraits[i]
						if(tv.trait.identifier === 'outlets') {
							this.outlets = tv.listItems
						}
						else if(tv.trait.identifier === 'performance') {
							this.performance = tv.numericValue + ' ' + tv.trait.unit
						}

						if (tv.trait.major) {
							this.traitDetails.push(tv);
						}
					}
				}

				if(this.charger.deviceType) {
					this.currentType = this.charger.deviceType.currentType
				}

				if(this.charger.status) {
					this.connectorStateKey = this.charger.status.value
					this.connectorState = this.charger.status.title
				}

				this.traitDetails = this.traitDetails.filter((tv) => !this.isTraitValueEmpty(tv));
				this.traitDetails.sort((tv1, tv2) => tv1.trait.orderNumber - tv2.trait.orderNumber);
			}
		}
	},
	created() {
		GlobalEventEmitter.$on('refreshLocationPanelCharger', () => {
			this.onMounted();
		});
	},
	mounted() {
		this.onMounted();
	},
	computed: {
		connectorStateClass() {
			if (this.connectorStateKey === 'AVAILABLE') {
				return 'connector-state-available';
			}
			if (this.connectorStateKey === 'OCCUPIED') {
				return 'connector-state-unavailable';
			}
			if (this.connectorStateKey === 'RESERVED' || this.connectorStateKey === 'ERROR' || this.connectorStateKey === 'UNAVAILABLE' || this.connectorStateKey === 'UNKNOWN' || this.connectorStateKey === 'OUT_OF_SERVICE') {
				return 'connector-state-unavailable';
			}
			if (this.connectorStateKey === 'PREPARING') {
				return 'connector-state-preparing';
			}
			return 'connector-state-other';
		},
		canStart() {
			if(!this.charger.tariff) {
				return false
			}

			if(this.connectorStateKey === 'AVAILABLE' || this.connectorStateKey === 'PREPARING') {
				return true
			}

			if(this.connectorStateKey === 'FINISHING' && this.charger.deviceType && this.charger.deviceType.canStartNewChargeWhileInFinishingState) {
				return true
			}

			return false
		}
	},
	emits: ['hideLocationPanel', 'showStartCharging']
};
</script>

<style>
.location-panel-charger-name {
	font-size: 26px;
	font-weight: bold;
	width: 50px;
	height: 50px;
	border-radius: 50%;
	border: 2px solid #333333;
}

.connector-state {
	font-size: 18px;
	font-weight: bold;
}

.connector-state-available {
	color: var(--mode-color);
}

.connector-state-unavailable {
	color: #b21914;
}

.connector-state-other {
	color: #aaaaaa;
}

.connector-state-preparing {
	color: var(--mode-color);
}

.outlet-item {
	white-space: nowrap;
}

.outlet-item img {
	vertical-align: middle;
	margin-right: 4px;
}

.outlet-item span {
	font-size: 0.9rem;
	vertical-align: middle;
}

.location-panel-charger-button {
	font-size: 16px;
	font-weight: bold;
	padding: 15px 30px;
	margin: 0 auto;
}

.charger-description p {
	margin-bottom: 0;
}

.charger-info-toggle-button {
	width: 100%;
	border: none;
	background: transparent;
	color: #999999;
	transform: translateY(8px);
}

@media screen and (min-width: 320px) {
	.start-charging-btn {
		margin-top: 10px;
	}
	.charger-card-container {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
	}
}

@media screen and (min-width: 1100px) {
	.charger-card-container {
		flex-direction: row;
	}
}
</style>
