import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export const store = new Vuex.Store({
	state: {
		onBoardingDetails: {
			carAdditionSkipped: false,
			userHasBillingData: false,
			userHasCar: false,
			userHasCreditCard: false,
			userHasPhoneNumber: false,
		},
		loggedInUserDataState: null,

		loggedInUserDataLoaded: false,
		onBoardingDetailsLoaded:false,

		loggedInUserDataResolve: null,
		onBoardingDetailsResolve: null,

		charge: null,
		parking: null,

		mode: null, // charging, parking, both

		settings: {
			wizardCarsOptional: false,
			wizardCards: false,
			welcomeScreen: false
		}
	},
	getters: {
		getMode: state => {
			return state.mode || localStorage.getItem('mode')
		},
		getOnBoardingDetails: state => {
			return new Promise(resolve => {
				if(state.onBoardingDetailsLoaded) {
					resolve(state.onBoardingDetails)
				}
				else {
					state.onBoardingDetailsResolve = resolve
				}
			})
		},
		getLoggedInUserData: state => {
			return new Promise(resolve => {
				if(state.loggedInUserDataLoaded) {
					resolve(state.loggedInUserDataState)
				}
				else {
					state.loggedInUserDataResolve = resolve
				}
			})
		},
		getCharge: state => {
			return state.charge
		},
		getParking: state => {
			return state.parking
		},
		getSettings: state => {
			return state.settings
		}
	},
	mutations: {
		storeMode: (state, payload) => {
			localStorage.setItem('mode', payload)
			state.mode = payload
		},
		storeOnBoardingDetails: (state, payload) => {
			state.onBoardingDetails = payload
			state.onBoardingDetailsLoaded = true

			if(state.onBoardingDetailsResolve) {
				state.onBoardingDetailsResolve(payload)
				state.onBoardingDetailsResolve = null
			}
		},
		storeLoggedInUserData: (state, payload) => {
			state.loggedInUserDataState = payload
			state.loggedInUserDataLoaded = true

			if(state.loggedInUserDataResolve) {
				state.loggedInUserDataResolve(payload)
				state.loggedInUserDataResolve = null
			}
		},
		setCarAdditionSkipped: (state, payload) => {
			const modifiedOnBoardingDetails = {
				...state.onBoardingDetails,
				carAdditionSkipped: payload
			}
			state.onBoardingDetails = modifiedOnBoardingDetails
		},
		setUserHasCar: (state, payload) => {
			const modifiedOnBoardingDetails = {
				...state.onBoardingDetails,
				userHasCar: payload
			}
			state.onBoardingDetails = modifiedOnBoardingDetails
		},
		storeCharge: (state, payload) => {
			state.charge = payload
		},
		storeParking: (state, payload) => {
			state.parking = payload
		},
		storeSettings: (state, payload) => {
			state.settings = payload
		},
	},
	actions: {
		storeMode: (context, payload) => {
			context.commit('storeMode', payload)
		},
		storeLoggedInUserData: (context, payload) => {
			context.commit('storeLoggedInUserData', payload)
		},
		storeOnBoardingDetails: (context, payload) => {
			context.commit('storeOnBoardingDetails', payload)
		},
		storeCharge: (context, payload) => {
			context.commit('storeCharge', payload)
		},
		storeParking: (context, payload) => {
			context.commit('storeParking', payload)
		},
		storeSettings: (context, payload) => {
			context.commit('settings', payload)
		},
	}
})

export function loadSettings(settings) {
	store.getters.getSettings.wizardCarsOptional = settings.frontendWizardCarsOptional === '1'
	store.getters.getSettings.wizardCards = settings.frontendWizardCards === '1'
	store.getters.getSettings.welcomeScreen = settings.frontendWelcomeScreen === '1'
}