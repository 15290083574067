<template>
	<div class="shadow-container-1000  d-flex flex-column flex-grow-1">
		<div class="text-center charge-history-header">
			<h1>{{ $t('newsView.news') }}</h1>
		</div>

		<div class="filter-container">
			<NoResult v-if="notFoundNewsPosts"></NoResult>
		</div>

		<div class="auto-margin flex-1" v-if="newsposts">
			<div v-for="post in newsposts" :key="post.id" class="form-group">
				<div class="border media p-2 post-box">
					<img :src="`${$rest.baseUrl}/file?type=news_thumb&subdir=${post.id}/&name=${post.primaryImage}&random=${Math.random()}`" class="img-fluid post-image" v-if="post.primaryImage" />
					<div class="media-body px-3 py-3 py-sm-0">
						<div>
							<small class="text-muted">{{ post.date }}</small>
							<h5 class="m-0 mb-1">{{ post.title }}</h5>
							<p class="p-0 m-0">{{ post.description }}</p>
						</div>
						<div>
							<button @click="openPost(post)" class="btn btn-success mt-3 stretched-link">
								{{ $t('newsView.more') }}
								<font-awesome-icon icon="chevron-right" class="ml-2" />
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped>
.post-image {
	max-width: 200px;
	object-fit: cover;
	object-position: center;
}
.post-box {
	border-radius: 10px;
	position: relative;
}
@media (max-width: 640px) {
	.post-image {
		max-width: 100%;
	}
	.post-box {
		display: block;
		padding: 0 !important;
	}
}
</style>

<script>
import NoResult from '../components/NoResult';

export default {
	name: 'NewsView',
	components: { NoResult },
	data() {
		return {
			newsposts: [],
			selectedPost: null,
			notFoundNewsPosts: false
		};
	},
	props: {
		msgs: Object
	},
	methods: {
		loadNews() {
			this.$rest.loadNews((result) => {
				this.newsposts = result;
				this.notFoundNewsPosts = result.length < 1;
			});
		},
		openPost(post) {
			this.selectedPost = post;
			this.$router.push('/news/' + post.id);
		}
	},
	beforeMount() {
		this.loadNews();
	}
};
</script>
