<template>
	<div v-if="loggedInUserData">
		<div class="form-group">
			<div class="row" style="margin-left: 0px">
				<span class="step-number">4</span>
				<a name="step4"></a>
				<div class="col-9" >{{ $t('charger-finish.provide-end-date') }}</div>
			</div>
			<div class="blockquote-footer step-list">
				{{ $t('charger-finish.1') }}
			</div>
			<div class="blockquote-footer step-list">
				{{ $t('charger-finish.2') }}
			</div>
			<div class="blockquote-footer step-list">
				{{ $t('charger-finish.3') }}
			</div>
			<div class="blockquote-footer step-list">
				{{ $t('charger-finish.4') }}
			</div>
			<div class="blockquote-footer step-list">
				{{ $t('charger-finish.5') }}
			</div>
			<div class="blockquote-footer step-list">
				<b>{{ $t('charger-finish.6', [priceSettings.convenienceFeeGross]) }}</b>
			</div>
			<div class="blockquote-footer step-list">
				<b>{{ $t('charger-finish.7', [priceSettings.unitChargingGross]) }}</b>
			</div>
		</div>
		<div style="text-align: center">
			<select id="timePicker" class="form-control mb-3" v-model="selectedFinishTime" @change="$emit('onTimePicked', 0)">
				<option v-for="timeOption in timeOptions" :key="timeOption.val" :value="timeOption.date">
					{{ timeOption.tomorrow ? 'Holnap' : 'Ma' }} {{ timeOption.hours }}:{{ timeOption.minutes }}
				</option>
			</select>
			<div>
				<button class="btn btn-primary p-3" @click="onFinishTimeSelected()">
					<font-awesome-icon icon="check"/>
					{{ $t('charger-finish.choose-date') }}
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue'

export default {
	name: "ChargeFinishTimeTemplate",
	props: {
		msgs: Object,
		priceSettings: Object,
		showHintForCarAddition: Boolean
	},
	components: {
		VueTimepicker
	},
	emits: ['onTimePicked', 'clearTimePicker'],
	data() {
		return {
			timeOptions: [],
			selectedFinishTime: null,
			paymentSummary: null
		}
	},
	asyncComputed: {
		async loggedInUserData() {
			return await this.$store.getters.getLoggedInUserData
		}
	},
	mounted() {
		let nowDate = new Date()
		let todayDate = new Date(nowDate.getFullYear(), nowDate.getMonth(), nowDate.getDate(), 0, 0, 0, 0)
		let now = nowDate.getMinutes() + nowDate.getHours() * 60

		function pad2(val) {
			if(val < 10) {
				return '0' + val
			}
			return '' + val
		}

		this.timeOptions = []
		for(var i = 0; i < 1440; i += 15) {
			let date
			if(i >= now) {
				date = new Date(todayDate.getTime() + i * 60000)
			}
			else {
				date = new Date(todayDate.getTime() + i * 60000 + 86400000)
			}

			if(!this.selectedFinishTime && i >= now) {
				this.selectedFinishTime = date
			}

			this.timeOptions.push({
				val: i,
				date: date,
				hours: pad2(date.getHours()),
				minutes: pad2(date.getMinutes()),
				tomorrow: i < now
			})

			this.timeOptions.sort((d1, d2) => d1.date - d2.date)
		}

		if(!this.selectedFinishTime) {
			this.selectedFinishTime = this.timeOptions[0]
		}

	},
	methods: {
		inputChanged() {
			if(this.selectedFinishTime.HH === "" || this.selectedFinishTime.mm === "") {
				this.$emit('clearTimePicker')
				this.setDefaultValueForTimePicker()
			}
		},
		setDefaultValueForTimePicker() {
			this.selectedFinishTime = {
				HH: '00',
				mm: '00'
			}
		},
		onFinishTimeSelected() {
			if(this.selectedFinishTime) {
				const time = this.selectedFinishTime.getTime() - new Date().getTime()
				let timeInMinute = Math.ceil(time / 1000 / 60)
				if(timeInMinute === 0) {
					timeInMinute = 1
				}
				this.$emit('onTimePicked', timeInMinute)
			}
		},
	}
}
</script>

<style scoped>
</style>
