<template>
	<input
		:id="id"
		type="tel"
		:value="phone"
		@change="change"
	/>
</template>

<script>
const PNF = require('google-libphonenumber').PhoneNumberFormat
const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance()

export default {
	name: 'PhoneInput',
	data() {
		return {
			phone: ''
		}
	},	  
	props: {
		id: String,
		value: String,
		msgs: Object,
		required: Boolean
	},
	mounted() {
		this.phone = this.value
	},
	watch: {
		value(v) {
			this.phone = v
		}
	},
	methods: {
		change(event) {
			var val = event.target.value.trim()

			if(!val && !this.required) {
				event.target.value = val
				this.phone = val
				this.$emit('input', val)
				this.$set(this.msgs, this.id, [])
				return
			}

			if(!val && this.required) {
				event.target.value = val
				this.text = val
				this.$emit('input', val)
				this.$set(this.msgs, this.id, [{id: 0, text: this.$t('invalid.required'), severity: 'error'}])
				return
			}
			
			try {
				var number = phoneUtil.parseAndKeepRawInput(val, 'HU')
				if(phoneUtil.isValidNumber(number)) {
					val = phoneUtil.format(number, PNF.INTERNATIONAL)
					event.target.value = val
					this.phone = val
					this.$emit('input', val)
					this.$set(this.msgs, this.id, [])
					return
				}
			}
			catch(err) {
			}
			
			this.$emit('change', '')
			this.$set(this.msgs, this.id, [{id: 0, text: this.$t('invalid.phone'), severity: 'error'}])
		}
	},
	emits: ['input']
}
</script>