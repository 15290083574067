<template>
	<div class="container mb-auto mt-5">
		<div class="filter-container">
			<div class="form-group">
				<div class="input-group">
					<input id="filterCriteria" type="text" v-model="filterText" @input="filterData" class="form-control" :placeholder="$t('charger-search.filter')" />
					<button @click="showAdvancedFilter" class="btn btn-primary input-button">
						<font-awesome-icon icon="cog" class="m-0" />
					</button>
				</div>
			</div>
			<NoResult v-if="filteredLocations !== null && filteredLocations.length < 1"></NoResult>
		</div>

		<Spinner v-if="this.filteredLocations === null" />

		<div class="card-container">
			<div class="no-shadow-container card text-center location-card" v-for="location in filteredLocations" :key="location.id" @click="showLocation(location)">
				<div class="card-body">
					<h5 class="card-title">{{ location.name }}</h5>
					<div class="card-text location-address" v-if="location.address">
						{{ location.address.full }}
					</div>

					<div class="row">
						<div class="col-4 text-left connector-type">
							<span v-for="outlet in location.outlets" :key="outlet">
								<div v-if="outlet === 'TYPE2'">
									<img class="charger-head-location" src="../assets/connector/TYPE2_ico.png" />
									<span>TYPE2</span>
								</div>
								<div v-if="outlet === 'CHADEMO'">
									<img class="charger-head-location" src="../assets/connector/CHADEMO_ico.png" />
									<span>CHADEMO</span>
								</div>
								<div v-if="outlet === 'CCS'">
									<img class="charger-head-location" src="../assets/connector/CCS_ico.png" />
									<span>CCS</span>
								</div>
							</span>
						</div>
						<div class="col-4 text-center dist-in-km" v-if="location.distanceInKm">{{ round2(location.distanceInKm) }} km</div>
						<div v-if="location.available && location.hasCharger" class="free-charger-text">{{ $t('charger-search.free-charger') }}</div>
						<div v-else-if="!location.available && location.hasCharger" class="col-4 text-right free-charger-text-reserved">{{ $t('charger-search.reserved') }}</div>
					</div>
				</div>
			</div>
		</div>

		<AdvancedLocationFilter v-if="isShowAdvancedFilter" :outlet-type-list="outletTypeList" :filter-values="filters" :top-categories="topCategories" @hide="hideAdvancedFilter" @apply="applyFilter" @deletefilter="removeAdvancedFilter"></AdvancedLocationFilter>
		<PoiPanelView v-if="isShowPoiPanel" @hide="hidePoiPanel" @category="changeCategory" :location="selectedLocationWithPoi" :data="locationData" :chargerActive="filters.searchCharger"></PoiPanelView>
	</div>
</template>

<script>
import LocationPanel from '@/components/LocationPanel.vue';
import AdvancedLocationFilter from '@/components/AdvancedLocationFilter';
import PoiPanelView from '@/components/PoiPanelView';
import NoResult from '../components/NoResult';
import Spinner from '../components/Spinner';

export default {
	name: 'ChargerSearch',
	components: {
		Spinner,
		NoResult,
		PoiPanelView,
		LocationPanel,
		AdvancedLocationFilter
	},
	props: ['type'],
	data() {
		return {
			app: null,
			outletTypeList: [],
			filterText: null,
			topCategories: [],
			filteredLocations: null,
			locationData: null,
			isShowAdvancedFilter: false,
			isShowPoiPanel: false,
			isAdvancedFilterApplied: false,
			geolocation: null,
			filters: {
				city: null,
				outletType: null,
				currentType: null,
				performanceFrom: 0,
				searchCharger: false,
				searchPoi: false,
				onlyPointAccepter: false,
				onlyAvailableChargers: false,
				selectedCategory: null,
				subcategory: null,
				selectedSubCategories: [],
				latitude: null,
				longitude: null
			},
			selectedLocationWithPoi: null,
			timer: null
		};
	},
	methods: {
		changeCategory(category) {
			this.filters.subcategory = category.id;
			this.hidePoiPanel();
			this.applyFilter(this.filters);
		},
		applyFilter(filter) {
			this.filters = filter;
			this.filterText = null;
			this.isAdvancedFilterApplied = true;
			//this.hideAdvancedFilter()
			this.$rest.filterLocations(this.filters, (responseData) => {
				this.locations = responseData;
				this.filteredLocations = responseData;
			});
		},
		removeAdvancedFilter() {
			this.filters = {
				city: null,
				outletType: null,
				currentType: null,
				performanceFrom: 0,
				searchCharger: false,
				searchPoi: false,
				onlyPointAccepter: false,
				onlyAvailableChargers: false,
				selectedCategory: null,
				subcategory: null,
				selectedSubCategories: [],
				latitude: null,
				longitude: null
			};
			if (this.geolocation) {
				this.filters.latitude = this.geolocation.coords.latitude;
				this.filters.longitude = this.geolocation.coords.longitude;
			}
			this.filterText = null
			this.isAdvancedFilterApplied = false
			this.setType()
			this.loadData()
		},
		showLocation(location) {
			this.$rest.getLocationWithPoi(location.id, (response, url) => {
				response.pois.forEach((p) => {
					if (p.pictureUrl != null) {
						p.pictureUrl = url + p.pictureUrl;
					}
				});
				this.selectedLocationWithPoi = response;
				this.loadLocationData(location, true);
			});
		},

		loadLocationData(location, setPanel) {
			const l = location;
			this.$rest.getLocation(location.id, this.timer != null, (responseData) => {
				this.locationData = responseData;
				if (setPanel) {
					this.isShowPoiPanel = true;
				}
			});
			this.killTimer();
			this.timer = setTimeout(() => {
				this.loadLocationData(l, false);
			}, 15000);
		},

		showAdvancedFilter() {
			this.isShowAdvancedFilter = true;
		},
		hideAdvancedFilter() {
			this.isShowAdvancedFilter = false;
		},
		hidePoiPanel() {
			this.isShowPoiPanel = false;
			this.killTimer();
		},
		filterData(event) {
			const str = event.target.value.toLowerCase().trim();
			const value = str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
			if (value.length > 0) {
				this.filteredLocations = this.locations.filter((location) => {
					const normalizedName = location.name
						.toLowerCase()
						.normalize('NFD')
						.replace(/[\u0300-\u036f]/g, '');
					let result = normalizedName.includes(value);
					if (location.address) {
						const normalizedAddress = location.address.full
							.toLowerCase()
							.normalize('NFD')
							.replace(/[\u0300-\u036f]/g, '');
						result |= normalizedAddress.includes(value);
					}
					return result;
				});
			} else {
				this.filteredLocations = this.locations;
			}
		},
		filterLocation() {
			this.$rest.filterLocations(this.filters, (responseData) => {
				this.filteredLocations = responseData;
				if (!this.filters.searchCharger) {
					this.filterLocationsWithoutPoi();
				} else {
					this.filterLocationsWithCharger();
				}
			});
		},
		filterLocationsWithoutPoi() {
			const locationsWithPois = this.filteredLocations.filter((location) => location.hasPoi);
			this.filteredLocations = locationsWithPois;
			this.locations = this.filteredLocations;
		},
		filterLocationsWithCharger() {
			const locationsWithChargers = this.filteredLocations.filter((location) => location.hasCharger);
			this.filteredLocations = locationsWithChargers;
			this.locations = this.filteredLocations;
		},
		loadData() {
			this.app.getLocation(
				async (position) => {
					this.geolocation = position;
					this.filters.latitude = position.coords.latitude;
					this.filters.longitude = position.coords.longitude;
					this.filterLocation();
				},
				(thereIsGeoLocationSupport) => {
					this.filterLocation();
				}
			);

			this.$rest.getChargePorts((ports) => {
				this.outletTypeList = ports;
			});
			this.$rest.loadCategories(null, (response) => {
				this.topCategories = response;
			});
		},
		round2(num) {
			return +(Math.round(num + 'e+2') + 'e-2');
		},
		killTimer() {
			if (this.timer) {
				clearTimeout(this.timer);
				this.timer = null;
			}
		},
		setType() {
			if(this.type) {
				if(this.type === 'charger') {
					this.filters.searchCharger = true
				}
				else if(this.type === 'service') {
					this.filters.searchPoi = true
				}
			}
		}
	},
	created() {
		this.app = this.$root.$children[0]
	},
	beforeMount() {
		this.filteredLocations = null
		this.setType()
		this.loadData()
	},
	beforeDestroy() {
		this.killTimer()
	}
}
</script>

<style>
.charger-head-location {
	width: 20px;
	margin: 0;
}

.card-container {
	margin: auto;
}

.free-charger-text {
	display: flex;
	color: white;
	margin-left: auto;
	background: var(--newbtn-green);
	border-radius: 20px;
	padding: 5px 15px;
	font-weight: bold;
	font-size: 1.1rem;
}

.free-charger-text-reserved {
	color: red;
	font-weight: bold;
	font-size: 1.1rem;
}

.input-button {
	border-radius: 0 !important;
	padding: 0 10px !important;
}

.filter-container {
	max-width: 40rem;
	margin: auto;
}

.location-card {
	max-width: 30rem;
	margin: 1rem auto;
	/* box-shadow: 2px 2px 5px lightgrey; */
}

.location-card:hover {
	border-color: #4c69ba;
}

.filter-value {
	background-color: red;
	color: white;
	padding: 4px;
	border-radius: 5px;
}

.location-address {
	margin: 20px 0;
}

@media (max-width: 465px) {
	.dist-in-km,
	.connector-type {
		min-width: 100%;
	}
}
</style>
