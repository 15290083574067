<template>
	<div>
		<div>
			<ConfirmDialog :modal="true"></ConfirmDialog>
		</div>
		<Dialog :containerStyle="{ 'max-width': '95%' }" header=" " :visible.sync="openDetailsDialog" @hide="closeDetails()" :modal="true">
			<div v-if="selectedCar && !selectedCar.addedByUser" class="">
				<div class="card car-details">
					<div class="d-flex align-items-center px-2 mt-2 justify-content-between">
						<h3 class="car-producer">{{ selectedCar.producer }}</h3>
						<h5 v-if="selectedCar.defaultCar" class="default-text-title">{{ $t('cars.setDefault') }}</h5>
					</div>
					<h4 class="car-model px-2 mb-0">{{ selectedCar.model }}</h4>

					<div class="card-body pt-0">
						<hr style="margin-bottom: 2rem" />

						<div class="row my-2 dialog-details-row">
							<div class="col-6 col-md-3 col-lg-3 my-2" v-if="selectedCar.licensePlate">
								<b>{{ $t('cars.licencePlate') }}</b>
							</div>
							<input type="text" class="col-6 col-md-3 col-lg-3 my-2 licence-plate-input" v-if="selectedCar.licensePlate" v-model="selectedCar.licensePlate" @change="licenseChanged" />
							<div class="col-6 col-md-3 col-lg-3 my-2" v-if="selectedCar.yearOfProduction">
								<b>{{ $t('cars.yearOfProduction') }}</b>
							</div>
							<div class="col-6 col-md-3 col-lg-3 my-2" v-if="selectedCar.yearOfProduction">{{ selectedCar.yearOfProduction }}</div>
						</div>

						<div class="row my-2 dialog-details-row">
							<div class="col-6 col-md-3 col-lg-3 my-2" v-if="selectedCar.chargePorts">
								<b>{{ $t('cars.chargePort') }}</b>
							</div>
							<div class="col-6 col-md-3 col-lg-3 my-2" v-if="selectedCar.chargePorts">{{ formattedChargerPorts }}</div>
							<div class="col-6 col-md-3 col-lg-3 my-2" v-if="selectedCar.enginePowerKw">
								<b>{{ $t('cars.enginePowerKw') }}</b>
							</div>
							<div class="col-6 col-md-3 col-lg-3 my-2" v-if="selectedCar.enginePowerKw">{{ selectedCar.enginePowerKw }} kW</div>
						</div>

						<div class="row my-2 dialog-details-row">
							<div class="col-6 col-md-3 col-lg-3 my-2" v-if="selectedCar.batteryCapacityKWh">
								<b>{{ $t('cars.batteryCapacity') }}</b>
							</div>
							<div class="col-6 col-md-3 col-lg-3 my-2" v-if="selectedCar.batteryCapacityKWh">{{ selectedCar.batteryCapacityKWh }} kWh</div>
							<div class="col-6 col-md-3 col-lg-3 my-2" v-if="selectedCar.rangeKm">
								<b>{{ $t('cars.range') }}</b>
							</div>
							<div class="col-6 col-md-3 col-lg-3 my-2" v-if="selectedCar.rangeKm">{{ selectedCar.rangeKm }} km</div>
						</div>

						<div class="row my-2 dialog-details-row">
							<div class="col-6 col-md-3 col-lg-3 my-2" v-if="selectedCar.topSpeedKmph">
								<b>{{ $t('cars.max-speed') }}</b>
							</div>
							<div class="col-6 col-md-3 col-lg-3 my-2" v-if="selectedCar.topSpeedKmph">{{ selectedCar.topSpeedKmph }} km/h</div>
							<div class="col-6 col-md-3 col-lg-3 my-2" v-if="selectedCar.accelerationZeroToHundredKmph">
								<b>{{ $t('cars.acceleration') }}</b>
							</div>
							<div class="col-6 col-md-3 col-lg-3 my-2" v-if="selectedCar.accelerationZeroToHundredKmph">
								{{ selectedCar.accelerationZeroToHundredKmph }}
								sec
							</div>
						</div>

						<div class="row my-2 dialog-details-row">
							<div class="col-6 col-md-3 col-lg-3 my-2" v-if="selectedCar.rangeEPAKm">
								<b>{{ $t('cars.epa-range') }}</b>
							</div>
							<div class="col-6 col-md-3 col-lg-3 my-2" v-if="selectedCar.rangeEPAKm">{{ selectedCar.rangeEPAKm }} km</div>
							<div class="col-6 col-md-3 col-lg-3 my-2" v-if="selectedCar.rangeNEDCKm">
								<b>{{ $t('cars.nedc-range') }}</b>
							</div>
							<div class="col-6 col-md-3 col-lg-3 my-2" v-if="selectedCar.rangeNEDCKm">{{ selectedCar.rangeNEDCKm }} km</div>
						</div>

						<div class="row my-2 dialog-details-row">
							<div class="col-6 col-md-3 col-lg-3 my-2" v-if="selectedCar.rangeWLPTKm">
								<b>{{ $t('cars.wlpt-range') }}</b>
							</div>
							<div class="col-6 col-md-3 col-lg-3 my-2" v-if="selectedCar.rangeWLPTKm">{{ selectedCar.rangeWLPTKm }} km</div>
							<div class="col-6 col-md-3 col-lg-3 my-2" v-if="selectedCar.averageChargingSpeedDCKmph">
								<b>{{ $t('cars.avg-dc-speed') }}</b>
							</div>
							<div class="col-6 col-md-3 col-lg-3 my-2" v-if="selectedCar.averageChargingSpeedDCKmph">{{ selectedCar.averageChargingSpeedDCKmph }} km/h</div>
						</div>

						<div class="row my-2 dialog-details-row">
							<div class="col-6 col-md-3 col-lg-3 my-2" v-if="selectedCar.maxChargingPowerACKw">
								<b>{{ $t('cars.max-ac-speed') }}</b>
							</div>
							<div class="col-6 col-md-3 col-lg-3 my-2" v-if="selectedCar.maxChargingPowerACKw">{{ selectedCar.maxChargingPowerACKw }} kW</div>
							<div class="col-6 col-md-3 col-lg-3 my-2" v-if="selectedCar.maxChargingPowerDCKw">
								<b>{{ $t('cars.max-dc-speed') }}</b>
							</div>
							<div class="col-6 col-md-3 col-lg-3 my-2" v-if="selectedCar.maxChargingPowerDCKw">{{ selectedCar.maxChargingPowerDCKw }} kW</div>
						</div>

						<div class="row my-2 dialog-details-row">
							<div class="col-6 col-md-3 col-lg-3 my-2" v-if="selectedCar.engineTorqueNm">
								<b>{{ $t('cars.torque') }}</b>
							</div>
							<div class="col-6 col-md-3 col-lg-3 my-2" v-if="selectedCar.engineTorqueNm">{{ selectedCar.engineTorqueNm }} Nm</div>
							<div class="col-6 col-md-3 col-lg-3 my-2" v-if="selectedCar.motorType">
								<b>{{ $t('cars.motor-type') }}</b>
							</div>
							<div class="col-6 col-md-3 col-lg-3 my-2" v-if="selectedCar.motorType">{{ selectedCar.motorType }}</div>
						</div>

						<div class="row my-2 dialog-details-row">
							<div class="col-6 col-md-3 col-lg-3 my-2" v-if="selectedCar.seats">
								<b>{{ $t('cars.seat-count') }}</b>
							</div>
							<div class="col-6 col-md-3 col-lg-3 my-2" v-if="selectedCar.seats">
								<span v-for="(seat, index) in selectedCar.seats" :key="seat"> {{ seat }}<template v-if="index != Object.keys(selectedCar.seats).length - 1">, </template> </span>
							</div>
							<div class="col-6 col-md-3 col-lg-3 my-2" v-if="selectedCar.wheelbaseMM">
								<b>{{ $t('cars.axle-base') }}</b>
							</div>
							<div class="col-6 col-md-3 col-lg-3 my-2" v-if="selectedCar.wheelbaseMM">{{ selectedCar.wheelbaseMM }} mm</div>
						</div>

						<div class="row my-2 dialog-details-row">
							<div class="col-6 col-md-3 col-lg-3 my-2" v-if="selectedCar.bodyStyle">
								<b>{{ $t('cars.style') }}</b>
							</div>
							<div class="col-6 col-md-3 col-lg-3 my-2" v-if="selectedCar.bodyStyle">{{ selectedCar.bodyStyle }}</div>
							<div class="col-6 col-md-3 col-lg-3 my-2" v-if="selectedCar.cargoVolumeL">
								<b>{{ $t('cars.trunk-space') }}</b>
							</div>
							<div class="col-6 col-md-3 col-lg-3 my-2" v-if="selectedCar.cargoVolumeL">{{ selectedCar.cargoVolumeL }} L</div>
						</div>

						<div class="row my-2 dialog-details-row">
							<div class="col-6 col-md-3 col-lg-3 my-2" v-if="selectedCar.crubWeightKg">
								<b>{{ $t('cars.weight') }} </b>
							</div>
							<div class="col-6 col-md-3 col-lg-3 my-2" v-if="selectedCar.crubWeightKg">{{ selectedCar.crubWeightKg }} kg</div>
							<div class="col-6 col-md-3 col-lg-3 my-2" v-if="selectedCar.towingCapacityKg">
								<b>{{ $t('cars.tow-weight') }}</b>
							</div>
							<div class="col-6 col-md-3 col-lg-3 my-2" v-if="selectedCar.towingCapacityKg">{{ selectedCar.towingCapacityKg }} kg</div>
						</div>

						<div class="row my-2 dialog-details-row">
							<div class="col-6 col-md-3 col-lg-3" v-if="selectedCar.efficiencyKWhpKm">
								<b>{{ $t('cars.efficiency') }}</b>
							</div>
							<div class="col-6 col-md-3 col-lg-3" v-if="selectedCar.efficiencyKWhpKm">{{ selectedCar.efficiencyKWhpKm }} kWh/100km</div>
						</div>
					</div>
				</div>
			</div>
		</Dialog>
		<Dialog :containerStyle="{ 'max-width': '95%' }" header=" " :visible.sync="openAddDialog" :modal="true">
			<div class="tabcontent hidden-wizard-container">
				<CarRegistrationForm @saveCar="saveVehicleEvent" @addCar="addToMyCarEvent" @changeTab="tabChangeEvent" :msgs="msgs" />
			</div>
		</Dialog>
		<h2 class="header-common" v-if="!selectedCar">{{ $t('cars.listHeader') }}</h2>
		<div class="tabs text-center" v-if="!selectedCar">
			<PrimeButton @click="openAddCarDialog()" :style="{ 'background-color': 'var(--newbtn-blue)' }" class="d-flex gap-2 mx-auto mb-3 p-button-rounded p-button-raised">
				<i class="pi pi-plus-circle"></i>
				<span>{{ $t('cars.registerYourCar') }}</span>
			</PrimeButton>
		</div>
		<carousel :perPage="1" :navigationEnabled="true" navigationNextLabel="<i class='pi pi-caret-right' :style='{font-size:2rem}'></i>" navigationPrevLabel="<i class='pi pi-caret-left' style='{width:100%; height:100%}'></i>" :style="{ width: '90%', 'max-width': '380px', margin: '0 auto', 'border-radius': '12px' }">
			<slide v-for="car in cars" :key="car.id" :style="{ width: '90%', 'max-width': '380px' }">
				<div class="card car-card card-container" :style="{ 'min-height': '600px', 'max-width': '380px', 'border-radius': '12px', overflow: 'hidden' }">
					<div class="car-img-container">
						<img v-if="car.image" :src="car.image" class="car-image" />
						<div v-else class="centered" :style="{ 'min-height': '250px' }">
							<span class="font-italic">Nincs megjeleníthető kép</span>
						</div>
					</div>
					<div class="car-card-body">
						<div class="d-flex align-items-center px-1 mt-2 justify-content-between">
							<h3 class="car-producer">{{ car.producer }}</h3>
							<h5 v-if="car.defaultCar" class="default-text-title">{{ $t('cars.setDefault') }}</h5>
							<PrimeButton @click="defaultCar(car)" v-else class="p-button-rounded p-button-raised p-2 p-button-success">
								<i class="pi pi-car"></i>
							</PrimeButton>
						</div>
						<h4 class="car-model px-1">{{ car.model }}</h4>
						<div class="car-details-container">
							<div class="car-details-row" v-if="car.licensePlate">
								<span class="car-details-row-title"
									><b>{{ $t('cars.licencePlate') }}</b></span
								>
								<span class="car-details-row-value">{{ car.licensePlate }}</span>
							</div>
							<div class="car-details-row" v-if="car.maxChargingPowerACKw">
								<span class="car-details-row-title"
									><b>{{ $t('cars.ac-performance') }}</b></span
								>
								<span class="car-details-row-value">{{ car.maxChargingPowerACKw }} kW</span>
							</div>
							<div class="car-details-row" v-if="car.maxChargingPowerDCKw">
								<span class="car-details-row-title"
									><b>{{ $t('cars.dc-performance') }}</b></span
								>
								<span class="car-details-row-value">{{ car.maxChargingPowerDCKw }} kW</span>
							</div>
							<div class="car-details-row" v-if="car.batteryCapacityKWh">
								<span class="car-details-row-title"
									><b>{{ $t('cars.batteryCapacity') }}</b></span
								>
								<span class="car-details-row-value">{{ car.batteryCapacityKWh }} kWh</span>
							</div>
						</div>
					</div>
					<div class="centered mt-auto mb-3">
						<PrimeButton @click="car.addedByUser ? openUserAddedDetails(car) : openCarDetailsDialog(car)" class="p-button-rounded p-button-raised p-2 px-3 centered">
							<i class="pi pi-info-circle"></i>
							<span>Info</span>
						</PrimeButton>
						<PrimeButton @click="removeCar(car)" class="p-button-danger p-button-rounded p-button-raised py-2 px-3 centered">
							<i class="pi pi-trash"></i>
							<span>{{ $t('cars.delete') }}</span>
						</PrimeButton>
					</div>
				</div>
			</slide>
		</carousel>
	</div>
</template>

<script>
import CarRegistrationForm from '@/components/CarRegistrationForm';
import Steps from 'primevue/steps';
import Dialog from 'primevue/dialog';
import ConfirmDialog from 'primevue/confirmdialog';
import { Flicking } from '@egjs/vue-flicking';
import { Fade } from '@egjs/flicking-plugins';
import CustomCarFormView from '../components/CustomCarFormView';
import { Carousel, Slide } from 'vue-carousel';

export default {
	name: 'CarView',
	components: { CustomCarFormView, CarRegistrationForm, Flicking, ConfirmDialog, Dialog, Steps, Carousel, Slide },
	computed: {
		cancelBtnTitle() {
			return this.$t('cars.cancelButton');
		}
	},
	data() {
		return {
			plugins: [new Fade()],
			selectedCar: null,
			cars: [],
			availablePorts: [],
			formattedChargerPorts: [],
			licensePlateChanged: false,
			carToModify: {
				id: '',
				licensePlate: ''
			},
			openDetailsDialog: false,
			openAddDialog: false,
			openCarDetailsAddedByUser: false,
			isLargeScreen: false
		};
	},
	props: {
		msgs: Object
	},
	methods: {
		checkScreenSize() {
			this.isLargeScreen = window.innerWidth > 800;
		},
		modifyLicensePlateOnVehicle() {
			this.carToModify.id = this.selectedCar.id;
			this.carToModify.licensePlate = this.selectedCar.licensePlate;
			this.$rest.modifyCar(this.carToModify, (usersCars) => {
				this.$emit('onSaved', this.carToModify);
				this.cars = usersCars;
				this.$emit('loaded', this.cars);
				this.$forceUpdate();
			});
			this.selectedCar = null;
		},
		licenseChanged(event) {
			this.licensePlateChanged = true;
		},
		openAddCarDialog() {
			this.openAddDialog = true;
			this.selectedCar = null;
		},
		closeAddCarDialog() {
			this.openAddDialog = false;
		},
		moveFlicking(direction) {
			let index = this.$refs.carFlicking.getIndex();
			if (direction === 'fw') {
				index++;
			} else {
				index--;
			}
			this.$refs.carFlicking.moveTo(index, 300);
		},
		closeDetails() {
			if (this.licensePlateChanged) {
				if (confirm(this.$t('cars.confirmChangeLicensePlate'))) {
					this.modifyLicensePlateOnVehicle();
				} else {
					this.selectedCar = null;
				}
			} else {
				this.selectedCar = null;
			}
		},
		async details(car) {
			this.licensePlateChanged = false;
			this.cars.forEach((c) => {
				if (c.id === car.id) {
					this.selectedCar = { ...c };
				}
			});

			async function formatChargePortValues() {
				let portValues = [];
				await this.getChargePorts();
				this.availablePorts.forEach((port) => {
					if (this.selectedCar.chargePorts.includes(port.key)) {
						portValues.push(port.value);
					}
				});
				this.formattedChargerPorts = portValues.join(', ');
			}

			if (this.selectedCar && !this.selectedCar.addedByUser) {
				await formatChargePortValues.call(this);
			}
		},
		async getChargePorts() {
			await this.$rest.getChargePorts((ports) => {
				this.availablePorts = ports;
			});
		},
		saveVehicleEvent(car) {
			this.$emit('onSaved', car);
			this.cars.push(car);
			this.$emit('loaded', this.cars);
			this.$forceUpdate();
		},
		modifyVehicle(vehicleToSave) {
			this.$rest.modifyVehicle(vehicleToSave, (usersCars) => {
				this.$emit('onSaved', vehicleToSave);
				this.cars = usersCars;
				this.$emit('loaded', this.cars);
				this.$forceUpdate();
				this.closeDetails();
			});
		},
		addToMyCarEvent(car) {
			this.cars.push(car);
			this.$emit('loaded', this.cars);
			this.$forceUpdate();
			this.$emit('onSaved', car);
			this.closeAddCarDialog();
		},
		tabChangeEvent(tab) {
			alert('still running!');
			this.$forceUpdate();
		},
		defaultCar(car) {
			this.$rest.defaultCar(car, (myCars, url) => {
				myCars.forEach((c) => {
					if (c.image) {
						c.image = url + c.image;
					}
				});
				this.cars = myCars;
				this.$emit('loaded', this.cars);
				this.$forceUpdate();
			});
		},
		removeCar(car) {
			if (confirm(this.$t('cars.confirmDelete'))) {
				this.$rest.removeMyCars(car, (myCars, url) => {
					this.cars = [...myCars];
					this.$emit('loaded', this.cars);
					myCars.forEach((c) => {
						if (c.image) {
							c.image = url + c.image;
						}
					});
					this.$forceUpdate();
					this.$store.commit('setUserHasCar', myCars.length > 0);
				});
			}
		},
		loadData() {
			this.$rest.loadMyCars((myCars, url) => {
				myCars.forEach((c) => {
					if (c.image) {
						c.image = url + c.image;
					}
				});
				this.cars = myCars;
				this.$emit('loaded', this.cars);
				this.$forceUpdate();
			});
		},
		openDeleteCarDialog(car) {
			this.$confirm.require({
				message: this.$t('cars.deleteConfirm'),
				header: this.$t('cars.deleteCar'),
				icon: 'pi pi-exclamation-triangle',
				acceptClass: 'p-button-danger',
				rejectIcon: 'pi pi-arrow-left',
				acceptIcon: 'pi pi-trash',
				acceptLabel: this.$t('cars.delete'),
				rejectLabel: this.$t('cancel'),
				defaultFocus: 'accept',
				accept: () => {
					this.removeCar(car);
				},
				reject: () => {
					//callback to execute when user rejects the action
				}
			});
		},
		openCarDetailsDialog(car) {
			this.openDetailsDialog = true;
			this.details(car);
		},
		openUserAddedDetails(car) {
			this.openCarDetailsAddedByUser = true;
			this.details(car);
		}
	},
	beforeMount() {
		this.loadData();
		this.checkScreenSize();
		window.addEventListener('resize', this.checkScreenSize);
	},
	emits: ['onSaved', 'loaded']
};
</script>

<style>
.input-btn-delete > svg {
	margin-right: 0px !important;
}

.default-text {
	font-size: 1.2rem;
	color: red;
}

.car-details {
	background-color: #fff !important;
	border: none;
	box-shadow: 0 0 2px lightgrey;
}

.car-producer {
	color: #3d3d3d;
	margin-bottom: 1rem !important;
	text-transform: uppercase;
	font-size: 1.3rem;
	text-align: left;
	font-weight: 400;
	margin-bottom: 0 !important;
}

.licence-plate-input {
	border: 1px solid rgb(192, 192, 192);
	border-radius: 10px;
	padding: 5px 0;
}

.car-details-row {
	border-bottom: 1px solid rgba(82, 82, 82, 0.26);
	border-radius: 6px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 10px;
	padding: 8px;
	/* background: #343a40;
	color: white; */
}

.dialog-details-row,
.dialog-details-spanned-row {
	border-bottom: 1px solid rgba(82, 82, 82, 0.26);
	border-radius: 6px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 10px;
	padding: 8px;
}

.car-details-row-title {
	font-weight: 300;
}

.car-details-row-value {
	min-width: 20%;
}

.card-footer-btns {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 6px;
}

.default-text-title {
	font-size: 0.8rem;
	background-color: #6aad6a;
	padding: 8px 10px;
	color: white;
	border-radius: 20px;
}

.car-model {
	color: #313131;
	margin-bottom: 1rem !important;
	text-transform: uppercase;
	font-size: 1.5rem;
	font-weight: 400;
}

.car-name-detail {
	color: darkblue;
	margin-bottom: 2rem !important;
}

.eg-flick-viewport {
	z-index: 1000 !important;
}

.car-card {
	box-shadow: 0 0px 4px grey;
	overflow: hidden;
	color: black;
	margin: 1.5rem auto;
	max-width: 25rem !important;
	height: 33rem !important;
}

.card-car-img-top {
	width: 25rem;
	max-height: 14rem;
	object-fit: contain;
}

.car-flicking {
	height: 35rem;
}

.progress {
}

.progress-container {
	margin: auto;
}

.progress .thumb {
	position: relative;
	height: 100%;
	width: 0;
	border-radius: inherit;
	background: #ccc;
}

.empty-table-msg-container {
	width: 100%;
	text-align: center;
	margin-top: 25px;
	color: #343a40;
}

.hidden-wizard-container .wizard-navigation .wizard-progress-with-circle,
.hidden-wizard-container .vue-form-wizard .wizard-header,
.hidden-wizard-container .vue-form-wizard .wizard-nav-pills,
.hidden-wizard-container .wizard-progress-bar {
	display: none;
}

.hidden-wizard-container .wizard-tab-container .alert.alert-warning {
	margin-top: 15px;
	margin-bottom: 5px;
}

.hidden-wizard-container .wizard-footer-right {
	text-align: right;
}

.hidden-wizard-container .wizard-footer-right .reg-new-car-txt {
	color: #343a40;
	font-weight: 500;
}

.hidden-wizard-container .wizard-footer-right button {
	width: 100%;
	margin-top: 10px;
}

.hidden-wizard-container .wizard-btn {
	background-color: #878789;
	border-color: #6c757d;
	color: #ffffff;
}

.hidden-wizard-container #vehicles > .vs__dropdown-toggle {
	height: calc(1.5em + 0.75rem + 2px);
}

.hidden-wizard-container .wizard-tab-content {
	-moz-box-shadow: 0px 1px 9px rgba(0, 0, 0, 0.16);
	-webkit-box-shadow: 0px 1px 9px rgba(0, 0, 0, 0.16);
	box-shadow: 0px 1px 9px rgba(0, 0, 0, 0.16);
	padding: 20px;
	margin: 20px 0px;
	position: relative;
}

.hidden-wizard-container .vue-form-wizard .wizard-card-footer {
	padding: 0px;
}

.hidden-wizard-container .fake-modal-cancel-button {
	position: absolute;
	right: 0px;
	top: -12px;
	font-size: 40px;
	color: #e74c3c;
	cursor: pointer;
}

.tab-link-register {
	margin: 10px 0 !important;
}

.tab-link-register.active {
	display: none;
}

.tab-link-my-cars {
	display: none;
}

@media screen and (min-width: 320px) {
	.card-container {
		max-width: 380px;
	}

	.car-img-container {
		max-width: 90%;
		margin: 0 auto;
		width: 100%;
		width: 100%;
		max-width: 350px;
		margin: 0 auto;
		/* min-height: 250px; */
		border-bottom-left-radius: 15px;
		border-bottom-right-radius: 15px;
		overflow: hidden;
	}

	.car-image {
		width: 100%;
		height: auto;
		object-fit: cover;
	}

	.car-card-body {
		width: 90%;
		margin: 0 auto;
	}
}

@media screen and (min-width: 350px) {
	.car-img-container {
		max-width: 100%;
	}
}
</style>
