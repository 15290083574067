<template>
	<div class="no-shadow-container p-4 bg-white" :style="{ 'max-width': '480px' }">
		<!-- <button @click="$emit('cancel')" class="btn btn-secondary px-4 py-2 m-3" style="margin: auto">
			<font-awesome-icon icon="times" size="lg" />
			{{ $t('walletView.cancel') }}
		</button> -->
		<div class="cancel-balance-container">
			<PrimeButton @click="$emit('cancel')" class="flex gap-2 mb-1" :style="{ 'background-color': 'transparent', color: 'black', border: 'none' }">
				<i class="pi pi-arrow-left"></i>
				{{ $t('walletView.cancel') }}
			</PrimeButton>
			<div class="">
				<label>{{ $t('walletView.balances') }}:</label>
				<span class="wallet-actual-wallet-balance-text-invert" :class="{ negative: walletBalance < 0 }">{{ walletBalance }}<span style="font-size: 0.8em"> Ft</span></span>
			</div>
		</div>

		<!-- <div class="wallet-header py-1 mt-4"> {{ $t('menu.wallet') }}</div> -->

		<div class="centered flex-wrap my-2 mb-3">
			<div @click="selectAmount(amount.value)" v-for="amount in possibleAmounts" :key="amount.value" class="text-center">
				<Tag :style="amount.value === selectedAmount ? { 'background-color': 'var(--gentle-green)' } : { 'background-color': 'var(--active)' }" class="p-tag-rounded cursor-pointer custom-tags" :value="amount.text"></Tag>
			</div>
		</div>

		<div class="mb-3">
			<label>{{ $t('walletView.pleaseAddAmount') }}:</label>
			<NumberInput id="chargeAmount" :msgs="msgs" v-model="chargeAmount" class="form-control" :required="true" />
			<ComponentMessage :msgs="msgs" forComponent="chargeAmount" />
		</div>

		<div class="mb-3">
			<label>{{ $t('walletView.listHeader') }}:</label>
			<select class="form-control" v-model="selectedBillingData" v-if="billingDatas && billingDatas.length > 0">
				<option v-for="billingData in billingDatas" :key="billingData.id" :value="billingData">{{ billingData.payerName }}, {{ billingData.address.full }}</option>
			</select>
		</div>

		<div class="text-center">
			<!-- <button @click="chargeWallet()" class="btn btn-success px-4 py-2 m-3" style="margin: auto">
				<font-awesome-icon icon="coins" size="lg" />
				{{ $t('walletView.sendPrepaidAmount') }}
			</button> -->
			<PrimeButton @click="chargeWallet()" :style="{ 'border-radius': '30px', 'min-height': '52px', 'min-width': '200px', padding: '0px 20px', 'background-color': '#04af43', border: 'none' }" class="new-btn centered">
				<i class="pi pi-wallet"></i>
				{{ $t('walletView.sendPrepaidAmount') }}
			</PrimeButton>
		</div>
	</div>
</template>

<script>
import Tag from 'primevue/tag/Tag';
export default {
	name: 'WalletAdd',
	props: {
		msgs: Object
	},
	components: { Tag },
	data() {
		return {
			walletBalance: null,
			chargeAmount: null,
			selectedAmount: null,
			selectedBillingData: null,
			billingDatas: [],
			minAmountStatic: 100,
			minAmount: 100,
			maxAmount: 50000,
			possibleAmounts: [
				{
					text: '500 Ft',
					value: 500
				},
				{
					text: '1 000 Ft',
					value: 1000
				},

				{
					text: '3 000 Ft',
					value: 3000
				},
				{
					text: '5 000 Ft',
					value: 5000
				},
				{
					text: '10 000 Ft',
					value: 10000
				},
				{
					text: '20 000 Ft',
					value: 20000
				}
			]
		};
	},
	methods: {
		selectAmount(amount) {
			if (amount === this.chargeAmount) {
				this.chargeAmount = null;
				this.selectedAmount = null;
				return;
			}
			this.chargeAmount = amount;
			this.selectedAmount = amount;
		},
		chargeWallet() {
			if (!this.chargeAmount || this.chargeAmount < this.minAmount) {
				alert(this.$t('walletView.minimumLimitAlert', [this.minAmount]));
				return;
			}
			if (this.chargeAmount > this.maxAmount) {
				alert(this.$t('walletView.maximumLimitAlert', [this.maxAmount]));
				return;
			}

			if (confirm(this.$t('walletView.confirmWalletCharge', [this.chargeAmount]))) {
				this.$rest.chargeMoneyToWallet(
					this.chargeAmount,
					this.selectedBillingData.id,
					(responseData) => {
						if (responseData.result) {
							window.location.href = responseData.data;
						} else {
							alert(this.$t('walletView.prepaidFailed'));
						}
					},
					(error) => {
						this.showErrorMessage(error);
					}
				);
			}
		},
		getBillingDatas() {
			this.$rest.listBillingDataForCurrentUser((responseData) => {
				this.billingDatas = responseData ? responseData : [];
				this.selectedBillingData = null;
				for (let billingData of this.billingDatas) {
					this.selectedBillingData = billingData;
					if (this.selectedBillingData.defaultBillingData) {
						break;
					}
				}
			});
		},
		getWalletBalance() {
			this.$rest.getWalletBalance((responseData) => {
				if (responseData.result) {
					this.walletBalance = responseData.data.ballance;

					if (this.walletBalance < 0) {
						this.chargeAmount = -this.walletBalance;
						this.minAmount = Math.max(this.chargeAmount, this.minAmountStatic);
					}
				}
			});
		}
	},
	mounted() {
		this.getBillingDatas();
		this.getWalletBalance();
	},
	watch: {
		chargeAmount: function (newVal) {
			if (this.possibleAmounts.map((el) => el.value).includes(Number(newVal))) {
				this.selectedAmount = Number(newVal);
			} else {
				this.selectedAmount = null;
			}
		}
	}
};
</script>
<style scoped>
.custom-tags {
	padding: 10px 12px;
	font-size: 14px;
	font-weight: normal;
}

@media screen and (min-width: 320px) {
	.cancel-balance-container {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
}

@media screen and (min-width: 410px) {
	.cancel-balance-container {
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
	}
}
</style>
