<template>
	<div class="form-group">
		<h3>{{ title }}</h3>

		<small class="d-block mb-3">{{ $t('custom-car-form.mandatory-blocks') }}</small>

		<div class="row">
			<div class="col-sm-6 form-group">
				<label for="licencePlateNw">{{ $t('cars.licencePlate') }}: *</label>
				<TextInput id="licencePlateNw" v-model="vehicleToSave.licensePlate" :msgs="msgs" class="form-control" maxlength="255" :required="true" />
				<ComponentMessage :msgs="msgs" forComponent="licencePlateNw" />
			</div>

			<div class="col-sm-6 form-group">
				<label for="producer">{{ $t('cars.producer') }}: *</label>
				<TextInput id="producer" v-model="vehicleToSave.producer" :msgs="msgs" class="form-control" maxlength="255" :required="true" />
				<ComponentMessage :msgs="msgs" forComponent="producer" />
			</div>

			<div class="col-sm-6 form-group">
				<label for="model">{{ $t('cars.model') }}: *</label>
				<TextInput id="model" v-model="vehicleToSave.model" :msgs="msgs" class="form-control" maxlength="255" :required="true" />
				<ComponentMessage :msgs="msgs" forComponent="model" />
			</div>
			<div class="col-sm-6 form-group">
				<label for="yearOfProduction">{{ $t('cars.yearOfProduction') }}: *</label>
				<NumberInput id="yearOfProduction" v-model="vehicleToSave.yearOfProduction" :msgs="msgs" class="form-control" maxlength="255" :required="true" type="number" />
				<ComponentMessage :msgs="msgs" forComponent="yearOfProduction" />
			</div>

			<div class="col-sm-6 form-group">
				<label for="maxChargingPowerACKw">{{ $t('cars.maxChargingPowerACKw') }}:</label>
				<DecimalInput id="maxChargingPowerACKw" v-model="vehicleToSave.maxChargingPowerACKw" :msgs="msgs" class="form-control" maxlength="255" />
			</div>

			<div class="col-sm-6 form-group">
				<label for="maxChargingPowerDCKw">{{ $t('cars.maxChargingPowerDCKw') }}:</label>
				<DecimalInput id="maxChargingPowerDCKw" v-model="vehicleToSave.maxChargingPowerDCKw" :msgs="msgs" class="form-control" maxlength="255" />
			</div>

			<div class="col-sm-6 form-group">
				<label for="chargePorts">{{ $t('cars.chargePort') }}:</label>

				<div>
					<span v-for="chargerPort in chargerPortOptions" :key="chargerPort.key" class="mr-2">
						<input type="checkbox" style="vertical-align: middle" v-model="chargerPortsObj[chargerPort.key]" @change="recalcVehicleChargePorts()" />
						<span style="font-size: 0.7rem" class="ml-1">{{ chargerPort.value }}</span>
					</span>
				</div>

				<ComponentMessage :msgs="msgs" forComponent="chargePorts" />
			</div>

			<div class="col-sm-6 form-group">
				<label for="batteryCapacityKWh">{{ $t('cars.batteryCapacityKWh') }}:</label>
				<NumberInput id="batteryCapacityKWh" v-model="vehicleToSave.batteryCapacityKWh" :msgs="msgs" class="form-control" maxlength="255" type="number" />
			</div>

			<div class="col-sm-6 form-group">
				<label for="rangeKm">{{ $t('cars.rangeKm') }}:</label>
				<NumberInput id="rangeKm" v-model="vehicleToSave.rangeKm" :msgs="msgs" class="form-control" maxlength="255" type="number" />
			</div>

			<div class="col-sm-6 form-group">
				<label for="enginePowerKw">{{ $t('cars.enginePowerKw') }}:</label>
				<NumberInput id="enginePowerKw" v-model="vehicleToSave.enginePowerKw" :msgs="msgs" class="form-control" maxlength="255" type="number" />
			</div>

			<div class="col-sm-6 form-group">
				<label for="efficiencyKWhpKm">{{ $t('cars.efficiencyKWhpKm') }}:</label>
				<NumberInput id="efficiencyKWhpKm" v-model="vehicleToSave.efficiencyKWhpKm" :msgs="msgs" class="form-control" maxlength="255" type="number" />
			</div>
		</div>
		<div class="row vehicle-row">
			<div class="col-md-12">
				<div class="wizard-footer-top btns-container">
					<PrimeButton @click="saveCar()" class="centered gap-2 w-100">
						<i class="pi pi-save"></i>
						{{ $t('cars.save') }}
					</PrimeButton>

					<!-- THIS CLOSE BTN IS NOT NEEDED BECAUSE THIS COMPONENT IS OPENED FROM A DIALOG IN 2 PLACES WHICH HAS A CLOSE BUTTON -->

					<!-- <PrimeButton v-if="modification" @click="$emit('closeDetails')" class="centered gap-2 w-100">
						<i class="pi pi-arrow-left"></i>
						{{ $t('cars.cancel') }}
					</PrimeButton> -->
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'CustomCarFormView',
	props: {
		modification: Boolean,
		title: String,
		vehicle: Object,
		msgs: Object
	},
	emits: ['saveCar', 'closeDetails'],
	data() {
		return {
			chargerPortOptions: [],
			chargerPortsObj: {},
			vehicleToSave: {
				licensePlate: '',
				producer: '',
				model: '',
				yearOfProduction: '',
				batteryCapacityKWh: '',
				rangeKm: '',
				chargePorts: [],
				enginePowerKw: '',
				efficiencyKWhpKm: '',
				maxChargingPowerACkw: '',
				maxChargingPowerDCkw: ''
			},
			options: {
				multi: true,
				btnLabel: this.$t('custom-car-form.charger-type')
			},
			btnLabel: (v) => ''
		};
	},
	mounted() {
		this.resetVehicle();
		if (this.vehicle) {
			this.vehicleToSave = this.vehicle;

			this.chargerPortsObj = {};
			if (this.vehicle && this.vehicle.chargePorts) {
				for (var i = 0; i < this.vehicle.chargePorts.length; i++) {
					this.chargerPortsObj[this.vehicle.chargePorts[i]] = true;
				}
			}
		}
		this.getChargePorts();
	},
	methods: {
		getChargePorts() {
			this.$rest.getChargePorts((ports) => {
				this.chargerPortOptions = ports;
			});
		},
		saveCar() {
			if (this.vehicleToSave.yearOfProduction) {
				this.vehicleToSave.yearOfProduction = Math.floor(this.vehicleToSave.yearOfProduction);
			}
			if (this.vehicleToSave.rangeKm) {
				this.vehicleToSave.rangeKm = Math.floor(this.vehicleToSave.rangeKm);
			}
			if (this.vehicleToSave.efficiencyKWhpKm) {
				this.vehicleToSave.efficiencyKWhpKm = Math.floor(this.vehicleToSave.efficiencyKWhpKm);
			}
			if (this.vehicleToSave.enginePowerKw) {
				this.vehicleToSave.enginePowerKw = Math.floor(this.vehicleToSave.enginePowerKw);
			}
			if (this.vehicleToSave.batteryCapacityKWh) {
				this.vehicleToSave.batteryCapacityKWh = Math.floor(this.vehicleToSave.batteryCapacityKWh);
			}
			this.$emit('saveCar', this.vehicleToSave);
		},
		resetVehicle() {
			this.vehicleToSave = {
				id: '',
				licensePlate: '',
				producer: '',
				model: '',
				yearOfProduction: '',
				batteryCapacityKWh: '',
				rangeKm: '',
				chargePorts: [],
				enginePowerKw: '',
				efficiencyKWhpKm: '',
				maxChargingPowerACkw: '',
				maxChargingPowerDCkw: ''
			};
			this.chargerPortsObj = {};
		},
		recalcVehicleChargePorts() {
			let tmp = {};
			for (var i = 0; i < this.chargerPortOptions.length; i++) {
				tmp[this.chargerPortOptions[i].key] = this.chargerPortOptions[i];
			}

			this.vehicleToSave.chargePorts = [];
			for (var key in this.chargerPortsObj) {
				if (this.chargerPortsObj[key]) {
					this.vehicleToSave.chargePorts.push(tmp[key]);
				}
			}
		}
	}
};
</script>

<style scoped>
.btns-container {
	max-width: 800px;
	margin: 0 auto;
}
</style>
