<template>
	<div class="surchargeview-container shadow-sm" style="max-width: 768px; width: 100%; margin: 0 auto">
		<h2 class="text-center">{{ $t('parkingSurchargeView.title') }}</h2>
		<div class="alert alert-info">
			<font-awesome-icon icon="info-circle" />
			{{ $t('parkingSurchargeView.info') }}
		</div>
		<div v-if="!surcharge">
			<div class="flex justify-content-center align-items-center">
				<div class="form-group" style="max-width: 420px; width: 100%; margin-left: auto; margin-right: auto">
					<label for="email" class="text-center w-100">{{ $t('surcharge.email') }}</label>
					<input type="text" class="form-control d-inline-block" v-model="email" />
				</div>
			</div>

			<ParkingCodeInput class="mt-3" :show-description="false" :load-parking="false" :park-code="parkCode" @code="($e) => (this.parkCode = $e)" />
			<p class="text-center">
				{{ $t('parkingSurchargeView.parkCodeDesc') }}
			</p>

			<div class="d-flex justify-content-center mt-3">
				<button class="btn btn-success px-3" @click="start" :disabled="loading || !canStart">
					<font-awesome-icon icon="credit-card" class="mr-2" />
					{{ $t('parkingSurchargeView.start') }}
				</button>
			</div>
		</div>

		<div class="mt-5">
			<div v-if="surcharge">
				<div class="parking-panel p-2 mb-4">
					<div class="parking-card-container">
						<div class="card-body">
							<div v-if="parkingWillFinishIn && (openingGate || showOpenButton) && surcharge.status === 'PAID'">
								<div class="alert alert-info d-flex flex-column">
									<div class="d-flex">
										<font-awesome-icon class="mr-2 text-warning" icon="exclamation-triangle" />
										<h6>
											{{
												$t('parkingView.parkingSurchargeWillFinishIn', {
													x: parkingWillFinishIn
												})
											}}
										</h6>
									</div>
									<p>
										{{ $t('parkingView.pleaseLeaveSurcharge') }}
									</p>
								</div>
							</div>

							<div class="parking-card-title text-center">
								<h2 class="card-title my-1">{{ surcharge.amount }} Ft</h2>
							</div>

							<div class="card-text">
								<div class="parking-card-text text-left">
									<div class="row my-3">
										<div class="col-7 col-md-8">
											<b>{{ $t('surcharge.days') }}</b>
										</div>
										<div class="col-5 col-md-4">{{ surcharge.days }} {{ $t('surcharge.day') }}</div>
									</div>
									<div class="row my-3">
										<div class="col-7 col-md-8">
											<b>{{ $t('parking-details.gate-name') }}</b>
										</div>
										<div class="col-5 col-md-4">{{ surcharge.parkingSession.parkingLot.name }}</div>
									</div>
									<div v-if="surcharge.parkingSession.parkingTariff" class="row my-3">
										<div class="col-7 col-md-8">
											<b>{{ $t('surcharge.price') }}</b>
										</div>
										<div class="col-5 col-md-4">
											<small class="text-danger font-weight-bold">{{ $t('surcharge.price-parking', [surcharge.parkingSession.parkingTariff.grossSurchargeFeePerDay]) }}</small>
										</div>
									</div>
								</div>
							</div>

							<template v-if="surcharge.status === 'UNPAID'">
								<!--  PAYMENT STATUS  -->
								<template v-if="surcharge.paymentStartedAt">
									<!-- PAYMENT STARTED -->
									<template v-if="!surcharge.paymentFailed && !surcharge.paymentFinishedAt">
										<div>
											<div class="alert alert-info mb-2">
												<font-awesome-icon class="fa-spin mr-2" icon="spinner" />
												{{ $t('parkingView.paymentStarted') }}
											</div>
										</div>

										<button v-if="surcharge.paymentMethod === 'SIMPLEPAY' && surcharge.paymentUrl" class="btn btn-success location-panel-parking-button" @click="goToPaymentUrl">
											<font-awesome-icon class="fa mr-3" icon="money-check-alt" style="vertical-align: middle; margin-bottom: 2px" />
											{{ $t('parkingView.paymenturl') }}
										</button>
									</template>

									<!-- PAYMENT FINISHING -->
									<div v-else-if="!surcharge.paymentFailed && surcharge.paymentFinishedAt" class="d-flex justify-content-center flex-column">
										<div class="alert alert-info">
											<font-awesome-icon class="fa-spin mr-2" icon="spinner" />
											{{ $t('parkingView.paymentFinishing') }}
										</div>
									</div>

									<!-- PAYMENT FAILED -->
									<div v-else-if="surcharge.paymentFailed">
										<div class="alert alert-danger mb-3">
											<font-awesome-icon class="mr-2" icon="exclamation-triangle" />
											{{ $t('parkingView.paymentFailed') }}
										</div>
									</div>
								</template>

								<!--  PAYMENT PROCESS  -->
								<template v-if="!surcharge.paymentStartedAt || surcharge.paymentFailed">
									<template>
										<div class="d-flex flex-wrap">
											<div class="my-1 mx-auto">
												<button :class="{ highlightselected: selectedMode === 'SIMPLEPAY' }" class="credit-card credit-card-simple simple-pay" @click="selectedMode = 'SIMPLEPAY'">
													<img alt="simplepay logo" class="img-fluid" src="../assets/simplepay/simplepay.png" />
												</button>
											</div>
											<div v-for="card in creditCards" :key="card.cardId" class="my-1 mx-auto">
												<button :class="{ highlightselected: selectedMode === 'CARD' && card.cardId === selectedCard }" class="credit-card credit-card-card" @click="selectCard(card)">
													<span class="card-number">{{ card.cardMask }}</span>
													<span class="card-comment">{{ $t('charger-start.payWithCard') }}</span>
													<span class="card-expiry">{{ formatDate(card.expiry) }}</span>
												</button>
											</div>
											<div v-if="showWallet" class="my-1 mx-auto">
												<button :class="{ highlightselected: selectedMode === 'WALLET' }" class="credit-card credit-card-wallet" @click="selectedMode = 'WALLET'">
													<span class="card-number">{{ $t('menu.wallet') }}</span>
													<span class="card-ballance">{{ walletBalance }}<span style="font-size: 0.8em"> Ft</span></span>
												</button>
											</div>
										</div>

										<template v-if="selectedMode">
											<div class="w-100 d-flex justify-content-center mt-3">
												<button class="btn btn-success location-panel-parking-button" @click="paySurcharge">
													<font-awesome-icon class="fa mr-3" icon="money-check-alt" style="vertical-align: middle; margin-bottom: 2px" />
													{{ $t('parkingView.pay') }}
												</button>
											</div>
										</template>
									</template>
								</template>
							</template>

							<template v-if="surcharge.status === 'PAID'">
								<div v-if="openingGate" class="btn btn-success disabled location-panel-parking-button">
									<font-awesome-icon class="fa-spin mr-2" icon="spinner" />
									{{ $t('parkingView.openingGate') }}
								</div>
								<template v-else-if="showOpenButton">
									<button v-if="canOpen" class="btn btn-success location-panel-parking-button w-100" @click="openGate">
										<font-awesome-icon class="fa mr-3" icon="unlock" style="vertical-align: middle; margin-bottom: 2px" />
										{{ $t('parkingView.openGate') }}
									</button>

									<div v-else class="alert alert-info">
										<font-awesome-icon class="fa-spin mr-2" icon="spinner" />
										{{ $t('parkingView.stopNearGate') }}
									</div>
								</template>
							</template>

							<template v-if="surcharge.status === 'FINISHED'">
								<div class="parking-card-title text-center">
									<h4 class="card-title my-1">
										{{ $t('parking-details.thanks-for-parking') }}
									</h4>
								</div>
							</template>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import ParkingCodeInput from '@/components/ParkingCodeInput.vue';
import CasualParkingSurvey from '@/components/CasualParkingSurvey.vue';

export default {
	name: 'ParkingSurchargeView',
	components: { CasualParkingSurvey, ParkingCodeInput },
	data() {
		return {
			parking: null,
			loading: false,
			error: null,
			parkCode: null,
			email: null,
			surcharge: null,

			openingGate: false,
			canOpen: false,
			selectedMode: null,
			selectedCard: null,
			walletBalance: 0,
			creditCards: [],
			refreshTimer: null,
			alreadyJumpedToPaymentUrl: false
		};
	},
	async created() {
		if (this.$route.query.parkCode) {
			this.parkCode = this.$route.query.parkCode;
		}
		if (this.$route.query.email) {
			this.email = this.$route.query.email;
		}
		const data = await this.$store.getters.getLoggedInUserData;
		if (data && !this.email) {
			this.email = data.email;
		}

		this.requestWalletBalance();
		this.requestCreditCards();
	},
	async mounted() {
		if (this.email && this.parkCode) {
			await this.start();
		}
	},
	computed: {
		parkingWillFinishIn() {
			if (!this.surcharge) return null;
			if (!this.surcharge.parkingWillFinishAt) return null;
			const time = new Date(this.surcharge.parkingWillFinishAt);
			return `${time.getHours().toString().padStart(2, '0')}:${time.getMinutes().toString().padStart(2, '0')}`;
		},
		canStart() {
			return this.email && this.parkCode && !this.surcharge;
		},
		showWallet() {
			if (!this.parking) return false;
			return this.walletBalance >= this.parking.amount;
		},
		showOpenButton() {
			return this.surcharge.status === 'PAID';
		}
	},
	asyncComputed: {
		async loggedInUserData() {
			return await this.$store.getters.getLoggedInUserData;
		},
		async casualUser() {
			if (!(await this.loggedInUserData)) return true;
			return this.loggedInUserData.casualUser;
		}
	},
	watch: {
		surcharge() {
			this.subToCanOpen();
			// this.subToSurcharge();
		}
	},
	methods: {
		async start() {
			if (!this.canStart) return;

			this.loading = true;
			this.error = null;

			let resp = null;
			const casualUser = await this.casualUser;
			//console.log('casualUser', casualUser)
			if (casualUser) {
				resp = await this.$rest.startCasualSurcharge(this.parkCode, this.email);
			} else {
				resp = await this.$rest.startSurcharge(this.parkCode, this.email);
			}
			if (!resp) {
				this.error = 'No response from server';
				this.loading = false;
				return;
			}

			this.onSurcharge(resp.data);

			if (resp.extraData) {
				// extraData is the access token
				localStorage.setItem('loggedInAccessToken', resp.extraData.extraData);
			}
			this.$ws.connect();

			this.$ws.onOpen(() => {
				this.subToCanOpen();
				this.subToSurcharge();
			});

			this.loading = false;
		},
		goToPaymentUrl() {
			if (!this.surcharge.paymentFailed && this.surcharge.paymentUrl) {
				window.location.href = this.surcharge.paymentUrl;
			}
		},
		async paySurcharge() {
			const d = await this.$rest.paySurcharge(this.surcharge.id, this.selectedMode, this.selectedCard);
		},
		selectCard(card) {
			this.selectedMode = 'CARD';
			this.selectedCard = card.cardId;
		},
		async openGate() {
			this.$ws.command('openGateSurcharge');
			this.openingGate = true;
			setTimeout(() => {
				this.openingGate = false;
			}, 5000);
		},
		async requestWalletBalance() {
			if (await this.casualUser) return;
			this.$rest.getWalletBalance((responseData) => {
				if (responseData.result) {
					this.walletBalance = responseData.data.ballance;
				}
			});
		},
		async requestCreditCards() {
			if (await this.casualUser) return;
			this.$rest.getCreditCards((data) => {
				this.creditCards = data;
			});
		},
		onCanOpen(canOpen) {
			this.canOpen = canOpen;
		},
		formatDate(dateStr) {
			const date = new Date(dateStr);
			return `${date.getFullYear()}/${date.getMonth() + 1}`;
		},
		subToCanOpen() {
			if (!this.surcharge) {
				this.$ws.unsubscribe('/can/open', this.onCanOpen);
				return;
			}

			this.$ws.subscribe('/can/open', this.onCanOpen);
			this.$ws.command('getCanOpenSurcharge');
		},
		subToSurcharge() {
			if (!this.surcharge) {
				this.$ws.unsubscribe('/user/parkingSurcharge', this.onSurcharge);
				return;
			}

			this.$ws.subscribe('/user/parkingSurcharge', this.onSurcharge);
			this.$ws.command('getParkingSurcharge');
			this.refresh();
		},
		onSurcharge(surcharge) {
			//console.log('surcharge', surcharge)
			if (!surcharge) return;
			// this.surcharge = surcharge;

			if (this.surcharge && !this.surcharge.paymentUrl && surcharge.paymentUrl && !this.alreadyJumpedToPaymentUrl) {
				this.goToPaymentUrl();
				this.alreadyJumpedToPaymentUrl = true;
			}

			this.surcharge = surcharge;
		},
		refresh(count = 0) {
			this.subToCanOpen();

			clearTimeout(this.refreshTimer);
			// if(count > 30) return console.error('refresh timeout')

			this.refreshTimer = setTimeout(() => {
				this.refresh();
			}, 1000);
		}
	}
};
</script>
<style scoped>
.surchargeview-container {
	min-height: 500px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin: 0 auto;
	padding: 40px;
	background-color: white;
	border-radius: 5px;
	//box-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
}
.parking-card-title {
	margin-bottom: 1.3rem;
	margin-top: 1rem;
}

.stop-btn-row {
	margin-top: 3rem;
}

.parking-card-text {
	max-width: 30rem;
	margin: auto;
}

.spin-icon {
	margin-top: 1rem;
}

.parking-card-container {
	max-width: 40rem;
	margin: auto;
	box-shadow: 0 0 5px grey;
	background-color: lightcyan;
	border-radius: 10px;
}

.credit-card.simple-pay,
.credit-card {
	height: 60px !important;
	width: 7rem !important;
}

.credit-card-wallet {
}

.card-number {
	top: 3px !important;
}

.highlightselected {
	border: 2px solid #28a745 !important;
}

.card-number {
	font-size: 11px;
}

.card-comment {
	font-size: 8px;
}

.card-expiry {
	font-size: 8px;
}

.location-panel-parking-button {
	width: 100%;
}
</style>
