<template>
	<div>
		<carousel :items="1" @changed="changedTo($event)" @initialized="init()" :navText="[$t('startChargeTutorial.prev'), $t('startChargeTutorial.next')]">
			<div class="row align-items-center">
				<div class="col-md-3">
					<img src="@/assets/wizard/charger_in.jpg" class="img-wizard img-fluid mb-2 mx-auto" />
				</div>
				<div class="col-md-9">
					{{ $t('startChargeTutorial.tip1') }}
				</div>
			</div>
			<div class="row align-items-center">
				<div class="col-md-3">
					<img src="@/assets/wizard/off.jpg" class="img-wizard img-fluid mb-2 mx-auto" />
				</div>
				<div class="col-md-9">
					{{ $t('startChargeTutorial.tip2') }}
				</div>
			</div>
			<div class="row align-items-center">
				<div class="col-md-3">
					<img src="@/assets/wizard/card-recycle.jpg" class="img-wizard img-fluid mb-2 mx-auto" />
				</div>
				<div class="col-md-9">
					{{ $t('startChargeTutorial.tip3') }}
				</div>
			</div>
			<div class="row align-items-center">
				<div class="col-md-3">
					<img src="@/assets/wizard/card-3d.jpg" class="img-wizard img-fluid mb-2 mx-auto" />
				</div>
				<div class="col-md-9">
					{{ $t('startChargeTutorial.tip4') }}
				</div>
			</div>
			<div class="row align-items-center">
				<div class="col-md-3">
					<img src="@/assets/wizard/card-battery.jpg" class="img-wizard img-fluid mb-2 mx-auto" />
				</div>
				<div class="col-md-9">
					{{ $t('startChargeTutorial.tip5') }}
				</div>
			</div>
			<div class="row align-items-center">
				<div class="col-md-3">
					<img src="@/assets/wizard/charger_out.jpg" class="img-wizard img-fluid mb-2 mx-auto" />
				</div>
				<div class="col-md-9">
					{{ $t('startChargeTutorial.tip6') }}
				</div>
			</div>
			<div class="row align-items-center">
				<div class="col-md-3">
					<img src="@/assets/wizard/door-open.jpg" class="img-wizard img-fluid mb-2 mx-auto" />
				</div>
				<div class="col-md-9">
					{{ $t('startChargeTutorial.tip7') }}
				</div>
			</div>
			<div>
				<div class="form-group text-center">
					<input id="noWizardNextTime" type="checkbox" v-model="noWizardNextTime" class="switch form-check-input" />
					<label for="noWizardNextTime" class="form-check-label media-body">{{ $t('startChargeTutorial.dontShowThisAgain') }}</label>
				</div>
				<div class="form-group text-center mt-4">
					<!-- <button class="btn btn-lg btn-success" @click="$emit('event', 'START_CHARGE')">
						<font-awesome-icon icon="play" />
						{{ $t('chargerView.startCharging') }}
					</button> -->
					<button @click="$emit('event', 'START_CHARGE')" class="btn btn-success stretched-link border-none start-charging-btn" :style="{ 'min-width': '250px', 'min-height': '50px', 'background-color': '#84cc16', border: 'none' }">
						<i class="pi pi-play"></i>
						{{ $t('chargerView.startCharging') }}
					</button>
				</div>
			</div>
		</carousel>
	</div>
</template>

<script>
import carousel from 'vue-owl-carousel2';
import $ from 'jquery';

export default {
	name: 'StartChargeTutorial',
	components: { carousel },
	emits: ['event'],
	data() {
		return {
			noWizardNextTime: false
		};
	},
	props: {
		parent: Object
	},
	methods: {
		init() {
			this.handleButtons(0, 7);
		},
		changedTo(event) {
			this.handleButtons(event.page.index, event.page.count);
		},
		handleButtons(index, count) {
			if (index <= 0) {
				$('.owl-prev').css('visibility', 'hidden');
			} else {
				$('.owl-prev').css('visibility', 'visible');
			}
			if (index >= count - 1) {
				$('.owl-next').css('visibility', 'hidden');
			} else {
				$('.owl-next').css('visibility', 'visible');
			}
		}
	},
	watch: {
		noWizardNextTime() {
			if (this.parent) {
				this.parent.noWizardNextTime = this.noWizardNextTime;
			}
		}
	}
};
</script>

<style>
.owl-dot {
	pointer-events: none;
}
.owl-prev {
	background: #878789 !important;
}
.owl-next {
	background: var(--mode-color) !important;
}
.owl-carousel .owl-stage-outer,
.owl-stage {
	max-height: 280px !important;
}
.img-wizard {
	max-width: 150px !important;
}
@media only screen and (max-width: 767px) {
	.img-wizard {
		max-width: 80px !important;
	}
}
</style>
