import $ from 'jquery';

export function scrollToAnchor(aid, offset, time) {
	setTimeout(() => {
		if (typeof offset === 'undefined') {
			offset = 0;
		}
		var stickyNav = $('.site-nav.sticky-top');
		if (stickyNav.length > 0) {
			offset += stickyNav.height();
		}
		if (typeof time === 'undefined') {
			time = 500;
		}
		var aTag = $("a[name='" + aid + "']");
		var o = aTag.offset();
		if (o) {
			if (time == 0) {
				window.scrollTo(0, o.top - offset);
			} else {
				$('html,body').animate({ scrollTop: o.top - offset }, time);
			}
		}
	}, 0);
}

export function scrollToTop(time) {
	setTimeout(() => {
		if (typeof time === 'undefined') {
			time = 500;
		}
		if (time == 0) {
			window.scrollTo(0, 0);
		} else {
			$('html, body').animate({ scrollTop: 0 }, time);
		}
	}, 0);
}
