<template>
	<div class="wallet-outer2" :class="{ 'max-h-130': hideAddFundsButton}" :style="{'min-width':'300px'}">
		<div class="wallet-outer">
			<div class="wallet">
				<div class="wallet-header position-relative centered py-1">
					<div class="position-absolute" :style="{ width: '50px', height: '50px', left: 0 }">
						<img src="@/assets/cards.png" alt="" :style="{ width: '100%' }" />
					</div>
					<p class="mb-0" :style="{ 'font-weight': '300' }">{{ $t('menu.wallet') }}</p>
				</div>

				<div class="text-center text-danger" style="margin-top: -10px; margin-bottom: 8px" v-if="countUnpayedWallets > 0">
					{{ $t('walletView.countUnpayedWallets', [countUnpayedWallets]) }}
				</div>

				<div class="centered text-center pt-0">
					<p class="mb-0">{{ $t('walletView.balances') }}:</p>
					<span class="wallet-actual-wallet-balance-text" :class="{ negative: walletBalance < 0 }">{{ walletBalance }}<span style="font-size: 0.8em"> Ft</span></span>
				</div>

				<div v-if="!hideAddFundsButton" class="centered justify-content-center mt-3">
					<PrimeButton @click="$emit('walletAdd')" :style="{ 'border-radius': '30px', 'min-height': '30px', padding: '10px 30px', 'background-color': '#04af43', border: 'none' }" class="new-btn centered">
						<i class="pi pi-wallet"></i>
						{{ $t('walletView.sendPrepaidAmount') }}
					</PrimeButton>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'WalletView',
	props: {
		msgs: Object,
		hideAddFundsButton: Boolean
	},
	data() {
		return {
			walletBalance: null,
			countUnpayedWallets: 0,
			timer: null
		};
	},
	methods: {
		getWalletBalance() {
			this.$rest.getWalletBalance((responseData) => {
				if (responseData.result) {
					this.walletBalance = responseData.data.ballance;
					this.countUnpayedWallets = responseData.data.countUnpayedWallets;
				}
			});
		},

		refresh() {
			this.getWalletBalance();

			this.killTimer();
			this.timer = setTimeout(() => {
				this.refresh();
			}, 5000);
		},

		killTimer() {
			if (this.timer) {
				clearTimeout(this.timer);
				this.timer = null;
			}
		}
	},
	mounted() {
		this.refresh();
	},
	beforeDestroy() {
		this.killTimer();
	},
	emits: ['walletAdd']
};
</script>
<style scoped>
.max-h-130 {
	max-height: 130px;
	transform: translateY(25px);
	max-width: 280px;
}
</style>
