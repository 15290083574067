<template>
	<div v-if="loggedInUserData">
		<div class="form-group">
			<div class="row" style="margin-left: 0px">
				<span class="step-number">4</span>
				<a name="step4"></a>
				<div class="col-9" >{{ $t('charger-max.provide-kwh') }}</div>
			</div>
			<div class="blockquote-footer step-list">
				{{ $t('charger-max.1') }}
			</div>
			<div class="blockquote-footer step-list">
				{{ $t('charger-max.2') }}
			</div>
			<div class="blockquote-footer step-list">
				{{ $t('charger-max.3') }}
			</div>
			<div class="blockquote-footer step-list">
				<b>{{ $t('charger-max.4', [priceSettings.convenienceFeeGross]) }}</b>
			</div>
			<div class="blockquote-footer step-list">
				<b>{{ $t('charger-max.5', [priceSettings.unitChargingGross]) }}</b>
			</div>
		</div>
		<div class="form-group text-center">
			<NumberInput
				id="maxKwInputLabel"
				v-model="selectedKw"
				:msgs="msgs"
				class="form-control"
				maxlength="4"
				:required="true"
				type="number"
				@change="$emit('onKwPicked', '')"
				:placeholder="$t('charger-max.maxKWhPlaceholder')"
			/>
			<div class="mt-3">
				<button class="btn btn-primary p-3" @click="inputChanged()">
					<font-awesome-icon icon="check"/>
					{{ $t('charger-max.choose-energy') }}
				</button>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "ChargeMaxKwTemplate",
	props: {
		msgs: Object,
		priceSettings: Object
	},
	emits: ['onKwPicked', 'clearKwInputField'],
	data() {
		return {
			selectedKw: null
		}
	},
	asyncComputed: {
		async loggedInUserData() {
			return await this.$store.getters.getLoggedInUserData
		}
	},
	methods: {
		inputChanged() {
			if(this.selectedKw) {
				if(this.selectedKw < 1) {
					alert(this.$t('charger-max.energy-cannot-be-less'))
					this.selectedKw = null
					this.$emit('onKwPicked', '')
				}
				else if(this.selectedKw > 500) {
					alert(this.$t('charger-max.energy-cannot-be-more'))
					this.selectedKw = null
					this.$emit('onKwPicked', '')
				}
				else {
					this.$emit('onKwPicked', this.selectedKw)
				}
			}
			else {
				this.$emit('clearKwInputField')
			}
		}
	}
}
</script>

<style scoped>
</style>
