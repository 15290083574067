<template>
	<button @click="click()" class="wallet-btn" :style="isSelected ? { border: '3px solid var(--sky-500)' } : {}">
		<div class="wallet-btn-header">
			<div class="position-absolute" :style="{ width: '35px', height: '35px', left: 0, top: 0 }">
				<img src="@/assets/cards.png" alt="" :style="{ width: '100%' }" />
			</div>
			<p class="mb-0" :style="{ 'font-weight': '300' }">{{ $t('menu.wallet') }}</p>
		</div>
		<div class="wallet-btn-body">
			<p class="mb-0" style="color: black; font-size: 0.9rem">{{ $t('walletView.balance') }}:</p>
			<span class="wallet-actual-wallet-balance-text smaller-balance" :class="{ negative: balance < 0 }">{{ balance }}<span style="font-size: 0.8em"> Ft</span></span>
		</div>
	</button>
</template>
<script>
export default {
	props: {
		balance: Number,
		selected: Boolean
	},
	computed: {
		isSelected() {
			return this.selected ? this.selected : false;
		}
	},
	methods: {
		click() {
			this.$emit('click');
		}
	},
	emits: ['click']
};
</script>
<style scoped>
.wallet-btn {
	position: relative;
	border: none;
	display: flex;
	flex-direction: column;
	padding: 0;
	width: 100%;
	width: 200px;
	height: 100px;
	border-radius: 8px;
	overflow: hidden;
	color: white;
	font-weight: normal;
	background-color: var(--wallet-card-bg);
}

.wallet-btn-header {
	position: relative;
	background-color: var(--active);
	width: 100%;
	padding: 5px;
	margin-top: 10px;
}

.wallet-btn-body {
	width: 100%;
	height: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
}

.smaller-balance {
	font-size: 0.9rem;
}
</style>
