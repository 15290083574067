<template>
	<div>

		<div class="alert alert-info media mb-2">
			<font-awesome-icon icon="exclamation-triangle" size="2x" class="mr-3" />
			<div class="media-body">
				<b>Bankkártya felvétele</b><br/><br/>
				<div class="text-justify">A töltések fizetéséhez ezen a felületen felveheted a bankkártyádat. A bankkártya hozzáadása nem kötelező. Ha nem veszel fel bankkártyát, akkor is fogsz tudni fizetni a SimplePay fizetésszolgáltató felületén keresztül. Bankkártya felvétele esetén pedig annak adatait szintén a SimplePay fogja tárolni. Ha most nem veszel fel bankkártyát, akkor azt később a 'Fizetési módok' menüben később is megteheted.</div>
			</div>
		</div>

		<div class="d-flex">
			<button @click="navigateBackward()" class="btn btn-outline-secondary">
				<font-awesome-icon icon="chevron-left"/>
				{{ $t('wizard.prev') }}
			</button>

			<button @click="navigateForward" class="btn btn-outline-dark ml-auto mr-0 wizard-next-btn">
				{{ $t(nextButtonText) }}
				<font-awesome-icon icon="chevron-right" class="ml-1"/>
			</button>
		</div>

		<CreditCardsProcessAlert class="mt-2 mb-0" />

		<CreditCards @changed="cardsChanged" />

		<CardRegistrationForm :wizard="true" v-if="!hasCards" />

	</div>
</template>

<script>
import CardRegistrationForm from "@/components/CardRegistrationForm"
import CreditCards from '@/components/CreditCards'
import CreditCardsProcessAlert from '@/components/CreditCardsProcessAlert'

export default {
	name: "CreditCardsWizardView",
	components: { CardRegistrationForm, CreditCards, CreditCardsProcessAlert },
	data() {
		return {
			app: null,
			onBoardingDetails: null,
			routeDestination: '/',
			hasCards: false
		}
	},
	props: {
		msgs: Object,
		loggedInUserData: Object,
		onBoardingDetailsProp: Object
	},
	methods: {
		cardsChanged(cards) {
			this.hasCards = cards && cards.length > 0
		},
		onSaved() {
			this.recalcNextButtonData()
			setTimeout(() => this.app.scrollUp(), 1000)
		},
		navigateForward() {
			if(!this.hasCards) {
				this.$rest.skipCreditCardAddition()
			}

			if(this.routeDestination === '/') {
				this.app.onWizardFinished()
			}
			else {
				this.$router.push(this.routeDestination)
			}
		},
		navigateBackward() {
			this.$router.go(-1)
		},
		recalcNextButtonData() {
			this.routeDestination = '/'
		}
	},
	computed: {
		nextButtonText() {
			return this.hasCards ? 'wizard.startUsing' : 'wizard.skipAddBankCard'
		}
	},
	created() {
		this.app = this.$root.$children[0]
	},
	beforeMount() {
		this.onBoardingDetails = this.onBoardingDetailsProp
		this.recalcNextButtonData()
	},
	mounted() {
		this.app.keepInWizard = true
		document.body.scrollTop = 0
		document.documentElement.scrollTop = 0
	}
}
</script>
