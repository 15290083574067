<template>
	<div class="no-shadow-container border mx-auto" style="border-radius: 10px; max-width: 600px; min-height: 320px">
		<h2 class="header-common pt-3">{{ $t('cards.startCardRegistrationButton') }}</h2>
		<div class="info-container text-center" :style="{ 'border-radius': '10px', margin: '10px' }">
			<i class="pi pi-info-circle mr-1" :style="{ 'font-size': '1rem', color: 'var(--info-icon)' }"></i>
			{{ $t('cards.creditCardAcceptMsg_1') }}
			<a href="#/privacy-statements" target="_blank">{{ $t('cards.creditCardAcceptMsg_link') }}</a>
			{{ $t('cards.creditCardAcceptMsg_2') }}
		</div>
		<div class="text-center">
			<a href="http://simplepartner.hu/PaymentService/Fizetesi_tajekoztato.pdf" target="_blank">
				<img alt="simplepay logo" src="../assets/simplepay/simplepay_bankcard_logos_left_482x40.png" class="img-fluid" />
			</a>
		</div>
		<div class="form-group buttonRow text-center register-button-row my-0">
			<PrimeButton @click="startCardRegistration()" style="background-color: var(--newbtn-blue)" :style="{ width: '100%', height: '50px', 'border-radius': '6px', 'min-height': '40px', padding: '0px 20px' }" class="new-btn centered">
				<i class="pi pi-plus-circle"></i>
				<span>{{ $t('cards.startCardRegistrationButton') }}</span>
			</PrimeButton>
		</div>
	</div>
</template>

<style>
.register-button-row > button {
	margin-bottom: 10px;
}

.register-button-row {
	padding: 1rem;
	margin-top: 1rem;
	margin-bottom: 1rem;
}
</style>

<script>
export default {
	name: 'CardRegistrationForm',
	props: {
		wizard: Boolean
	},
	methods: {
		startCardRegistration() {
			this.$emit('hideWalletAdd');
			this.$rest.registerCreditCard(this.wizard, (url) => {
				window.location.href = url;
			});
		}
	},
	emits: ['hideWalletAdd']
};
</script>
