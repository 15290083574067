<template>
	<div class="wallet-outer" :style="{ color: 'white' }">
		<div class="wallet cc px-1">
			<div class="card-top-container px-2">
				<div>
					<span class="default-text-title" v-if="card.defaultCard">{{ $t('cards.default-card') }}</span>
					<PrimeButton v-else @click="changeDefaultCard(card)" class="centered" :style="{ width: '35px', height: '35px', 'border-radius': '30px', padding: '2px', 'background-color': 'var(--default-green)', border: 'none' }">
						<i class="pi pi-wallet"></i>
					</PrimeButton>
				</div>
				<div class="d-flex justify-content-end mb-0">
					<p class="mb-0" :style="{ 'font-size': '14px' }">Saját kártya</p>
				</div>
			</div>

			<div class="centered px-2" :class="card.defaultCard ? 'mt-3' : 'mt-2'">
				<div class="centered chip-container">
					<img src="@/assets/chip.png" alt="" />
				</div>
				<div v-if="card.cardMask" class="card-mask-container2">{{ formattedCardMask }}</div>
			</div>

			<div v-if="!card.cardMask" class="wallet-progress centered">
        <p class="mb-0">{{ $t('cards.processing') }}</p>
        <i v-if="!card.cardMask" class="pi pi-spin pi-spinner" style="font-size: 1.4rem"></i>
      </div>
			<div class="wallet-footer px-2">
				<PrimeButton @click="deleteCard(card)" class="centered" :style="{ width: '35px', height: '35px', 'border-radius': '30px', padding: '2px', 'background-color': '#ef4444', border: 'none' }">
					<i class="pi pi-trash"></i>
				</PrimeButton>
				<span v-if="card.cardMask" :style="{ color: 'white', 'font-weight': 'normal', 'margin-right': '8px' }">{{ formatDate(card.expiry) }}</span>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'CreditCard',
	props: {
		card: Object
	},
	data() {
		return {
			formattedCardMask: ''
		};
	},
	methods: {
		deleteCard(card) {
			let text;
			if (card.cardMask) {
				text = this.$t('cards.confirmDelete') + '\n\n' + card.cardMask + '\n' + this.formatDate(card.expiry);
			} else {
				text = this.$t('cards.confirmDelete2');
			}

			if (confirm(text)) {
				this.$rest.removeCreditCard(card.cardId, (resp) => {
					this.$emit('changed');
				});
			}
		},

		changeDefaultCard(card) {
			this.$rest.changeDefaultCreditCard(card.cardId, (resp) => {
				this.$emit('changed');
			});
		},

		formatDate(dateStr) {
			const date = new Date(dateStr);
			const formattedDate = `${date.getFullYear()}/${date.getMonth() + 1}`;
			return formattedDate;
		}
	},
	watch: {
		card: {
			handler() {
				this.formattedCardMask = this.card.cardMask ? '•••• •••• •••• ' + this.card.cardMask.slice(-4) : '';
			},
			immediate: true
		}
	},
	emits: ['changed']
};
</script>
<style scoped>
.card-mask-container {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 1.4rem;
	margin-top: 10px;
	margin: 10px 20px 0 20px;
	border: 1px solid #dbdbdb85;
	border-radius: 4px;
	background-color: rgb(238, 237, 237);
	color: black;
}

.card-mask-container2 {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 1.4rem;
	margin-top: 10px;
	margin: 10px 20px 0 20px;
	/* border: 1px solid #dbdbdb85; */
	border-radius: 4px;
	background-color: transparent;
	color: white;
}

.card-top-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px 0;
}

.chip-container {
	width: 40px;
	height: 30px;
	overflow: hidden;
	border-radius: 4px;
}

.chip-container img {
	width: 100%;
}

.wallet-progress{
  background-color: var(--mode-color);
  border-radius: 3px;
  margin-top: 10px;
  padding: 10px 0;
}

.wallet-footer {
	position: absolute;
	bottom: 6px;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
</style>
