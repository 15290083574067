<template>
	<div class="flex-1">
		<template>
			<CreditCardsProcessAlert />
			<div class="row">
				<div class="col-lg-12">
					<h2 class="header-common payment-methods-title">{{ $t('cards.myCards') }}</h2>

					<div class="cards-layout-container">
						<div class="relative credit-cards-container no-shadow-container m-0">
							<CreditCards>
								<WalletView @walletAdd="showWalletAdd = true" :hideAddFundsButton="showWalletAdd" />
							</CreditCards>
							<div v-if="showWalletAdd" class="absolute no-shadow-container wallet-add-container" :style="{ border: 'none' }">
								<WalletAdd :msgs="msgs" @cancel="showWalletAdd = false" />
							</div>
						</div>
						<div class="form-group mb-0">
							<CardRegistrationForm :wizard="false" @hideWalletAdd="showWalletAdd = false" />
						</div>
					</div>
				</div>
			</div>
		</template>
	</div>
</template>

<script>
import CardRegistrationForm from '@/components/CardRegistrationForm';
import CreditCards from '@/components/CreditCards';
import CreditCardsProcessAlert from '@/components/CreditCardsProcessAlert';
import WalletView from '@/components/WalletView';
import WalletAdd from '@/components/WalletAdd';

export default {
	name: 'CardsView',
	components: {
		CardRegistrationForm,
		CreditCards,
		CreditCardsProcessAlert,
		WalletView,
		WalletAdd
	},
	data() {
		return {
			email: '',
			phone: '',
			password: '',
			password2: '',
			selectedCard: '',
			secret: '',
			showWalletAdd: false
		};
	},
	props: {
		msgs: Object
	},
	emits: ['reloadUser']
};
</script>

<style>
.credit-cards-container {
	max-width: 600px;
	margin: 0 auto;
	padding: 10px;
  min-width: 300px;
}

.payment-methods-title {
	font-weight: 300;
}

.register-button-row > button {
	margin-bottom: 10px;
}

.register-button-row {
	padding: 1rem;
	margin-top: 1rem;
	margin-bottom: 1rem;
}

.card-spinner {
	margin-top: 10px;
}

.change-payment-method-text {
	margin-top: 5px;
	width: auto;
	display: block;
	text-align: center;
}

.btn.card-delete2,
.btn.card-default {
	padding: 10px;
	align-self: end;
	background-color: #ffffff;
}

.btn.card-delete2 > .svg-inline--fa,
.btn.card-default > .svg-inline--fa {
	margin: 0;
	height: 1rem;
	display: block;
}

.card-expiry-cv {
	margin-left: auto;
	margin-right: 0;
	color: #343a40;
	margin-top: 1.5rem;
	font-weight: bold;
}

.card-bottom {
	display: flex;
	margin-top: 15px;
	margin-bottom: 15px;
}

.wallet-add-container {
	bottom: 20px;
}

@media screen and (min-width: 320px) {
	.cards-layout-container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		gap: 20px;
	}
}

@media screen and (min-width: 700px) {
	.cards-layout-container {
		flex-direction: row;
		gap: 20px;
	}
}
</style>
