<template>
	<div>
		<div>
			<a href="javascript:history.back()" class="btn btn-outline-secondary mr-2 mb-2" v-if="showBackLink">
				<font-awesome-icon icon="chevron-left"/>
				Vissza
			</a>
		</div>
		<div class="text-center">
			<p>Copyright OpenJS Foundation and other contributors, https://openjsf.org/</p>
			<p>Permission is hereby granted, free of charge, to any person obtaining<br/>a copy of this software and associated documentation files (the<br/>"Software"), to deal in the Software without restriction, including<br/>without limitation the rights to use, copy, modify, merge, publish,<br/>distribute, sublicense, and/or sell copies of the Software, and to<br/>permit persons to whom the Software is furnished to do so, subject to<br/>the following conditions:</p>
			<p>The above copyright notice and this permission notice shall be<br/>included in all copies or substantial portions of the Software.</p>
			<p>THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND,<br/>EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF<br/>MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND<br/>NONINFRINGEMENT. IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE<br/>LIABLE FOR ANY CLAIM, DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION<br/>OF CONTRACT, TORT OR OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION<br/>WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN THE SOFTWARE.</p>
		</div>
	</div>
</template>

<script>
export default {
	name: "JqueryLicenseView",
	data() {
		return {
			showBackLink: history.length > 1
		}
	}
}
</script>

<style scoped>

</style>
