<template>
	<div class="shadow-container-1000 d-flex flex-column flex-grow-1">
		<div class="text-center charge-history-header mb-auto">
			<h1>{{ $t('invoices.title') }}</h1>
			<div class="filter-container">
				<div class="col-12 form-group">
					<label for="billingDatas">{{ $t('invoices.billingDatas') }}</label>
					<select id="billingDatas" v-model="selectedBillingData" class="form-control">
						<option v-for="billingData in billingDatas" :key="billingData.id" :value="billingData" :selected="selectedBillingData && billingData.id == selectedBillingData.id">
							{{ billingData.labelName }}
						</option>
					</select>
					<ComponentMessage :msgs="msgs" forComponent="vehicles" />
				</div>
			</div>

			<div class="my-5 auto-margin text-center" v-if="selectedBillingData">
				<div v-for="(invoice, index) in invoiceList" :key="invoice.id" class="my-4">
					<a class="pdf-info" :onclick="invoice.path ? '' : 'return false;'" :href="invoice.path ? $rest.baseUrl + '/file?type=invoice&name=' + invoice.path + '&token=' + ls.getItem('loggedInUserToken') : 'javascript:void(0)'" target="_blank">
						<div class="flex-start align-items-center mb-2"> <span class="index-container">{{index+1}}.</span> {{$t('invoices-view.issue')}}</div>
            <div class="flex-between issue-body">
							<div class="issue-body-content">
                <div class="flex-start">
                  <div class="centered">
                  <p class="mb-0">{{ $t('invoices-view.create-date') }}</p>
                  <div>{{ invoice.createdAt }}</div>
                </div></div>
                <div class="flex-start">
                  <div class="centered">
                    <p class="mb-0">{{ $t('invoices-view.order-date') }}</p>
                    <div>{{ invoice.invoiceIdentifier }}</div>
                  </div>
                </div>
              </div>
              <PrimeButton type="button" style="background-color: var(--newbtn-blue)" :style="{ width: '100%', 'max-width':'250px', height: '50px', 'border-radius': '6px', 'min-height': '40px', padding: '0px 20px' }" class="new-btn centered">
                <i class="pi pi-file"></i>
                <span>{{ $t('invoices-view.view-issue') }}</span>
              </PrimeButton>
						</div>
					</a>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'InvoicesView',
	props: {
		msgs: Object
	},
	data() {
		return {
			billingDatas: [],
			selectedBillingData: null,
			label: '',
			invoiceList: [],
			ls: window.localStorage
		};
	},
	watch: {
		selectedBillingData(newBillingData) {
			if (newBillingData) {
				this.getInvoices();
			}
		}
	},
	methods: {
		getInvoices() {
			this.$rest.getInvoicesOfBillingdata(this.selectedBillingData.id, (responseData) => {
				this.invoiceList = responseData;
			});
		},
		getBillingDatasOfUser() {
			this.$rest.listBillingDataForCurrentUser((responseData) => {
				this.billingDatas = this.setBillingLabelName(responseData);
				if (this.billingDatas && this.billingDatas.length > 0) {
					this.selectedBillingData = this.billingDatas[0];
				}
			});
		},
		setBillingLabelName(billingDatas) {
			const billingDatasWithLabelName = [];
			billingDatas.forEach((billingData) => {
				const labelName = billingData.payerName + ' - ' + billingData.address.full;
				billingData = { ...billingData, labelName };
				billingDatasWithLabelName.push(billingData);
			});
			return billingDatasWithLabelName;
		}
	},
	beforeMount() {
		this.getBillingDatasOfUser();
	}
};
</script>

<style scoped>

.index-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--active);
  color: white;
  width: 23px;
  height: 23px;
  border-radius: 50%;
  margin-right: 3px;
}

@media screen and (min-width: 320px){
  .filter-container {
    margin-top: 40px;
    max-width: 40rem;
    margin-left: auto;
    margin-right: auto;
  }

  .pdf-info {
    display: block;
    text-decoration: none;
    color: #000000;
    box-shadow:none;
    overflow:hidden;
    max-width: 40rem;
    margin: 0 auto;
    border: 1px solid rgb(236, 236, 236);
    padding: 20px;
    border-radius: 10px;
  }

  .issue-body{
    flex-direction: column;
    gap: 10px;
  }

  .issue-body-content{
    display: flex;
    flex-direction:column;
    margin-top: 5px;
    gap: 10px;
  }
}

@media screen and (min-width: 650px){
  .pdf-info {
    display: block;
    text-decoration: none;
    color: #000000;
    box-shadow:none;
    overflow:hidden;
    max-width: 40rem;
    margin: 0 auto;
    border: 1px solid rgb(236, 236, 236);
    padding: 20px;
    border-radius: 10px;
  }

  .issue-body{
    flex-direction: row;
  }
}
</style>
