"use strict"

import Vue from 'vue'
import axios from 'axios'
import 'url-search-params-polyfill'

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

var baseUrl

if(window.location.href.indexOf('http://localhost') === 0 || window.location.href.indexOf('file:///Users/') === 0) {
	baseUrl = 'http://local.freecharger.hu:8080/rest'
	// baseUrl = 'http://localhost:8080/rest';
}
else if(window.location.href.indexOf('https://teszt.freecharger.hu') === 0) {
	baseUrl = 'https://teszt.freecharger.hu/rest'
}
else {
	baseUrl = 'https://app.freecharger.hu/rest'
	// baseUrl = 'https://teszt.freecharger.hu/rest' // For android test
}
// baseUrl = 'http://localhost:8080/rest';
baseUrl = 'https://teszt.freecharger.hu/rest'
axios.defaults.baseURL = baseUrl

// axios.defaults.baseURL = "http://localhost:8080/rest"

let config = {
	// baseURL: process.env.baseURL || process.env.apiUrl || ''
	// timeout: 60 * 1000, // Timeout
	// withCredentials: true, // Check cross-site Access-Control
}

const _axios = axios.create(config)

Plugin.install = function (Vue) {
	Vue.axios = _axios
	window.axios = _axios
	Object.defineProperties(Vue.prototype, {
		axios: {
			get() {
				return _axios
			}
		},
		$axios: {
			get() {
				return _axios
			}
		},
	})
}

const _rest = {
	install() {
		Object.defineProperty(Vue.prototype, '$rest', {
			get() {
				return _rest
			}
		})
	}
}

_rest.baseUrl = baseUrl

_rest.getOAuthClient = function (clientId, callback, callbackError) {
	_axios.get('/v1/oauth/client/' + clientId)
		.then((response) => {
			//console.log(response)
			if(response.data) {
				if(typeof callback === 'function') {
					callback(response.data)
				}
			}
		})
		.catch((error) => {
			if(typeof callbackError === 'function') {
				callbackError(error)
			}
		})
}

_rest.getOAuthCode = function (clientId, state, callback, callbackError) {
	var bodyFormData = new URLSearchParams()
	bodyFormData.append('clientId', clientId)
	bodyFormData.append('state', state)
	_axios.post('/v1/oauth/authorize', bodyFormData)
		.then((response) => {
			if(response.data) {
				if(typeof callback === 'function') {
					callback(response.data)
				}
			}
		})
		.catch((error) => {
			if(typeof callbackError === 'function') {
				callbackError(error)
			}
		})
}

_rest.getLoggedInUser = function (callback, callbackError) {
	var loggedInUserId = localStorage.getItem('loggedInUserId')
	var loggedInEmail = localStorage.getItem('loggedInEmail')
	var loggedInUserToken = localStorage.getItem('loggedInUserToken')
	if((loggedInUserId || loggedInEmail) && loggedInUserToken) {
		_axios.get('v1/user/loggedin-user')
			.then((response) => {
				if(typeof callback === 'function') {
					callback(response.data.data, response.data.extraData)
				}
			})
			.catch((error) => {
				if(typeof callbackError === 'function') {
					callbackError(error)
				}
			})
	}
	else {
		if(typeof callbackError === 'function') {
			callbackError(null)
		}
	}
}

_rest.getLoggedInUserAsync = async function () {
	const loggedInUserId = localStorage.getItem('loggedInUserId')
	const loggedInEmail = localStorage.getItem('loggedInEmail')
	const loggedInUserToken = localStorage.getItem('loggedInUserToken')

	if(!((loggedInUserId || loggedInEmail) && loggedInUserToken)) {
		throw new Error('not_logged_in')
	}

	const resp = await _axios.get('v1/user/loggedin-user')

	return resp.data
}

_rest.getSettingsAsync = async function () {
	const resp = await _axios.get('v1/user/settings')
	return resp.data.data
}

_rest.deleteProfile = function (callback) {
	_axios.delete('v1/user/delete')
		.then((response) => {
			if(typeof callback === 'function') {
				callback(response.data.result)
			}
		})
}

_rest.addCar = function (vehicleId, licencePlate, callback) {
	const bodyFormData = new URLSearchParams()
	if(vehicleId) {
		bodyFormData.append('vehicleId', vehicleId)
		if(licencePlate) {
			bodyFormData.append('licencePlate', licencePlate)
		}
	}


	_axios.post('v1/car', bodyFormData)
		.then((response) => {
			if(response.data.result) {
				if(typeof callback === 'function') {
					callback(response.data.data, axios.defaults.baseURL)
				}
			}
		})
		.catch((error) => {
		})
}

_rest.getPoints = function (callback) {
	_axios.get('/v1/coupon')
		.then((response) => {
			if(response.data) {
				if(typeof callback === 'function') {
					callback(response.data)
				}
			}
		})
		.catch((error) => {
			//console.log(error)
		})
}

_rest.getPointsHistory = function (callback) {
	_axios.get('/v1/coupon/history')
		.then((response) => {
			if(response.data) {
				if(typeof callback === 'function') {
					callback(response.data)
				}
			}
		})
		.catch((error) => {
			//console.log(error)
		})
}


_rest.loadCategoryTraits = function (callback) {
	_axios.get('/v1/coupon/coupon-categories')
		.then((response) => {
			if(response.data) {
				if(typeof callback === 'function') {
					callback(response.data)
				}
			}
		})
		.catch((error) => {
			//console.log(error)
		})
}

_rest.getCouponInventory = function (callback) {
	_axios.get('/v1/coupon/coupon-inventory')
		.then((response) => {
			if(response.data) {
				if(typeof callback === 'function') {
					callback(response.data)
				}
			}
		})
		.catch((error) => {
			//console.log(error)
		})
}

_rest.getUserCoupons = function (callback) {
	_axios.get('/v1/coupon/my-coupons')
		.then((response) => {
			if(response.data) {
				if(typeof callback === 'function') {
					callback(response.data)
				}
			}
		})
		.catch((error) => {
			//console.log(error)
		})
}

_rest.buyCoupon = function (id, callback) {
	_axios.get(`/v1/coupon/buy-coupon/${id}`)
		.then((response) => {
			if(response.data) {
				if(typeof callback === 'function') {
					callback(response.data)
				}
			}
		})
		.catch((error) => {
			//console.log(error)
		})
}

_rest.deleteUserCoupon = function (id, callback) {
	_axios.delete(`v1/coupon/delete-coupon/${id}`)
		.then((response) => {
			if(typeof callback === 'function') {
				callback(response.data)
			}
		})
		.catch((error) => {
			//console.log(error)
		})
}


_rest.getAvailableCouponQr = function (callback) {
	_axios.get('/v1/coupon/active-coupon')
		.then((response) => {
			if(response) {
				if(typeof callback === 'function') {
					callback(response.data)
				}
			}
		})
		.catch((error) => {
			//console.log(error)
		})
}

_rest.generateCouponQr = function (points, callback) {
	_axios.get(`/v1/coupon/generate-coupon?points=${points}`)
		.then((response) => {
			if(response.data) {
				if(typeof callback === 'function') {
					callback(response.data)
				}
			}
		})
		.catch((error) => {
			//console.log(error)
		})
}

_rest.generateCouponQr = function (points, callback) {
	_axios.get(`/v1/coupon/generate-coupon?points=${points}`)
		.then((response) => {
			if(response.data) {
				if(typeof callback === 'function') {
					callback(response.data)
				}
			}
		})
		.catch((error) => {
			//console.log(error)
		})
}

_rest.removeActiveCoupon = function (callback) {
	_axios.delete(`v1/coupon`)
		.then((response) => {
			if(typeof callback === 'function') {
				callback(response.data)
			}
		})
		.catch((error) => {
			//console.log(error)
		})
}

_rest.onlineWalletDeposit = function (amount, email, callback) {
	const bodyFormData = new URLSearchParams()
	if(!amount) return
	if(!email) return
	bodyFormData.append("amount", amount)
	bodyFormData.append("email", email)

	_axios.post('v1/online-wallet/deposit', bodyFormData)
		.then((response) => {
			if(response.data) {
				if(typeof callback === 'function') {
					callback(response.data.result)
				}
			}
		})
		.catch((error) => {
			if(typeof callback === 'function') {
				callback(false)
			}
		})
}

_rest.isOnlineWalletUserExists = function (email, callback) {
	_axios.get('/v1/online-wallet/user-exists?email=' + email)
		.then((response) => {
			if(response.data != null) {
				if(typeof callback === 'function') {
					callback(response.data)
				}
			}
		})
		.catch((error) => {
			callback(false)
		})
}


_rest.getChargePorts = function (callback) {
	_axios.get('/v1/vehicle/charger-ports')
		.then((response) => {
			if(response.data) {
				if(typeof callback === 'function') {
					callback(response.data)
				}
			}
		})
		.catch((error) => {
			//console.log(error)
		})
}

_rest.changeDefaultCreditCard = function (cardId, callback) {
	_axios.get(`v1/user/default-card/${cardId}`)
		.then((response) => {
			if(typeof callback === 'function') {
				callback(response.data)
			}
		})
		.catch((error) => {
			//console.log(error)
		})
}

_rest.removeCreditCard = function (cardId, callback) {
	_axios.delete(`v1/user/delete-card/${cardId}`)
		.then((response) => {
			if(typeof callback === 'function') {
				callback(response.data)
			}
		})
		.catch((error) => {
			//console.log(error)
		})
}

_rest.removeMyCars = function (car, callback) {
	_axios.delete(`v1/car/${car.id}`)
		.then((response) => {
			if(typeof callback === 'function') {
				callback(response.data, axios.defaults.baseURL)
			}
		})
		.catch((error) => {
			//console.log(error)
		})
}

_rest.defaultCar = function (car, callback) {
	_axios.patch(`v1/car/default/${car.id}`)
		.then((response) => {
			if(typeof callback === 'function') {
				callback(response.data, axios.defaults.baseURL)
			}
		})
		.catch((error) => {
			//console.log(error)
		})
}

_rest.loadMyCars = function (callback) {
	_axios.get('v1/car')
		.then((response) => {
			if(typeof callback === 'function') {
				callback(response.data, axios.defaults.baseURL)
			}
		})
		.catch((error) => {
			//console.log(error)
		})
}

_rest.loadVehicles = function (callback) {
	_axios.get('v1/vehicle')
		.then((response) => {
			if(typeof callback === 'function') {
				callback(response.data)
			}
		})
		.catch((error) => {
			//console.log(error)
		})
}

_rest.addNewVehicle = function (vehicle, callback) {
	_axios.post('v1/vehicle', vehicle)
		.then((response) => {
			if(response.data.result) {
				if(typeof callback === 'function') {
					callback(response.data.data)
				}
			}
		})
		.catch((error) => {
		})
}

_rest.modifyVehicle = function (vehicle, callback) {
	_axios.patch('v1/vehicle', vehicle)
		.then(response => {
			if(response.data.result) {
				if(typeof callback === 'function') {
					callback(response.data.data)
				}
			}
		}).catch((error) => {
		})
}

_rest.modifyCar = function (car, callback) {
	_axios.patch('v1/car', car)
		.then(response => {
			if(response.data) {
				if(typeof callback === 'function') {
					callback(response.data)
				}
			}
		}).catch(error => {
		})
}

_rest.getCreditCards = function (callback) {
	_axios.get('v1/user/credit-cards')
		.then((response) => {
			if(typeof callback === 'function') {
				callback(response.data)
			}
		})
		.catch((error) => {
			//console.log(error)
		})
}

_rest.login = function (email, password, callback) {
	var bodyFormData = new URLSearchParams()
	if(email) {
		bodyFormData.append('email', email)
	}
	if(password) {
		bodyFormData.append('password', password)
	}

	_axios.post('v1/user/login', bodyFormData)
		.then((response) => {
			if(response.data.result) {
				if(typeof callback === 'function') {
					callback(response.data.data, response.data.extraData)
				}
			}
		})
		.catch((error) => {
		})
}

_rest.googleLogin = function (token, callback) {
	const formData = new URLSearchParams()
	formData.append("idToken", token)
	_axios.post('v1/user/googleLogin', formData)
		.then((response) => {
			if(response.data.result) {
				if(typeof callback === 'function') {
					callback(response.data.data, response.data.extraData)
				}
			}
		})
		.catch((error) => {
			//console.log(error)
		})
}

_rest.facebookLogin = function (fbResponse, callback) {
	const formData = new URLSearchParams()
	formData.append("accessToken", fbResponse.accessToken)
	formData.append("userId", fbResponse.userID)
	formData.append("signedRequest", fbResponse.signedRequest)

	_axios.post('v1/user/facebookLogin', formData)
		.then((response) => {
			if(response.data.result) {
				if(typeof callback === 'function') {
					callback(response.data.data, response.data.extraData)
				}
			}
		})
		.catch((error) => {
			//console.log(error)
		})
}

_rest.appleLogin = function (userId, name, givenName, familyName, email, callback) {
	const formData = new URLSearchParams()
	formData.append("user", userId)
	formData.append("name", name)
	formData.append("given_name", givenName)
	formData.append("family_name", familyName)
	if(email) {
		formData.append("email", email)
	}

	_axios.post('v1/user/appleLogin', formData)
		.then((response) => {
			if(response.data.result) {
				if(typeof callback === 'function') {
					callback(response.data.data, response.data.extraData)
				}
			}
		})
		.catch((error) => {
			//console.log(error)
		})
}

_rest.facebookLoginNative = function (accessToken, userID, name, email, callback) {
	const formData = new URLSearchParams()
	formData.append("accessToken", accessToken)
	formData.append("userId", userID)
	formData.append("name", name)
	if(email) {
		formData.append("email", email)
	}

	_axios.post('v1/user/facebookLogin', formData)
		.then((response) => {
			if(response.data.result) {
				if(typeof callback === 'function') {
					callback(response.data.data, response.data.extraData)
				}
			}
		})
		.catch((error) => {
			//console.log(error)
		})
}

_rest.logout = function (callback) {
	_axios.get('v1/user/logout')
		.then((response) => {
			if(response.data.result) {
				if(typeof callback === 'function') {
					callback(response.data.data)
				}
			}
		})
		.catch((error) => {
		})
}

_rest.registerCreditCard = function (wizard, callback) {
	const bodyFormData = new URLSearchParams()
	bodyFormData.append('platform', window.platform)
	bodyFormData.append('wizard', wizard)

	_axios.post('v1/simplepay/card-registration', bodyFormData)
		.then((response) => {
			if(response.data) {
				if(typeof callback === 'function') {
					callback(response.data)
				}
			}
		})
		.catch((error) => {
		})
}

_rest.forgottenPasswordEmail = function (email, callback) {
	const bodyFormData = new URLSearchParams()
	if(email) {
		bodyFormData.append('email', email)
	}
	_axios.post('v1/user/forget-password', bodyFormData)
		.then((response) => {
			if(response.data) {
				if(typeof callback === 'function') {
					callback(response.data)
				}
			}
		})
		.catch((error) => {
		})
}

_rest.forgottenPasswordChange = function (password, password2, token, callback) {
	const bodyFormData = new URLSearchParams()
	if(password) {
		bodyFormData.append('password', password)
	}
	if(password2) {
		bodyFormData.append('password2', password2)
	}
	if(token) {
		bodyFormData.append('token', token)
	}

	_axios.post('v1/user/forget-password-change', bodyFormData)
		.then((response) => {
			if(response.data) {
				if(typeof callback === 'function') {
					callback(response.data)
				}
			}
		})
		.catch((error) => {
		})
}

_rest.saveLang = function (lang) {
	const bodyFormData = new URLSearchParams()
	if(lang) {
		bodyFormData.append('lang', lang)
	}

	_axios.put('v1/user/lang', bodyFormData)
}

_rest.saveProfile = function (email, phone, password, password2, oldPassword, lang, callback) {
	const bodyFormData = new URLSearchParams()

	if(email) {
		bodyFormData.append('email', email)
	}

	if(phone) {
		bodyFormData.append('phone', phone)
	}
	if(password) {
		bodyFormData.append('password', password)
	}
	if(password2) {
		bodyFormData.append('password2', password2)
	}
	if(oldPassword) {
		bodyFormData.append('oldPassword', oldPassword)
	}
	if(lang) {
		bodyFormData.append('lang', lang)
	}

	_axios.put('v1/user/update', bodyFormData)
		.then((response) => {
			if(response) {
				if(typeof callback === 'function') {
					callback(response)
				}
			}
		})
		.catch((error) => {
		})
}

_rest.register = function (email, phone, password, password2, accepted, chargerId, gateId, code, inputCode, callback) {
	var bodyFormData = new URLSearchParams()
	if(email) {
		bodyFormData.append('email', email)
	}
	if(phone) {
		bodyFormData.append('phone', phone)
	}
	if(password) {
		bodyFormData.append('password', password)
	}
	if(password2) {
		bodyFormData.append('password2', password2)
	}
	if(accepted) {
		bodyFormData.append('accepted', accepted)
	}
	if(chargerId) {
		bodyFormData.append('chargerId', chargerId)
	}
	if(gateId) {
		bodyFormData.append('gateId', gateId)
	}
	if(code) {
		bodyFormData.append('code', code)
	}
	if(inputCode) {
		bodyFormData.append('inputCode', inputCode)
	}
	_axios.post('v1/user/register', bodyFormData)
		.then((response) => {
			if(response.data.result) {
				if(typeof callback === 'function') {
					callback(response.data.data)
				}
			}
		})
		.catch((error) => {
		})
}

_rest.getInvoicesOfBillingdata = (billingDataId, callback) => {
	_axios.get('/v1/invoice/invoices-of-billingdata', {
		params: {
			id: billingDataId
		}
	}).then((response) => {
		if(response.data.result) {
			if(typeof callback === 'function') {
				callback(response.data.data)
			}
		}
	})
}

_rest.listBillingDataForCurrentUser = function (callback) {
	_axios.get('v1/billing-data/list-current-user')
		.then((response) => {
			if(response.data.result) {
				if(typeof callback === 'function') {
					callback(response.data.data)
				}
			}
		})
		.catch((error) => {
		})
}

_rest.getCountries = (callback) => {
	_axios.get('v1/billing-data/countries', {
	}).then(response => {
		if(response.data) {
			if(typeof callback === 'function') {
				callback(response.data)
			}
		}
	})
}

_rest.saveBillingDataForCurrentUser = function (billingData, callback) {
	_axios.post('v1/billing-data/save-current-user', billingData)
		.then((response) => {
			if(response.data.result) {
				if(typeof callback === 'function') {
					callback(response.data.data)
				}
			}
		})
		.catch((error) => {
		})
}

_rest.deleteBillingDataForCurrentUser = function (billingDataId, callback) {
	_axios.get('v1/billing-data/delete-current-user', {
		params: {
			id: billingDataId
		}
	})
		.then((response) => {
			if(response.data.result) {
				if(typeof callback === 'function') {
					callback(response.data.data)
				}
			}
		})
		.catch((error) => {
		})
}

_rest.loadLatestCharge = function (callback) {
	_axios.get('/v1/charge/last-finished')
		.then((response) => {
			if(typeof callback === 'function') {
				callback(response.data)
			}
		})
		.catch((error) => {
			//console.log(error)
		})
}

_rest.loadChargeHistory = function (fromDate, callback) {
	_axios.get('/v1/charge/history', {
		params: {
			filterFrom: fromDate
		}
	})
		.then((response) => {
			if(typeof callback === 'function') {
				callback(response.data)
			}
		})
		.catch((error) => {
			//console.log(error)
		})
}

_rest.loadNews = function (callback) {
	_axios.get('/v1/news', {
		params: {}
	})
		.then((response) => {
			if(typeof callback === 'function') {
				callback(response.data)
			}
		})
		.catch((error) => {
			//console.log(error)
		})
}

_rest.loadNewsFiltered = function (callback) {
	_axios.post('/v1/news/filtered', localStorage.getItem('shownNews'))
		.then((response) => {
			if(typeof callback === 'function') {
				callback(response.data)
			}
		})
		.catch((error) => {
			//console.log(error)
		})
}

_rest.loadNewsPost = function (id, callback) {
	_axios.get('/v1/news/' + id, {
		params: {}
	})
		.then((response) => {
			if(typeof callback === 'function') {
				callback(response.data)
			}
		})
		.catch((error) => {
			//console.log(error)
		})
}

_rest.closestAvailableLocationId = function (latitude, longitude, callback) {
	_axios.get('/v1/location/closest-location', {
		params: {
			latitude: latitude,
			longitude: longitude
		}
	})
		.then((response) => {
			if(typeof callback === 'function') {
				callback(response.data)
			}
		})
		.catch((error) => {
			//console.log(error)
		})
}

_rest.findLocations = function (callback) {
	_axios.get('v1/location/locations')
		.then((response) => {
			if(typeof callback === 'function') {
				callback(response.data)
			}
		})
		.catch((error) => {
		})
}

_rest.loadCategories = function (id, callback) {
	let url = 'v1/category'
	if(id) {
		url += `/${id}`
	}
	_axios.get(url)
		.then((response) => {
			if(typeof callback === 'function') {
				callback(response.data)
			}
		})
		.catch((error) => {
		})
}

_rest.filterLocations = function (filters, callback) {
	_axios.get('v1/location/filtered-locations', {
		params: filters
	})
		.then((response) => {
			if(typeof callback === 'function') {
				callback(response.data)
			}
		})
		.catch((error) => {
		})
}

_rest.getLocation = async function (locationId, keepMessages, callback) {
	await _axios.get('v1/location/location', {
		'keepMessages': keepMessages,
		params: {
			id: locationId
		}
	})
		.then((response) => {
			if(typeof callback === 'function') {
				callback(response.data)
			}
		})
		.catch((error) => {
		})
}

_rest.getLocationWithPoi = async function (locationId, callback) {
	await _axios.get(`v1/location/location-charger-poi/${locationId}`)
		.then((response) => {
			if(typeof callback === 'function') {
				callback(response.data, axios.defaults.baseURL)
			}
		})
		.catch((error) => {
		})
}

_rest.startCharging = function (chargerId, billingDataId, carId, chargingMinutes, chargingAmount, parkCode, callback, errorCallback) {
	let params = {
		'charger-id': chargerId,
		'billing-data-id': billingDataId,
		'car-id': carId,
		'fcmToken': localStorage.getItem('fcmToken'),
		'platform': window.platform,
		'parkCode': parkCode
	}

	if(chargingMinutes) {
		params['charging-minutes'] = chargingMinutes
	}
	else if(chargingAmount) {
		params['charging-amount'] = chargingAmount
	}

	_axios.get('v1/charger/start-charging', {
		params: params
	})
		.then((response) => {
			if(typeof callback === 'function') {
				callback(response.data.data)
			}
		})
		.catch((error) => {
			errorCallback(error)
		})
}

_rest.startCasualCharging = function (chargerId, email, parkCode, callback, errorCallback) {
	let params = {
		'charger-id': chargerId,
		'email': email,
		'fcmToken': localStorage.getItem('fcmToken'),
		'platform': window.platform,
		'parkCode': parkCode
	}

	_axios.get('v1/charger/start-casual-charging', {
		params: params
	})
		.then((response) => {
			if(typeof callback === 'function') {
				callback(response.data)
			}
		})
		.catch((error) => {
			errorCallback(error)
		})
}

_rest.startChargingFromWallet = function (chargerId, billingDataId, carId, chargingMinutes, chargingAmount, parkCode, callback, errorCallback) {
	let params = {
		'charger-id': chargerId,
		'billing-data-id': billingDataId,
		'car-id': carId,
		'fcmToken': localStorage.getItem('fcmToken'),
		'platform': window.platform,
		'parkCode': parkCode
	}

	if(chargingMinutes) {
		params['charging-minutes'] = chargingMinutes
	}
	else if(chargingAmount) {
		params['charging-amount'] = chargingAmount
	}

	_axios.get('v1/charger/start-charging-wallet', {
		params: params
	})
		.then((response) => {
			if(typeof callback === 'function') {
				callback(response.data)
			}
		})
		.catch((error) => {
			errorCallback(error)
		})
}

_rest.chargeMoneyToWallet = function (amount, billingDataId, callback, errorCallback) {
	let params = {
		'amount': amount,
		'billing-data-id': billingDataId,
		'fcmToken': localStorage.getItem('fcmToken'),
		'platform': window.platform
	}

	_axios.get('v1/wallet/charge-wallet', {
		params: params
	})
		.then((response) => {
			if(typeof callback === 'function') {
				callback(response.data)
			}
		})
		.catch((error) => {
			errorCallback(error)
		})
}

_rest.confirmPaymentAndGetBalance = function (transactionId, status, callback) {
	let params = {
		'transactionId': transactionId,
		'status': status
	}
	_axios.get('v1/wallet/confirm-payment', {
		params: params
	}).then((response) => {
		if(typeof callback === 'function') {
			callback(response.data)
		}
	}).catch(error => {
	})
}

_rest.getWalletBalance = (callback) => {
	_axios.get('v1/wallet/wallet-balance').then((response) => {
		if(typeof callback === 'function') {
			callback(response.data)
		}
	}).catch(error => {
	})
}

_rest.startRegCardCharging = function (
	chargerId, billingDataId, carId, chargingMinutes,
	chargingAmount, cardId,
	lang, java, color, height, width, tz, platform, parkCode,
	callback, errorCallback
) {
	let params = {
		'charger-id': chargerId,
		'billing-data-id': billingDataId,
		'car-id': carId,
		'card-id': cardId,
		'lang': lang,
		'java': java,
		'color': color,
		'height': height,
		'width': width,
		'tz': tz,
		'fcmToken': localStorage.getItem('fcmToken'),
		'platform': platform,
		'parkCode': parkCode
	}

	if(chargingMinutes) {
		params['charging-minutes'] = chargingMinutes
	}
	else if(chargingAmount) {
		params['charging-amount'] = chargingAmount
	}

	_axios.get('v1/charger/start-charging-card', {
		params: params
	})
		.then((response) => {
			// if(response.status!==200){
			// 	errorCallback(response)
			// }else{
			//console.log(response.data);
			if(typeof callback === 'function') {
				callback(response.data.data)
			}
			//}
		})
		.catch((error) => {
			errorCallback(error)
		})
}

_rest.getGrossToPay = function (
	chargerId, carId, chargingMinutes,
	callback, errorCallback
) {
	let params = {
		'charger-id': chargerId,
		'car-id': carId,
		'charging-minutes': chargingMinutes,
		'fcmToken': localStorage.getItem('fcmToken')
	}

	return _axios.get('v1/charger/get-gross-to-pay', {
		params: params
	})
		.then((response) => {
			// if(response.status!==200){
			// 	errorCallback(response)
			// }else{
			if(typeof callback === 'function') {
				callback(response.data.data)
			}
			//}
		})
		.catch((error) => {
			errorCallback(error)
		})
}

_rest.stopCharging = function (chargerId, chargeId, callback) {
	_axios.get('v1/charger/stop-charging', {
		params: {
			'charger-id': chargerId,
			'charge-id': chargeId
		}
	})
		.then((response) => {
			if(typeof callback === 'function') {
				callback(response.data)
			}
		})
		.catch((error) => {
		})
}

_rest.cancelChargingBeforeStart = function (chargeId, callback) {
	_axios.get('v1/charger/cancel-charge-before-start', {
		params: {
			'charge-id': chargeId
		}
	})
		.then((response) => {
			if(typeof callback === 'function') {
				callback(response.data)
			}
		})
		.catch((error) => {
		})
}

_rest.latestPoints = function (chargeId, callback) {
	_axios.get(`v1/coupon/latest-points/${chargeId}`)
		.then((response) => {
			if(typeof callback === 'function') {
				callback(response.data)
			}
		})
		.catch((error) => {
		})
}

_rest.startPayment = function (rowNumber, callback, errorHandler) {
	_axios.get('/v1/simplepay/start/' + rowNumber, {})
		.then(response => {
			callback(response.data.url)
		})
		.catch(error => {
			errorHandler(error.response.data)
		})
}

_rest.loadReceipt = function (rowNumber, callback, errorHandler) {
	_axios.get('/v1/receipt/' + rowNumber, {})
		.then(response => {
			callback(response.data)
		})
		.catch(error => {
			errorHandler(error.response.data)
		})
}

_rest.getCurrentCharge = function (keepMessages, callback) {
	var loggedInUserId = localStorage.getItem('loggedInUserId')
	var loggedInEmail = localStorage.getItem('loggedInEmail')
	var loggedInUserToken = localStorage.getItem('loggedInUserToken')
	if(!((loggedInUserId || loggedInEmail) && loggedInUserToken)) {
		this.getLoggedInUser()
	}
	if((loggedInUserId || loggedInEmail) && loggedInUserToken) {
		_axios.get('v1/charge/current', { 'keepMessages': keepMessages })
			.then(response => {
				callback(response.data)
			})
			.catch(error => {
				callback(null)
			})
	}
	else {
		callback(null)
	}
}

_rest.getPriceSettings = async function (chargerId, callback) {
	await _axios.get('/v1/user/price-settings/' + chargerId, {})
		.then(response => {
			callback(response.data)
		})
		.catch(error => {
		})
}

_rest.skipCarAddition = function (callback) {
	_axios.get('/v1/user/skip-car-addition', {})
		.then(response => {
			callback(response.data)
		})
		.catch(error => {
		})
}

_rest.skipCreditCardAddition = function (callback) {
	_axios.get('/v1/user/skip-card-addition', {})
		.then(response => {
			callback(response.data)
		})
		.catch(error => {
		})
}

_rest.getCharger = function (chargerId, callback, errorCallback) {
	_axios.get('/v1/charger/charger', {
		params: {
			'charger-id': chargerId
		}
	})
		.then(response => {
			if(response.data) {
				callback(response.data)
			}
			else {
				errorCallback(null)
			}
		})
		.catch(error => {
			if(errorCallback) {
				errorCallback(error)
			}
		})
}

_rest.getRandomAd = function (callback, errorCallback) {
	_axios.get('/v1/footer/random', { 'keepMessages': true })
		.then(response => {
			if(response.data) {
				callback(response.data)
			}
			else {
				errorCallback(null)
			}
		})
		.catch(error => {
			if(errorCallback) {
				errorCallback(error)
			}
		})
}

_rest.loginWithApple = (code, user, callback) => {
	const formData = new URLSearchParams()
	formData.append("code", code)
	formData.append("user", user)
	_axios.post('/v1/user/apple-return', formData)
		.then(response => {
			if(response.data.result) {
				if(typeof callback === 'function') {
					callback(response.data.data, response.data.extraData)
				}
			}
		})
}

_rest.getApprovedVehicles = (callback) => {
	_axios.get('/v1/vehicle/approved-vehicles')
		.then(response => {
			if(response.data.result) {
				callback(response.data.data)
			}
		})
		.catch(error => {})
}

_rest.keepCustomVehicle = (vehicleId) => {
	_axios.get('/v1/vehicle/keep-custom-vehicle', {
		params: {
			'vehicle-id': vehicleId
		}
	})
}

_rest.switchVehicleFromList = (vehicleId, callback) => {
	//console.log('id', vehicleId)
	_axios.get('/v1/vehicle/switch-to-vehicle-from-list', {
		params: {
			'vehicle-id': vehicleId
		}
	}).then(response => {
		if(response.data.result) {
			if(typeof callback === 'function') {
				callback(response.data)
			}
		}
	}).catch(error => {})
}

_rest.generateSecurityCode = async function (code) {
	var response = await _axios.post('security/generate', code)
	return response.data
}




_rest.getLocationParkingLots = async (locationId) => {
	const res = await _axios.get(`/v1/parking/location/${locationId}`)
		.catch(error => null)

	return (res && res.data) ? res.data : null
}

_rest.getParkingLot = async (parkingLotId) => {
	const res = await _axios.get(`/v1/parking/parking-lot/${parkingLotId}`)
		.catch(error => null)

	return (res && res.data) ? res.data : null
}


_rest.startParking = async (parkingLotId) => {
	const res = await _axios.post(`/v1/parking/${parkingLotId}/start`, {}, {
		params: {
			'fcmToken': localStorage.getItem('fcmToken'),
			'platform': window.platform
		}
	})
		.catch(error => null)

	return res && res.data.data
}

_rest.startCasualParking = async (parkingLotId, email) => {
	const res = await _axios.post(`/v1/parking/${parkingLotId}/start-casual`, {}, {
		params: {
			'email': email,
			'fcmToken': localStorage.getItem('fcmToken'),
			'platform': window.platform
		}
	})
		.catch(error => null)

	return res && res.data
}


_rest.stopParking = async (
	billingDataId, paymentMethod, cardId, email
) => {
	const res = await _axios.post(`/v1/parking/stop`, {}, {
		params: {
			"email": email,
			'billingDataId': billingDataId,
			'paymentMethod': paymentMethod,
			'card-id': cardId,
			'lang': navigator.language,
			'java': navigator.javaEnabled(),
			'color': screen.colorDepth,
			'height': screen.height,
			'width': screen.width,
			'tz': new Date().getTimezoneOffset(),
		}
	}
	)
		.catch(error => null)
	return res && res.data.data
}

_rest.loadParkingHistory = async (fromDate) => {
	const res = await _axios.get(`/v1/parking/history`, {
		params: {
			filterFrom: fromDate
		}
	})
		.catch(error => null)
	return res && res.data
}

_rest.loadLatestParking = async () => {
	const res = await _axios.get(`/v1/parking/last-finished`)
		.catch(error => null)
	return res && res.data
}





_rest.startSurcharge = async (code, email) => {
	const fcmToken = localStorage.getItem('fcmToken')
	const platform = window.platform
	const res = await _axios.post(`/v1/parking-surcharge/start`,{}, {
		params:{
			code: code,
			email: email,
			fcmToken: fcmToken,
			platform: platform
		}
	})
		.catch(error => null)
	return res && res.data
}

_rest.startCasualSurcharge = async (code, email) => {
	const fcmToken = localStorage.getItem('fcmToken')
	const platform = window.platform
	const res = await _axios.post(`/v1/parking-surcharge/start-casual`, {}, {
		params:{
			code: code,
			email: email,
			fcmToken: fcmToken,
			platform: platform
		}
	})
		.catch(error => null)

	return res && res.data
}

_rest.paySurcharge = async (surchargeId, paymentMethod, cardId) => {
	const res = await _axios.post(`/v1/parking-surcharge/${surchargeId}/pay`, {}, {
		params:{
			'paymentMethod': paymentMethod,
			'card-id': cardId,
			'lang': navigator.language,
			'java': navigator.javaEnabled(),
			'color': screen.colorDepth,
			'height': screen.height,
			'width': screen.width,
			'tz': new Date().getTimezoneOffset(),
		}
	})
		.catch(error => null)

	return res && res.data.data
}




Vue.use(Plugin)
Vue.use(_rest)

export default Plugin
