<template>
	<div class="aboutus-container shadow-sm d-flex flex-column flex-grow-1">
		<div class="mx-auto text-center mb-5" style="max-width: 480px">
			<img alt="FreeCharger" src="@/assets/logo.svg" class="img-fluid" />
		</div>
		<div class="text-center mx-auto" style="max-width: 480px">
			<router-link :to="$i18n.locale == 'hu' ? 'privacy-statement-hu' : 'privacy-statement'" tag="button" class="btn-dark w-100 btn-main">
				{{ $t('aboutUs.privacyStatement') }}
			</router-link>

			<router-link :to="$i18n.locale == 'hu' ? 'gtc-hu' : 'gtc'" tag="button" class="btn-dark w-100 btn-main">
				{{ $t('aboutUs.termsAndConditions') }}
			</router-link>

			<!--router-link
        to="open-source"
        tag="button"
        class="btn-dark w-100 btn-main">
        {{ $t('aboutUs.openSourceSoftware') }}
      </router-link-->
		</div>

		<div class="text-center pt-2 px-2">{{ $t('aboutUs.allRightsReserved') }}</div>
	</div>
</template>

<script>
export default {
	name: 'AboutUsView',
	methods: {},
	mounted() {}
};
</script>

<style scoped>
.aboutus-container {
	min-width: 80%;
	max-width: 1000px;
	min-height: 500px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin: 0 auto;
	padding: 40px;
	background-color: white;
	border-radius: 5px;
	//box-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
}

.btn-dark.btn-main {
	word-break: keep-all;
	text-align: center;
	padding: 1rem 1rem;
	background-color: #3370a3;
	border: none;
	border-radius: 1rem;
	box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.5);
	margin-bottom: 1rem;
}
</style>
