<template>
	<div>
		<a href="javascript:history.back()" class="btn btn-outline-secondary mr-2 mb-2" v-if="showBackLink">
			<font-awesome-icon icon="chevron-left"/>
			{{ $t('wizard.prev') }}
		</a>

		<h1 v-if="!modal">
			Data Processing Policy
		</h1>
		<div style="text-align: center;">Effective: from 1 March 2021</div>

		<h2>Content</h2>
		<ol>
			<li><a href="javascript:void(0)" @click="scrollTo('p1')">Data Controller's details</a></li>
			<li><a href="javascript:void(0)" @click="scrollTo('p2')">Purpose of the information</a></li>
			<li><a href="javascript:void(0)" @click="scrollTo('p3')">General purpose of data processing</a></li>
			<li>
				<a href="javascript:void(0)" @click="scrollTo('p4')">Data processed</a>
				<ol>
					<li><a href="javascript:void(0)" @click="scrollTo('p41')">Downloading and using the application</a></li>
					<li><a href="javascript:void(0)" @click="scrollTo('p42')">Invoicing</a></li>
				</ol>
			</li>
			<li><a href="javascript:void(0)" @click="scrollTo('p5')">Security of data</a></li>
			<li><a href="javascript:void(0)" @click="scrollTo('p6')">Transmission, transfer of data, use of data processors</a></li>
			<li><a href="javascript:void(0)" @click="scrollTo('p7')">Rights of data subjects</a></li>
		</ol>

		<div ref="p1"></div>
		<h2>1. Data Controller's details</h2>
		<table>
			<tbody>
				<tr><th>Name of Data Controller:</th><td style="font-weight: bold;">Ready 2 Grow Kereskedelmi és Szolgáltató Korlátolt Felelősségű Társaság</td></tr>
				<tr><th>Registered office:</th><td style="font-weight: bold;">2100 Gödöllő, Remsey krt. 8.</td></tr>
				<tr><th>Phone:</th><td style="font-weight: bold;">+36 30 371 5660</td></tr>
				<tr><th>Email:</th><td style="font-weight: bold;">info@r2g.hu</td></tr>
				<tr><th>Website:</th><td style="font-weight: bold;">www.r2g.hu</td></tr>
				<tr><th>Company registration number:</th><td style="font-weight: bold;">13-09-177610</td></tr>
				<tr><th>Representative:</th><td style="font-weight: bold;">Marianna Maka</td></tr>
			</tbody>
		</table>

		<div ref="p2"></div>
		<h2>2. Purpose of the information</h2>
		<p>The purpose of this data processing policy is to provide data subjects with information in a clear and comprehensible way about the processing of their personal data.</p>
		<p>The processing of personal data is not one of our core activities, but in carrying it out we pay particular attention to the relevant EU and national legislation, in particular Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of natural persons with regard to the processing of personal data and on the free movement of such data, and repealing Directive 95/46/EC (General Data Processing Regulation - GDPR) and the provisions of the domestic Act CXII of 2011 on the Right to Informational Self-Determination and on Freedom of Information.</p>
		<p>As regards our professional activities, our company is engaged in the construction of the infrastructure necessary for the charging of electric vehicles, in particular the installation of charging equipment and the development of software and applications for their management and control.</p>

		<div ref="p3"></div>
		<h2>3. General purpose of data processing</h2>
		<p>Provision of services, development of software applications and their use by customers, and issuing of accounting documents.</p>

		<div ref="p4"></div>
		<h2>4. Data processed</h2>

		<div ref="p41"></div>
		<h3>4.1 Downloading and using the <a href="https://freecharger.hu" target="_blank">https://freecharger.hu</a> website and FreeCharger application</h3>
		<p><b>Data subjects:</b> all natural persons who download and use the application.</p>
		<p><b>Purpose:</b> of data processing.</p>

		<table>
			<thead>
				<tr>
					<th>Activity</th>
					<th>Data type</th>
					<th>Legal basis</th>
					<th>Purpose</th>
					<th>Retention period</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td rowspan="4">Provision of the application</td>
					<td>name</td>
					<td rowspan="4">Performance of a contract</td>
					<td rowspan="4">Provision of service</td>
					<td rowspan="4">5 years from the termination of the legal relationship.</td>
				</tr>
				<tr>
					<td>telephone number</td>
				</tr>
				<tr>
					<td>e-mail address</td>
				</tr>
				<tr>
					<td>vehicle registration number</td>
				</tr>
			</tbody>
		</table>

		<p style="font-weight: bold;">Process of data processing:</p>
		<p>The data provided by the customer when downloading the application will be used and processed for the duration of the contractual relationship, solely for the purpose of providing the service and contacting.</p>
		<p>The provision of the data is an indispensable condition for the conclusion of the contract and the provision of the service, in order to identify the customer and to contact.</p>

		<p style="font-weight: bold;">Transfer, transmission of data, use of a data processor</p>
		<p>The Service Provider shall pay the consideration for the service within the framework of the SimplePay service provided by OTP Mobil Kft.</p>
		<p>By using the service, the customer acknowledges that the following personal data stored by Ready 2 Grow Kereskedelmi és Szolgáltató Korlátolt Felelősségű Társaság as data controller in the user database of the FreeCharger mobile application and the <a href="https://freecharger.hu" target="_blank">https://freecharger.hu</a> website will be transferred to OTP Mobil Kft. as data processor. The scope of the data transferred by the data controller is as follows:</p>
		<ul>
			<li>invoicing name</li>
			<li>email address</li>
			<li>address</li>
			<li>telephone number</li>
		</ul>
		<p>The nature and purpose of the data processing activities carried out by the processor can be found in the <a href="https://simplepay.hu/adatkezelesi-tajekoztatok/" target="_blank">SimplePay Data Processing Policy</a>.</p>

		<p style="font-weight: bold;">SimplePay card registration</p>
		<p>Recurring credit card payment (hereinafter referred to as "Recurring Payment") is a credit card acceptance function provided by SimplePay, which means that the credit card details provided by the Customer during the registration transaction can be used to initiate future payments without having to re-enter the credit card details.</p>
		<p>The recurring payment type is called "occasional consent" and is subject to occasional approval by the Customer for each transaction, i.e., you must approve the transaction for all future payments.</p>
		<p>You will always be notified of a successful payment through the same channels as a traditional credit card payment.</p>
		<p>To use Recurring Payments, by accepting this statement, you agree that following a successful registration transaction, subsequent payments made from your account in the FreeCharger Application and on <a href="https://freecharger.hu" target="_blank">https://freecharger.hu</a> will be processed without re-entering your credit card details.</p>
		<p style="font-weight: bold;">Please note(!): credit card details are processed in accordance with the card company's rules.</p>
		<p>The Merchant and SimplePay have no access to the credit card data. The Merchant is directly liable for recurring payment transactions initiated by the Merchant in error or illegally, and any claims against the payment service provider of the Merchant (SimplePay) are excluded.</p>
		<p style="font-weight: bold;">By using the service, the customer declares that he has read, acknowledged and accepted the contents of the data processing policy.</p>

		<div ref="p42"></div>
		<h3>4.2 Invoicing</h3>
		<p><b>Data subjects:</b> all users who use the service.</p>
		<p><b>Purpose of data processing:</b> issuing a receipt in accordance with the Accounting Act.</p>

		<table>
			<thead>
				<tr>
					<th>Activity</th>
					<th>Type of data</th>
					<th>Legal basis</th>
					<th>Purpose</th>
					<th>Retention period</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td rowspan="2">Invoicing</td>
					<td>name</td>
					<td rowspan="2">fulfilment of a legal obligation</td>
					<td rowspan="2">to issue a receipt in accordance with the Accounting Act</td>
					<td rowspan="2">Until the expiry of the retention obligation under the accounting rules in force at the time.</td>
				</tr>
				<tr>
					<td>invoicing address</td>
				</tr>
			</tbody>
		</table>

		<p style="font-weight: bold;">Process of data processing:</p>
		<p>The name and address of the user customers and, if requested by the user and provided by the user, invoicing information of a non-natural person will be used to issue the accounting document. These documents or data will be kept until the expiry of the retention period under the accounting rules in force at the time.</p>
		<p>The provision of data is mandatory under the applicable legislation, failing which the service will not be provided.</p>

		<div ref="p5"></div>
		<h2>5. Security of data</h2>

		<p>We ensure the security of the personal data we process by implementing technical and organizational measures and procedures.</p>
		<p>We take appropriate measures to protect the data against unauthorized access, alteration, disclosure, disclosure, deletion or destruction, accidental destruction or damage, and against inaccessibility resulting from changes in the technology used.</p>
		<p>Access to personal data is restricted to those employees who need to know it in order to perform their duties.</p>
		<p>To ensure the security of your data:</p>
		<ul>
			<li>we assess and take into account potential risks in the design and operation of the IT system, and seek to mitigate them continuously</li>
			<li>monitor emerging threats and vulnerabilities (such as computer viruses, computer intrusions, denial of service attacks, etc.) to take timely action to avoid and prevent them</li>
			<li>protect IT assets and information on paper against unauthorized physical access and environmental impacts (e.g. water, fire, electrical surges)</li>
			<li>monitor our IT systems to detect potential problems and incidents</li>
			<li>we take great care to train staff in information security and raise awareness</li>
			<li>reliability is a key criterion in the choice of service providers</li>
		</ul>

		<div ref="p6"></div>
		<h2>6. Transmission, transfer of data, use of data processors</h2>
		<p>Data may be transferred in the event of a request from a public authority (e.g. Tax Authority audit).</p>
		<p>For our invoice management activities, our company uses a data processor (KBOSS.hu Kft., 1031 Budapest, Záhony utca 7/C., Company Registration No.: 01-09-303201), as the invoice for the consideration for the provision of services is issued by means of an online invoicing program (<a href="https://www.szamlazz.hu" target="_blank">https://www.szamlazz.hu</a>) operated by a third party. The data processing agreement is part of our contract with it, which ensures that it will process the data with the same care, in compliance with the applicable legislation and only in accordance with our instructions.</p>

		<div ref="p7"></div>
		<h2>7. Rights of data subjects</h2>
		<p>All data subjects have the right to:</p>
		<ul>
			<li>To be informed of the data processing concerning him or her before they start</li>
			<li>Have access to all information about data processing concerning him or her</li>
			<li>Request the correction of incorrect, inaccurate or incomplete data</li>
			<li>Request the deletion (inactivation) of his/her personal data</li>
			<li>Request restriction of data processing</li>
			<li>Object to the use of his/her data in certain cases, for example for marketing purposes</li>
			<li>To exercise remedy against processing of personal data</li>
		</ul>

		<p>These rights can be exercised by the data subject in writing using the contact details provided below, or in person by prior arrangement. We will endeavour to respond to all requests as soon as possible, but not later than 15 working days.</p>
		<p>Contact details for exercising your rights:</p>
		<ul>
			<li>By post: Ready 2 Grow Kereskedelmi és Szolgáltató Korlátolt Felelősségű Társaság, 2100 Gödöllő, Remsey krt. 8.</li>
			<li>By e-mail: <a href="mailto:info@r2g.hu">info@r2g.hu</a></li>
			<li>In person: +36 30 371 5660 by telephone as agreed.</li>
		</ul>

		<p>We are unable to give out personal information via a phone call as we cannot identify the caller.</p>
		<p>In the event of a breach of their rights, data subjects may refer the matter to the National Authority for Data Protection and Freedom of Information:</p>
		<ul>
			<li>Address: 1125 Budapest, Szilágyi Erzsébet fasor 22/c</li>
			<li>Phone: +36 (1) 391-1400</li>
			<li>Fax: +36 (1) 391-1410</li>
			<li>Website: <a href="http://www.naih.hu" target="_blank">http://www.naih.hu</a></li>
			<li>E-mail: <a href="mailto:ugyfelszolgalat@naih.hu">ugyfelszolgalat@naih.hu</a></li>
		</ul>
	</div>
</template>

<script>
export default {
	name: "PrivacyStatementENView",
	data() {
		return {
			showBackLink: history.length > 1 && !this.modal
		}
	},
	props: {
		modal: Boolean
	},
	methods: {
		scrollTo(refName) {
			var element = this.$refs[refName];
			var top = element.offsetTop;
			window.scrollTo(0, top);
		}
	}
}
</script>

<style scoped>
h1 {
	text-transform: uppercase;
	text-align: center;
}

h2 {
	margin-top: 2rem;
}

h3 {
	margin-top: 1rem;
}

table {
	border-top: 1px solid #000000;
	border-left: 1px solid #000000;
	border-spacing: 0;
	width: 100%;
	margin-bottom: 1rem;
}

td, th {
	border-bottom: 1px solid #000000;
	border-right: 1px solid #000000;
	padding: 5px;
	text-align: left;
}

tbody th {
	font-weight: normal;
}

ol {
	counter-reset: item;
}

ol > li {
	counter-increment: item;
}

ol ol > li {
	display: block;
}

ol ol > li:before {
	content: counters(item, ".") ". ";
	margin-left: -20px;
}
</style>
