<template>
	<div :style="{ 'max-width': '900px', margin: '0 auto' }" class="shadow-container px-0">
		<div v-if="charger">
			<div class="alert alert-info align-items-center" v-if="loggedInUserData && loggedInUserData.casualUser">
				<font-awesome-icon icon="circle-notch" class="fa-spin fa-lg fa-fw mr-2" />
				{{ $t('charger-view.casual-charging-info') }}
			</div>
			<template v-else>
				<div class="media align-items-center px-3">
					<div class="location-panel-charger-name d-flex flex-column justify-content-center align-items-center mr-2" v-if="charger.shortName">
						{{ charger.shortName }}
					</div>
					<div class="flex-1 media-body-container">
						<div class="media-body w-full mr-auto">
							<div class="font-weight-bold">{{ charger.name }}</div>

							<div v-if="connectorStateKey === 'NEW_NOT_CONNECTED'" class="connector-state-unavailable" style="font-size: 0.9rem">
								{{ $t('location-panel.charger-state-new') }}
							</div>
							<div v-else-if="connectorStateKey === 'UNKNOWN'" :class="['connector-state', connectorStateClass]" style="font-size: 0.9rem">
								{{ $t('location-panel.communicationError') }}
							</div>
							<div v-else-if="connectorState" :class="['connector-state', connectorStateClass]" style="font-size: 0.9rem">
								{{ connectorState }}
							</div>

						<div class="d-flex">
							<div style="font-size: 0.8rem;">{{ chargerPerformance }} kW {{ currentType }}</div>

								<div v-for="(outlet, index) in outlets" :key="index" class="outlet-item ml-3">
									<img v-if="outlet === 'Type 2'" class="charger-head-location" style="vertical-align: top" src="../assets/connector/TYPE2_ico.png" />
									<img v-if="outlet === 'CHAdeMO'" class="charger-head-location" style="vertical-align: top" src="../assets/connector/CHADEMO_ico.png" />
									<img v-if="outlet === 'CCS'" class="charger-head-location" style="vertical-align: top" src="../assets/connector/CCS_ico.png" />
									<span style="font-size: 0.8rem; vertical-align: top">{{ outlet }}</span>
								</div>
							</div>
						</div>
						<div>
							<small v-if="charger.location" class="text-center d-block">{{ charger.location.address.full }}</small>

							<div v-if="priceSettings" class="text-center">
								<small v-if="priceSettings.freeCharging" class="text-success">Ingyenes</small>
								<small v-else class="text-danger">{{ $t('location-panel.price', [priceSettings.unitChargingGross]) }}</small>
							</div>
						</div>
					</div>
				</div>
				<div class="centered my-3">
					<button class="charger-info-toggle-button-2" :style="{ margin: '0 auto' }" @click="toggleInfo(charger.id)">
						<font-awesome-icon icon="chevron-down" class="mr-1 animated-flip" :class="{ 'do-flip': showInfo }" />
						<template v-if="showInfo">
							<small>{{ $t('location-panel.hide-details') }}</small>
						</template>
						<template v-else>
							<small>{{ $t('location-panel.more-details') }}</small>
						</template>
					</button>
				</div>

				<div id="chargerDetails" class="collapse p-2 mb-3">
					<div class="p-3 pt-2 border rounded-lg">
						<div v-if="this.tariffLines.length > 0" class="mt-2">{{ $t('location-panel.charger-tariff') }}:</div>
						<ul class="mb-0">
							<li v-for="tl in this.tariffLines" :key="tl">{{ tl }}</li>
						</ul>
					</div>

					<div class="row">
						<div class="col-md-6 col-xl-4" v-for="tv in traitDetails" :key="tv.trait.id">
							<hr />
							{{ tv.trait.name }}:
							<TraitValue :tv="tv" />
						</div>
					</div>
				</div>

				<div class="alert alert-warning" v-if="showChargeInProgressError">
					<font-awesome-icon icon="exclamation-triangle" size="2x" class="mr-2" />
					{{ $t('charger-view.start-not-possible') }}
				</div>

				<div class="alert alert-warning" v-if="!priceSettings">
					<font-awesome-icon icon="exclamation-triangle" size="2x" class="mr-2" />
					{{ $t('location-panel.error-no-tariff') }}
				</div>

				<div
					class="warning-container"
					v-if="priceSettings && canStart && connectorStateKey === 'FINISHING' && charger.deviceType && charger.deviceType.canStartNewChargeWhileInFinishingState"
				>
					<font-awesome-icon icon="exclamation-triangle" size="2x" class="mr-2" />
					{{ $t('charger-view.chargeInFinishingMessage') }}
				</div>

				<a name="survey"></a>

				<CasualChargingSurvey v-if="(priceSettings && canStart && !loggedInUserData) || loggedInUserData.casualCharge" :msgs="msgs" :priceSettings="priceSettings" :charger="charger" />
				<template v-else-if="priceSettings && canStart && loggedInUserData">
					<div class="step-container cont-bg border mx-2">
						<div class="form-group media">
							<span class="step-number">1</span>
							<div class="media-body align-self-center">{{ $t('charger-view.choose-billing-info') }}</div>
						</div>

						<div class="form-group">
							<select class="form-control" v-model="selectedBillingData" v-if="billingDatas && billingDatas.length > 0">
								<option v-for="billingData in billingDatas" :key="billingData.id" :value="billingData">{{ billingData.payerName }}, {{ billingData.address.full }}</option>
							</select>

							<div class="warning-container" v-if="!billingDatas || billingDatas.length === 0">
								<div>
									<i class="pi pi-exclamation-triangle mr-2" :style="'font-size: 1.7rem'"></i>
									<span style="font-size: 1.1rem">{{ $t('charger-view.no-billing-inf') }}</span>
								</div>
								<router-link to="/billing">
									{{ $t('charger-view.please-add-billing-info') }}
								</router-link>
							</div>
						</div>
					</div>
					<template v-if="selectedBillingData">
						<div class="warning-container mt-1 m-2" v-if="(!cars || cars.length == 0) && $store.getters.getSettings.wizardCarsOptional">
							<!-- <font-awesome-icon icon="exclamation-triangle" size="2x" class="mr-2" /> -->
							<i class="pi pi-exclamation-triangle mr-2" :style="'font-size: 1.7rem'"></i>
							<span class="mb-1 font-weight-bold">{{$t('charger-view.no-car-added')}}</span>
							<div class="mt-1">
								{{$t('charger-view.not-a-problem')}}<br />
                {{$t('charger-view.add-a-car')}}
              </div>
						</div>
						<template v-if="(cars && cars.length > 0) || !$store.getters.getSettings.wizardCarsOptional">
							<div class="step-container cont-bg border mx-2">
								<div class="form-group media">
									<span class="step-number">2</span>
									<div class="media-body align-self-center">{{ $t('charger-view.choose-car') }}</div>
								</div>

								<div class="form-group">
									<select class="form-control" v-model="selectedCar" v-if="cars && cars.length > 0">
										<option v-for="car in cars" :key="car.id" :value="car">{{ car.producer }} {{ car.model }} - {{ car.licensePlate }}</option>
									</select>
									<div class="alert alert-warning px-3 py-2" style="text-align: center" v-else>
										<div>
											<font-awesome-icon icon="exclamation-triangle" size="2x" class="mr-2" />
											<span style="font-size: 1.1rem">{{ $t('charger-view.no-car') }}</span>
										</div>
										{{ $t('charger-view.no-car-description') }}<br />
										<router-link to="/cars">
											{{ $t('charger-view.add-car') }}
										</router-link>
									</div>
								</div>
							</div>
						</template>

						<template v-if="selectedCar || $store.getters.getSettings.wizardCarsOptional">
							<a name="step3"></a>

							<div class="step-container cont-bg border mx-2">
								<div class="form-group media" v-if="!priceSettings.freeCharging && selectedCar">
									<span class="step-number">3</span>
									<div class="media-body align-self-center">{{ $t('charger-view.reserve-mode') }}</div>
								</div>

								<div class="form-group justify-content-center text-center row" v-if="!priceSettings.freeCharging">
									<template v-if="selectedCar">
										<div class="px-3 py-2" :class="{ 'col-md-6': !priceSettings.freeCharging }">
											<a href="javascript:void(0)" class="charger-limit-type border-none" :class="{ active: showTimePicker }" @click="onShowTimePicker">
												<span class="centered align-items-center">
													<i class="pi pi-stopwatch fs-4" style="font-size: 1.8rem"></i>
													<span class="media-body">{{ $t('charger-view.max-charge-time') }}</span>
												</span>
											</a>
										</div>

										<div class="col-md-6 px-3 py-2" v-if="!priceSettings.freeCharging">
											<a href="javascript:void(0)" class="charger-limit-type" :class="{ active: showAmountPicker && !fullBalance }" @click="onShowAmountPicker">
												<span class="media align-items-center">
													<i class="pi pi-credit-card" style="font-size: 1.8rem"></i>
													<span class="media-body">{{ $t('charger-view.max-charge-cost') }}</span>
												</span>
											</a>
										</div>

										<div class="col-md-6 px-3 py-2" v-if="walletBalance && walletBalance > 0">
											<a href="javascript:void(0)" class="charger-limit-type" :class="{ active: showAmountPicker && fullBalance }" @click="onFullBalance">
												<span class="media align-items-center">
													<!-- <font-awesome-icon :icon="['far', 'credit-card']" size="2x" class="mr-2" /> -->
													<i class="pi pi-wallet" style="font-size: 1.8rem"></i>
													<span class="media-body">{{ $t('charger-view.max-money') }}</span>
												</span>
											</a>
										</div>

										<!--div class="col-md-6 px-3 py-2" v-if="!priceSettings.freeCharging">
										<a href="javascript:void(0)" class="charger-limit-type" :class="{'active': pickedChargeMaxKW}" @click="onShowChargeMaxKWPicker">
											<span class="media align-items-center">
												<font-awesome-icon icon="bolt" size="2x" class="mr-2"/>
												<span class="media-body">{{ $t('charger-view.enter-enegery') }}</span>
											</span>
										</a>
									</div>

									<div class="col-md-6 px-3 py-2" v-if="!priceSettings.freeCharging">
										<a href="javascript:void(0)" class="charger-limit-type" :class="{'active': pickedChargeFinishTime}" @click="onShowChargeFinishTimePicker">
											<span class="media align-items-center">
												<font-awesome-icon :icon="['far', 'clock']" size="2x" class="mr-2"/>
												<span class="media-body">{{ $t('charger-view.enter-end-time') }}</span>
											</span>
										</a>
									</div-->
									</template>
								</div>
							</div>

							<div class="alert alert-warning px-3 py-2" style="text-align: center" v-if="showHintForCarAddition && loggedInUserData">
								<div>
									<font-awesome-icon icon="exclamation-triangle" size="2x" class="mr-2" />
									<span style="font-size: 1.1rem">{{ $t('charger-view.need-mode-info-about-car') }}</span>
								</div>
								{{ $t('charger-view.need-more-info-about-car-description') }}<br />
								<router-link to="/cars">
									{{ $t('charger-view.add-charge-speed') }}
								</router-link>
							</div>

							<template v-if="showTimePicker || priceSettings.freeCharging">
								<a name="step4"></a>

								<div class="form-group" v-if="loggedInUserData">
									<div class="media align-items-center mb-2">
										<span class="step-number">{{ step4 }}</span>
										<div class="media-body">
											{{ $t('charger-view.charge-time') }}
										</div>
									</div>

									<div class="blockquote-footer step-list">
										{{ $t('charger-view.charge-disclaimer') }}
									</div>
									<div class="blockquote-footer step-list" v-if="!priceSettings.freeCharging">
										{{ $t('charger-view.charge-disclaimer-2') }}
									</div>
									<div class="blockquote-footer step-list" v-if="!priceSettings.freeCharging">
										{{ $t('charger-view.charge-disclaimer-3') }}
									</div>
									<div class="blockquote-footer step-list">
										{{ $t('charger-view.charge-disclaimer-4') }}
									</div>
									<div class="blockquote-footer step-list" v-if="!priceSettings.freeCharging && priceSettings.convenienceFeeGross > 0">
										<b>{{ $t('charger-view.estemated', [priceSettings.convenienceFeeGross]) }}</b>
									</div>
									<div class="blockquote-footer step-list" v-if="!priceSettings.freeCharging">
										<b>{{ $t('charger-view.calculation-unit-price', [priceSettings.unitChargingGross]) }}</b>
									</div>
								</div>

								<div class="row mb-5 px-3" v-if="loggedInUserData">
									<div class="col-lg-3 p-0">
										<div class="time-bubble-row">
											<a href="javascript:void(0)" class="time-bubble" :class="{ active: pickedAmount && pickedAmount.time == 15 }" @click="onTimePicked(15)">
												<span class="time-bubble-inner">
													<span class="time-bubble-value">15</span>
													<span class="time-bubble-label">{{ $tc('chargerView.minutes', 15) }}</span>
													<span class="time-bubble-performance">{{ calculateKwFromTime(15, 1) }} kWh</span>
												</span>
											</a>
											<a href="javascript:void(0)" class="time-bubble" :class="{ active: pickedAmount && pickedAmount.time == 30 }" @click="onTimePicked(30)">
												<span class="time-bubble-inner">
													<span class="time-bubble-value">30</span>
													<span class="time-bubble-label">{{ $tc('chargerView.minutes', 30) }}</span>
													<span class="time-bubble-performance">{{ calculateKwFromTime(30, 1) }} kWh</span>
												</span>
											</a>
											<a href="javascript:void(0)" class="time-bubble" :class="{ active: pickedAmount && pickedAmount.time == 45 }" @click="onTimePicked(45)">
												<span class="time-bubble-inner">
													<span class="time-bubble-value">45</span>
													<span class="time-bubble-label">{{ $tc('chargerView.minutes', 45) }}</span>
													<span class="time-bubble-performance">{{ calculateKwFromTime(45, 1) }} kWh</span>
												</span>
											</a>
											<a href="javascript:void(0)" class="time-bubble" :class="{ active: pickedAmount && pickedAmount.time == 60 }" @click="onTimePicked(60)">
												<span class="time-bubble-inner">
													<span class="time-bubble-value">1</span>
													<span class="time-bubble-label">{{ $tc('chargerView.hour', 1) }}</span>
													<span class="time-bubble-performance">{{ calculateKwFromTime(60, 0) }} kWh</span>
												</span>
											</a>
										</div>
									</div>

									<div class="col-lg-3 p-0">
										<div class="time-bubble-row">
											<a href="javascript:void(0)" class="time-bubble" :class="{ active: pickedAmount && pickedAmount.time == 120 }" @click="onTimePicked(120)">
												<span class="time-bubble-inner">
													<span class="time-bubble-value">2</span>
													<span class="time-bubble-label">{{ $tc('chargerView.hours', 2) }}</span>
													<span class="time-bubble-performance">{{ calculateKwFromTime(120, 0) }} kWh</span>
												</span>
											</a>
											<a href="javascript:void(0)" class="time-bubble" :class="{ active: pickedAmount && pickedAmount.time == 180 }" @click="onTimePicked(180)">
												<span class="time-bubble-inner">
													<span class="time-bubble-value">3</span>
													<span class="time-bubble-label">{{ $tc('chargerView.hours', 3) }}</span>
													<span class="time-bubble-performance">{{ calculateKwFromTime(180, 0) }} kWh</span>
												</span>
											</a>
											<a href="javascript:void(0)" class="time-bubble" :class="{ active: pickedAmount && pickedAmount.time == 240 }" @click="onTimePicked(240)">
												<span class="time-bubble-inner">
													<span class="time-bubble-value">4</span>
													<span class="time-bubble-label">{{ $tc('chargerView.hours', 4) }}</span>
													<span class="time-bubble-performance">{{ calculateKwFromTime(240, 0) }} kWh</span>
												</span>
											</a>
										</div>
									</div>

									<div class="col-lg-6 p-0">
										<div class="time-bubble-row">
											<a href="javascript:void(0)" class="time-bubble" :class="{ active: pickedAmount && pickedAmount.time == 360 }" @click="onTimePicked(360)">
												<span class="time-bubble-inner">
													<span class="time-bubble-value">6</span>
													<span class="time-bubble-label">{{ $tc('chargerView.hours', 6) }}</span>
													<span class="time-bubble-performance">{{ calculateKwFromTime(360, 0) }} kWh</span>
												</span>
											</a>
											<a href="javascript:void(0)" class="time-bubble" :class="{ active: pickedAmount && pickedAmount.time == 480 }" @click="onTimePicked(480)">
												<span class="time-bubble-inner">
													<span class="time-bubble-value">8</span>
													<span class="time-bubble-label">{{ $tc('chargerView.hours', 8) }}</span>
													<span class="time-bubble-performance">{{ calculateKwFromTime(480, 0) }} kWh</span>
												</span>
											</a>
											<a href="javascript:void(0)" class="time-bubble" :class="{ active: pickedAmount && pickedAmount.time == 600 }" @click="onTimePicked(600)">
												<span class="time-bubble-inner">
													<span class="time-bubble-value">10</span>
													<span class="time-bubble-label">{{ $tc('chargerView.hours', 10) }}</span>
													<span class="time-bubble-performance">{{ calculateKwFromTime(600, 0) }} kWh</span>
												</span>
											</a>
											<a href="javascript:void(0)" class="time-bubble" :class="{ active: pickedAmount && pickedAmount.time == 720 }" @click="onTimePicked(720)">
												<span class="time-bubble-inner">
													<span class="time-bubble-value">12</span>
													<span class="time-bubble-label">{{ $tc('chargerView.hours', 12) }}</span>
													<span class="time-bubble-performance">{{ calculateKwFromTime(720, 0) }} kWh</span>
												</span>
											</a>
										</div>
									</div>
								</div>
							</template>

							<template v-if="showAmountPicker && loggedInUserData">
								<a name="step4"></a>

								<div class="form-group px-2">
									<div class="media align-items-center mb-2">
										<span class="step-number">{{ step4 }}</span>
										<div class="media-body" style="max-width: 80%">{{ $t('charger-view.please-choose-amount') }}</div>
									</div>
									<ul class="list-unstyled">
										<li class="blockquote-footer step-list">
											{{ $t('charger-view.amount-disclaimer-1') }}
										</li>
										<li class="blockquote-footer step-list">
											{{ $t('charger-view.amount-disclaimer-2') }}
										</li>
										<li class="blockquote-footer step-list">
											{{ $t('charger-view.amount-disclaimer-3') }}
										</li>
										<li class="blockquote-footer step-list">
											{{ $t('charger-view.amount-disclaimer-4') }}
										</li>
										<li class="blockquote-footer step-list" v-if="priceSettings.convenienceFeeGross > 0">
											<b>{{ $t('charger-view.amount-disclaimer-5', [priceSettings.convenienceFeeGross]) }}</b>
										</li>
										<li class="blockquote-footer step-list">
											<b>{{ $t('charger-view.amount-disclaimer-6', [priceSettings.unitChargingGross]) }}</b>
										</li>
									</ul>
								</div>

								<div class="mt-2 px-0 charger-payment-stripe">
									<div>
										<a href="javascript:void(0)" class="charger-payment media align-items-center" :class="{ active: pickedAmount && pickedAmount.amount == 500 }" @click="onAmountPicked(500)">
											<i class="pi pi-credit-card" :style="'font-size: 2rem'"></i>
											<span class="media-body payment-card-media">
												<label>500 Ft</label>
												<span v-if="selectedCar">~{{ calculateTimeFromPrice(500) }} {{ $t('charger-view.minute-charge') }}</span>
												<span>~{{ calculateKwFromPrice(500) }} kWh</span>
											</span>
										</a>
									</div>
									<div>
										<a href="javascript:void(0)" class="charger-payment media align-items-center" :class="{ active: pickedAmount && pickedAmount.amount == 1000 }" @click="onAmountPicked(1000)">
											<i class="pi pi-credit-card" :style="'font-size: 2rem'"></i>
											<span class="media-body payment-card-media">
												<label>1 000 Ft</label>
												<span v-if="selectedCar">~{{ calculateTimeFromPrice(1000) }} {{ $t('charger-view.minute-charge') }}</span>
												<span>~{{ calculateKwFromPrice(1000) }} kWh</span>
											</span>
										</a>
									</div>
									<div>
										<a href="javascript:void(0)" class="charger-payment media align-items-center" :class="{ active: pickedAmount && pickedAmount.amount == 3000 }" @click="onAmountPicked(3000)">
											<i class="pi pi-credit-card" :style="'font-size: 2rem'"></i>
											<span class="media-body payment-card-media">
												<label>3 000 Ft</label>
												<span v-if="selectedCar">~{{ calculateTimeFromPrice(3000) }} {{ $t('charger-view.minute-charge') }}</span>
												<span>~{{ calculateKwFromPrice(3000) }} kWh</span>
											</span>
										</a>
									</div>
									<div>
										<a href="javascript:void(0)" class="charger-payment media align-items-center" :class="{ active: pickedAmount && pickedAmount.amount == 5000 }" @click="onAmountPicked(5000)">
											<i class="pi pi-credit-card" :style="'font-size: 2rem'"></i>
											<span class="media-body payment-card-media">
												<label>5 000 Ft</label>
												<span v-if="selectedCar">~{{ calculateTimeFromPrice(5000) }} {{ $t('charger-view.minute-charge') }}</span>
												<span>~{{ calculateKwFromPrice(5000) }} kWh</span>
											</span>
										</a>
									</div>
									<div>
										<a href="javascript:void(0)" class="charger-payment media align-items-center" :class="{ active: pickedAmount && pickedAmount.amount == 10000 }" @click="onAmountPicked(10000)">
											<i class="pi pi-credit-card" :style="'font-size: 2rem'"></i>
											<span class="media-body payment-card-media">
												<label>10 000 Ft</label>
												<span v-if="selectedCar">~{{ calculateTimeFromPrice(10000) }} {{ $t('charger-view.minute-charge') }}</span>
												<span>~{{ calculateKwFromPrice(10000) }} kWh</span>
											</span>
										</a>
									</div>
									<div v-if="walletBalance && walletBalance > 0">
										<a href="javascript:void(0)" class="charger-payment media align-items-center" :class="{ active: pickedAmount && pickedAmount.amount == walletBalance }" @click="onAmountPicked(walletBalance, true)">
											<i class="pi pi-credit-card" :style="'font-size: 2rem'"></i>
											<span class="media-body payment-card-media">
												<label>{{ walletBalance | formatCurrency }}</label>
												<span v-if="selectedCar">~{{ calculateTimeFromPrice(walletBalance) }} {{ $t('charger-view.minute-charge') }}</span>
												<span>~{{ calculateKwFromPrice(walletBalance) }} kWh</span>
											</span>
										</a>
									</div>
								</div>
							</template>

							<div class="px-2">
								<ChargeFinishTimeTemplate v-if="pickedChargeFinishTime" :msgs="msgs" :price-settings="priceSettings" @onTimePicked="onTimePicked" @clearTimePicker="onShowChargeFinishTimePicker" />
								<ChargeMaxKwTemplate v-if="pickedChargeMaxKW" :msgs="msgs" :priceSettings="priceSettings" @onKwPicked="onKwPicked" @clearKwInputField="onShowChargeMaxKWPicker" />
							</div>

							<template v-if="pickedAmount && loggedInUserData">
								<a name="step5"></a>

								<div class="form-group px-2">
									<div class="media align-items-center">
										<span class="step-number">{{ step5 }}</span>

										<div class="media-body">
											{{ $t('charger-view.start-charge') }}
										</div>
									</div>

									<ParkingAlertPanel :priceSettings="priceSettings" />

									<payment-summary-card v-if="!priceSettings.freeCharging" :data="paymentSummary" :price-settings="priceSettings" />
								</div>
								<div class="px-2">
									<ChargeStartArea :step="step6" @scrollToBottom="scrollToBottom" :creditCards="creditCards" :msgs="msgs" :priceSettings="priceSettings" :charger="charger" :selectedBillingData="selectedBillingData" :pickedAmount="pickedAmount" :selectedCar="selectedCar" :walletBalance="walletBalance" :priceToPayNative="priceToPayNative" />
								</div>
							</template>
						</template>
					</template>
				</template>
			</template>
		</div>
		<div v-else-if="showNoChargerInfo">
			<div class="alert alert-warning">
				<font-awesome-icon icon="exclamation-triangle" size="2x" class="mr-2" />
				{{ $t('charger-view.no-charger-found') }}
			</div>
		</div>
	</div>
</template>

<script>
import jQuery from 'jquery';
import LocationPanelCharger from '@/components/LocationPanelCharger.vue';
import PaymentSummaryCard from '@/components/PaymentSummaryCard.vue';
import * as utils from '@/plugins/utils.js';
import ChargeFinishTimeTemplate from '../components/ChargeFinishTimeTemplate';
import ChargeMaxKwTemplate from '../components/ChargeMaxKwTemplate';
import { mapGetters } from 'vuex';
import TraitValue from '@/components/TraitValue.vue';
import ChargeStartArea from '@/components/ChargeStartArea.vue';
import CasualChargingSurvey from '@/components/CasualChargingSurvey.vue';
import ParkingAlertPanel from '@/components/ParkingAlertPanel.vue';
import ParkingCodeInput from '@/components/ParkingCodeInput.vue';
import { formatCurrency } from '../assets/functions/currency-formatter';
import BasicDialog from '../components/BasicDialog.vue';

export default {
	name: 'ChargerView',
	data() {
		return {
			priceSettings: null,
			showChargeInProgressError: false,
			charger: null,
			chargerId: null,
			billingDatas: [],
			selectedBillingData: null,
			cars: [],
			creditCards: [],
			selectedCar: null,
			showTimePicker: false,
			showAmountPicker: false,
			fullBalance: false,
			pickedAmount: null,
			pickedChargeFinishTime: null,
			pickedChargeMaxKW: null,
			priceToPay: null,
			paymentSummary: null,
			showNoChargerInfo: false,
			showHintForCarAddition: false,
			currentType: 'AC',
			chargerPerformance: 22,
			outlets: [],
			traitDetails: [],
			showInfo: false,
			connectorState: '',
			walletBalance: null,
			connectorStateKey: '',
			payingWithCard: false,
			priceToPayNative: null
		};
	},
	props: {
		msgs: Object
	},
	asyncComputed: {
		async loggedInUserData() {
			return await this.$store.getters.getLoggedInUserData;
		}
	},
	computed: {
		step4() {
			var base = 4;

			if (!this.priceSettings.freeCharging) {
				base--;
			}

			if (this.cars.length == 0 && this.$store.getters.getSettings.wizardCarsOptional) {
				base--;
			}

			return base;
		},
		step5() {
			var base = 5;

			if (!this.priceSettings.freeCharging) {
				base--;
			}

			if (this.cars.length == 0 && this.$store.getters.getSettings.wizardCarsOptional) {
				base--;
			}

			return base;
		},
		step6() {
			var base = 6;

			if (!this.priceSettings.freeCharging) {
				base--;
			}

			if (this.cars.length == 0 && this.$store.getters.getSettings.wizardCarsOptional) {
				base--;
			}

			return base;
		},
		tariffLines() {
			let result = [];
			if (this.charger && this.charger.tariffs) {
				for (let t of this.charger.tariffs) {
					if (t.descriptionLines) {
						for (let dl of t.descriptionLines) {
							result.push(dl);
						}
					}
				}
			}
			return result;
		},
		connectorStateClass() {
			if (this.connectorStateKey == 'AVAILABLE' || this.connectorStateKey == 'PREPARING') {
				return 'connector-state-available';
			}
			if (this.connectorStateKey == 'OCCUPIED') {
				return 'connector-state-unavailable';
			}
			if (this.connectorStateKey == 'RESERVED' || this.connectorStateKey == 'ERROR' || this.connectorStateKey == 'UNAVAILABLE' || this.connectorStateKey == 'UNKNOWN' || this.connectorStateKey == 'OUT_OF_SERVICE') {
				return 'connector-state-unavailable';
			}
			return 'connector-state-other';
		},
		canStart() {
			if(!this.charger.tariff) {
				return false
			}

			if(this.connectorStateKey === 'AVAILABLE' || this.connectorStateKey === 'PREPARING') {
				return true
			}

			if(this.connectorStateKey === 'FINISHING' && this.charger.deviceType && this.charger.deviceType.canStartNewChargeWhileInFinishingState) {
				return true
			}

			return false
		}
	},
	methods: {
		toggleInfo() {
			this.showInfo = !this.showInfo;
			jQuery('#chargerDetails').collapse(this.showInfo ? 'show' : 'hide');
		},
		carHasChargingPowerInfo() {
			if(this.currentType === 'DC') {
				return this.selectedCar.maxChargingPowerDCKw !== null && typeof this.selectedCar.maxChargingPowerDCKw !== 'undefined'
			}
			else if(this.currentType === 'AC') {
				return this.selectedCar.maxChargingPowerACKw !== null && typeof this.selectedCar.maxChargingPowerACKw !== 'undefined'
			}
		},
		calculateKwFromPrice(price) {
			const kw = (price - this.priceSettings.convenienceFeeGross) / this.priceSettings.unitChargingGross;
			return kw.toFixed(2);
		},
		calculateTimeFromPrice(price) {
			if (this.selectedCar) {
				let maxPerformance = this.getMaxPerformance();
				const kw = this.calculateKwFromPrice(price);
				const time = (60 / maxPerformance) * kw;
				return Math.floor(time);
			}
		},
		getMaxPerformance() {
			let currentType = 'AC'
			let carPerformance = this.selectedCar.maxChargingPowerACKw
			if(currentType === 'DC') {
				carPerformance = this.selectedCar.maxChargingPowerDCKw
			}
			return Math.min(this.chargerPerformance, carPerformance);
		},
		calculateKwFromTime(time, decimals) {
			if (this.selectedCar) {
				let maxPerformance = this.getMaxPerformance();

				const kw = (maxPerformance / 60) * time;
				return kw.toFixed(decimals);
			}
		},
		getWalletBalance() {
			this.$rest.getWalletBalance((responseData) => {
				if (responseData.result) {
					this.walletBalance = responseData.data.ballance;
				}
			});
		},
		loadChargerId() {
			const cId = this.$route.query;
			if (this.$route.query.chargerId) {
				this.chargerId = this.$route.query.chargerId;
			} else {
				this.chargerId = this.$route.params.chargerId;
			}
		},
		async onLoad() {
			let loggedInUserData = await this.$store.getters.getLoggedInUserData;
			if (loggedInUserData) {
				this.getWalletBalance();

				this.$rest.getCreditCards((data) => {
					this.creditCards = data;
					console.log(this.creditCards);
					this.creditCards = this.creditCards.filter((card) => {
						if (card.cardMask) return card;
					});
				});
			}

			if (this.chargerId) {
				this.$rest.getCharger(
					this.chargerId,
					(responseData) => {
						this.charger = responseData;
						this.showStartCharging();
						this.getChargerProperties();
					},
					(error) => {
						this.showNoChargerInfo = true;
					}
				);

				await this.getPriceSettings(this.chargerId);
			} else {
				alert(this.$t('casualSurvey.somethingWentWrong'));
				this.$router.push({ path: '/charger-search/charger' });
			}
		},
		async getPriceSettings(chargerId) {
			await this.$rest.getPriceSettings(chargerId, (response) => {
				this.priceSettings = response;
			});
		},
		getChargerProperties() {
			this.currentType = this.charger.deviceType && this.charger.deviceType.currentType ? this.charger.deviceType.currentType : 'AC'

			this.traitDetails = []

			for(let trait of this.charger.staticTraits) {
				if(trait.trait.identifier === 'outlets') {
					this.outlets = trait.listItems
				}
				else if(trait.trait.identifier === 'performance') {
					this.chargerPerformance = trait.numericValue
				}

				if (trait.trait.major) {
					this.traitDetails.push(trait);
				}
			}

			if(this.charger.status) {
				this.connectorStateKey = this.charger.status.value
				this.connectorState = this.charger.status.title
			}

			this.traitDetails = this.traitDetails.filter((tv) => !this.isTraitValueEmpty(tv));
			this.traitDetails.sort((tv1, tv2) => tv1.trait.orderNumber - tv2.trait.orderNumber);
		},
		isTraitValueEmpty(tv) {
			if (!tv || !tv.trait || !tv.trait.type) {
				return true;
			}
			if (tv.trait.type == 'TEXT' && !tv.value) {
				return true;
			}
			if (tv.trait.type == 'LIST_SINGLE' && !tv.listItem) {
				return true;
			}
			if (tv.trait.type == 'LIST_MULTIPLE' && !tv.listItems) {
				return true;
			}
			if (tv.trait.type == 'LIST_CUSTOM' && !tv.listStrings) {
				return true;
			}
			return false;
		},
		showStartCharging() {
			if (this.loggedInUserData) {
				this.$rest.listBillingDataForCurrentUser((responseData) => {
					this.billingDatas = responseData ? responseData : [];

					this.selectedBillingData = null;

					for (var i = 0; i < this.billingDatas.length; i++) {
						this.selectedBillingData = this.billingDatas[i];
						if (this.selectedBillingData.defaultBillingData) {
							break;
						}
					}
					this.showStartChargingDialog = true;
				});

				this.$rest.loadMyCars((responseData) => {
					this.cars = responseData;
					for (let car of this.cars) {
						if (car.defaultCar === true) {
							this.selectedCar = car;
						}
					}
					if (this.cars.length == 0 && this.$store.getters.getSettings.wizardCarsOptional) {
						this.showAmountPicker = true;
					}
				});
			}
		},
		onShowTimePicker() {
			if (!this.carHasChargingPowerInfo()) {
				this.showHintForCarAddition = true;
			} else {
				this.showTimePicker = true;
				this.showHintForCarAddition = false;
				utils.scrollToAnchor('step4');
			}
			this.showAmountPicker = false;
			this.pickedAmount = null;
			this.pickedChargeFinishTime = false;
			this.pickedChargeMaxKW = false;
		},
		onShowAmountPicker() {
			this.showHintForCarAddition = false;
			this.fullBalance = false;
			this.showTimePicker = false;
			this.showAmountPicker = true;
			this.pickedAmount = null;
			this.pickedChargeFinishTime = false;
			this.pickedChargeMaxKW = false;
			utils.scrollToAnchor('step4');
		},
		onFullBalance() {
			this.fullBalance = true;
			this.showHintForCarAddition = false;
			this.showTimePicker = false;
			this.showAmountPicker = true;
			this.pickedAmount = null;
			this.pickedChargeFinishTime = false;
			this.pickedChargeMaxKW = false;
			this.pickedAmount = { amount: this.walletBalance };
			this.priceToPayNative = this.walletBalance;
			this.priceToPay = Intl.NumberFormat().format(this.walletBalance);
			this.paymentSummary = {
				reservedEnergy: this.calculateKwFromPrice(this.walletBalance),
				time: this.calculateTimeFromPrice(this.walletBalance),
				amount: this.walletBalance
			};
			utils.scrollToAnchor('step5');
		},
		onShowChargeFinishTimePicker() {
			if (!this.carHasChargingPowerInfo()) {
				this.showHintForCarAddition = true;
				this.pickedChargeFinishTime = false;
				this.pickedChargeMaxKW = false;
				this.showAmountPicker = false;
			} else {
				this.showHintForCarAddition = false;
				this.showTimePicker = false;
				this.showAmountPicker = false;
				this.pickedAmount = null;
				this.pickedChargeFinishTime = true;
				this.pickedChargeMaxKW = false;
				utils.scrollToAnchor('step4');
			}
		},
		onShowChargeMaxKWPicker() {
			this.showHintForCarAddition = false;
			this.showTimePicker = false;
			this.showAmountPicker = false;
			this.pickedAmount = null;
			this.pickedChargeFinishTime = false;
			this.pickedChargeMaxKW = true;
			utils.scrollToAnchor('step4');
		},
		async onTimePicked(time) {
			if (time <= 0) {
				this.paymentSummary = null;
				this.pickedAmount = null;
				return;
			}
			const kw = this.calculateKwFromTime(time, 2);
			await this.setPriceToPayFromBackend(time);
			this.paymentSummary = {
				reservedEnergy: kw,
				time: time,
				amount: this.priceToPay
			};
			this.pickedAmount = { time: time };
			utils.scrollToAnchor('step5');
		},
		async onKwPicked(kw) {
			if (!kw) {
				this.pickedAmount = null;
				this.paymentSummary = null;
				return;
			}
			const maxPerformance = this.getMaxPerformance();
			const time = ((60 / maxPerformance) * kw).toFixed(0);
			await this.setPriceToPayFromBackend(time);
			this.paymentSummary = {
				reservedEnergy: kw,
				time: time,
				amount: this.priceToPay
			};
			this.pickedAmount = { time: time };
			utils.scrollToAnchor('step5');
		},
		onAmountPicked(amount, fullBalance) {
			if (fullBalance) this.fullBalance = true;
			this.pickedAmount = { amount: amount };
			this.priceToPayNative = amount;
			this.priceToPay = Intl.NumberFormat().format(amount);
			this.paymentSummary = {
				reservedEnergy: this.calculateKwFromPrice(amount),
				time: this.calculateTimeFromPrice(amount),
				amount: amount
			};
			utils.scrollToAnchor('step5');
		},
		async setPriceToPayFromBackend(time) {
			await this.$rest.getGrossToPay(
				this.charger.id,
				this.selectedCar.id,
				time,
				(grossToPay) => {
					this.priceToPayNative = grossToPay;
					this.priceToPay = grossToPay;
				},
				(error) => {}
			);
		},
		stopCharging() {
			this.$rest.stopCharging(this.charger.id, (responseData) => {});
		},
		scrollToBottom() {
			setTimeout(() => {
				window.scrollTo({ top: document.body.scrollHeight, left: 0, behavior: 'smooth' });
			}, 0);
		}
	},
	mounted() {
		this.loadChargerId();
		this.onLoad();
		setTimeout(() => {
			if (!this.loggedInUserData) {
				utils.scrollToAnchor('survey');
			}
		}, 300);
	},
	watch: {
		//this seems to be not working
		$route(to, from) {
			if (to.name === 'charger') {
				this.onLoad();
			}
		},
		loggedInUserData(newValue, oldValue) {
			if (!newValue) {
				this.$router.push({ name: 'login' });
			}
		}
	},
	components: {
		ParkingCodeInput,
		ChargeMaxKwTemplate,
		ChargeFinishTimeTemplate,
		LocationPanelCharger,
		PaymentSummaryCard,
		TraitValue,
		ChargeStartArea,
		CasualChargingSurvey,
		ParkingAlertPanel,
		BasicDialog
	}
};
</script>

<style>
.step-container {
	padding: 10px 20px;
	border-radius: 6px;
	margin-bottom: 10px;
	/* border: 1px solid #dddddd; */
}

.step-number:after {
	content: '.';
	position: absolute;
}

.step-list {
	margin-left: 1rem;
}

.time-bubble-row {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	position: relative;
	margin: 0.25rem 0;
}

.time-bubble-row:before {
	content: '';
	display: block;
	border-top: 1px solid #333333;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	transform: translateY(50%);
}

.time-bubble {
	display: inline-block;
	border: 1px solid #333333;
	min-width: 65px;
	height: 65px;
	text-align: center;
	border-radius: 50%;
	font-size: 0.9rem;
	line-height: 0.9rem;
	position: relative;
	overflow: hidden;
	background-color: #ffffff;
}

.time-bubble-inner {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	display: flex;
	flex-direction: column;
}

.time-bubble-value {
	display: block;
	margin: auto;
	padding-bottom: 2rem;
	font-size: 1.2rem;
	font-weight: bold;
	color: #509cd5;
}

.time-bubble-label {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	padding-bottom: 1.4rem;
	background-color: rgba(0, 0, 0, 0.75);
	color: #ffffff;
	font-size: 0.7rem;
}

.time-bubble-performance {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	padding-bottom: 0.3rem;
	background-color: white;
	color: black;
	font-weight: bold;
	font-size: 0.6rem;
}

.time-bubble:hover,
.time-bubble:active,
.time-bubble.active {
	background-color: #509cd5;
	transform: translateY(-2px);
}

.time-bubble:hover .time-bubble-value,
.time-bubble:active .time-bubble-value,
.time-bubble.active .time-bubble-value {
	color: #ffffff;
	transform: translateY(-4px);
	font-size: 1.4rem;
}

.charger-limit-type {
	/* border: 1px solid #333333; */
	border-radius: 6px;
	display: flex;
	flex-direction: column;
	padding: 1rem;
	width: 100%;
	color: #333333;
	height: 100%;
	border: 1px solid rgb(180, 180, 180);
	background: white;
}

.charger-limit-type > * {
	margin-top: auto;
	margin-bottom: auto;
}

.charger-limit-type:hover,
.charger-limit-type:active,
.charger-limit-type.active {
	color: #ffffff;
	text-decoration: none;
	background-color: #509cd5;
	filter: drop-shadow(0 4px 3px rgb(0 0 0 / 0.07)) drop-shadow(0 2px 2px rgb(0 0 0 / 0.06));
	transform: translateY(-2px);
}

.charger-payment-stripe {
	padding: 15px;
	display: flex;
	flex-wrap: wrap !important;
	justify-content: center;
	gap: 10px;
}

.charger-payment {
	border: 1px solid #dadada;
	border-radius: 6px;
	padding: 15px;
	color: #333333;
	background-color: rgba(255, 255, 255, 0.1);
	box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
	width: 155px;
	min-height: 145px;
}

.charger-payment {
}

a.charger-payment:hover,
a.charger-payment:active,
a.charger-payment.active {
	color: #ffffff;
	text-decoration: none;
	background-color: #509cd5;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
	transform: translateY(-2px);
}

.charger-payment > span.media-body {
	margin-left: 0.5rem;
}

.charger-payment > span.media-body > label {
	margin-bottom: 0;
	font-weight: bold;
	font-size: 1.1rem;
}

.charger-payment > span.media-body > span {
	display: block;
	font-size: 0.9rem;
}

.amount-to-pay {
	width: auto;
	display: inline-block;
	color: #ffffff;
	background-color: #28a745;
	font-size: 1.8rem;
	font-weight: bold;
	font-family: -apple-system;
	border-radius: 10px;
	margin: 10px 10px 20px 10px;
	text-align: center;
	padding: 0px 15px;
	height: 43px;
	box-shadow: grey 2px 2px;
}

.charger-info-toggle-button-2 {
	width: 100%;
	max-width: 300px;
	min-height: 40px;
	border-radius: 6px;
	background: transparent;
	border: 1px solid var(--active);
	color: var(--active);
}

.media-body-container {
	display: flex;
	flex-direction: column;
	align-items: center;
}

@media screen and (min-width: 470px) {
	.media-body-container {
		flex-direction: row;
	}
}

@media screen and (min-width: 490px) {
	.charger-payment {
		width: 200px;
		min-height: unset;
	}
}
</style>
