<template>
	<div>
		<div style="position: relative" class="p-2 rounded-lg parking-card-container">
			<div>
				<div class="font-weight-bold">
					{{ parkingLot.name }}
				</div>

				<div class="media">
					<div class="media-body align-self-center">
						<template v-if="parkingLot.tariff">
							<div v-if="parkingLot.tariff.freeParking" class="text-success">{{ $t('location-panel.free') }}</div>
							<div v-else class="text-danger">{{ $t('location-panel.price-parking', [parkingLot.tariff.grossUnitParking]) }}</div>
						</template>
					</div>
				</div>
			</div>

			<div v-if="canStart">
				<template>
					<PrimeButton @click="$emit('showStartParking', parkingLot.id)" :style="{ 'background-color': 'var(--newbtn-green)', 'min-width': '250px', border: 'none' }" class="new-btn centered">
						<i class="pi pi-play"></i>
						{{ $t('parkingView.startParking') }}
					</PrimeButton>
				</template>
			</div>
		</div>
	</div>
</template>

<script>
import jQuery from 'jquery';
import TraitValue from '@/components/TraitValue.vue';
import { GlobalEventEmitter } from '@/plugins/GlobalEventEmitter';

export default {
	name: 'LocationPanelParking',
	props: { parkingLot: Object },
	components: { TraitValue },
	data() {
		return {
			performance: '',
			currentType: '',
			outlets: [],
			connectorState: '',
			connectorStateKey: '',
			thereIsRunningSession: true,
			traitDetails: [],
			showInfo: false,
			showPanel: true
		};
	},
	methods: {},
	mounted() {},
	computed: {
		canStart() {
			return true;
		}
	},
	emits: ['hideLocationPanel', 'showStartParking']
};
</script>

<style>
.location-panel-charger-name {
	font-size: 26px;
	font-weight: bold;
	width: 50px;
	height: 50px;
	border-radius: 50%;
	border: 2px solid #333333;
}

.connector-state {
	font-size: 18px;
	font-weight: bold;
}

.connector-state-available {
	color: var(--mode-color);
}

.connector-state-unavailable {
	color: #b21914;
}

.connector-state-other {
	color: #aaaaaa;
}

.connector-state-preparing {
	color: var(--mode-color);
}

.outlet-item {
	white-space: nowrap;
}

.outlet-item img {
	vertical-align: middle;
	margin-right: 4px;
}

.outlet-item span {
	font-size: 0.9rem;
	vertical-align: middle;
}

.location-panel-charger-button {
	font-size: 16px;
	font-weight: bold;
	padding: 15px 30px;
	margin: 0 auto;
}

.charger-description p {
	margin-bottom: 0;
}

.charger-info-toggle-button {
	width: 100%;
	border: none;
	background: transparent;
	color: #999999;
	transform: translateY(8px);
}

@media screen and (min-width: 320px) {
	.start-charging-btn {
		margin-top: 10px;
	}
	.parking-card-container {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
	}
}

@media screen and (min-width: 900px) {
	.parking-card-container {
		flex-direction: row;
	}
}
</style>
