<template>
	<div class="address-container">
		<div v-if="!choosedType">
      <h2 class="billing-title">{{ $t('billingDatas.listHeader') }}</h2>
			<table class="address-table table shadow-table" v-if="billingDatas.length">
				<tbody>
					<tr v-for="billingData in billingDatas" :key="billingData.id">
						<td class="maxWidth td">
							<div class="float-right ml-3 buttonRow td-btns">
								<PrimeButton style="background-color: var(--newbtn-blue)" v-if="billingData.type" @click="modifyBillingData(billingData)" :style="{ 'border-radius': '6px', 'min-height': '40px', padding: '0px 20px' }" class="new-btn centered">
									<i class="pi pi-pencil"></i>
									{{ $t('billingDatas.modify') }}
								</PrimeButton>
								<PrimeButton @click="deleteBillingData(billingData)" :style="{ 'border-radius': '6px', 'min-height': '40px', padding: '0px 20px', 'background-color': '#ef4444', border: 'none' }" class="new-btn centered">
									{{ $t('billingDatas.delete') }}
									<i class="pi pi-trash"></i>
								</PrimeButton>
							</div>
							<div>
								<b>{{ billingData.payerName }}</b>
								<span class="default-text-title py-1 ml-2" v-if="billingData.defaultBillingData">
									{{ $t('billingDatas.defaultBillingData') }}
								</span>
							</div>
							<div v-if="billingData.type == 'COMPANY'" class="billing-secondary-data">{{ $t('billingDatas.taxNumber') }}: {{ billingData.taxNumber }}</div>
							<div class="billing-secondary-data">{{ $t('billingDatas.add') }}: {{ billingData.address.full }}</div>
						</td>
					</tr>
				</tbody>
			</table>
			<div class="centered">
				<PrimeButton v-if="!editedBillingData" style="background-color: var(--newbtn-blue)" :style="{ 'border-radius': '6px', 'min-height': '50px', padding: '0px 20px' }" @click="createNewBillingData" class="new-btn centered add-billing-btn">
					{{ $t('billingDatas.buttonAdd') }}
					<i class="pi pi-plus-circle icon"></i>
				</PrimeButton>
			</div>
		</div>
		<BillingDataEditor :billingData="editedBillingData" :shouldBeDefault="billingDatas.length == 0" :loggedInUserData="loggedInUserData" :msgs="msgs" @chooseType="setChoosedType" @save="saveBillingData" @cancel="cancelEditedBillingData" v-if="editedBillingData" />
	</div>
</template>

<script>
import Vue from 'vue';
import BillingDataEditor from '@/components/BillingDataEditor.vue';

export default {
	name: 'BillingDataView',
	data() {
		return {
			billingDatas: [],
			editedBillingData: null,
			choosedType: false
		};
	},
	props: {
		loggedInUserData: Object,
		msgs: Object
	},
	methods: {
		createNewBillingData() {
			this.editedBillingData = { address: {} };
		},
		saveBillingData() {
			this.$rest.saveBillingDataForCurrentUser(this.editedBillingData, (responseData) => {
				this.billingDatas = responseData ? responseData : [];
				this.cancelEditedBillingData();
				this.$emit('onSaved');
			});
		},
		setChoosedType() {
			this.choosedType = !this.choosedType;
		},
		deleteBillingData(billingData) {
			if (confirm(this.$t('billingDatas.confirmDelete'))) {
				this.$rest.deleteBillingDataForCurrentUser(billingData.id, (responseData) => {
					this.billingDatas = responseData ? responseData : [];
					this.cancelEditedBillingData();
				});
			}
		},
		modifyBillingData(billingData) {
			this.editedBillingData = billingData;
		},
		cancelEditedBillingData() {
			this.editedBillingData = null;
			this.loadBillingDatas();
			this.choosedType = false;
		},
		loadBillingDatas() {
			this.$rest.listBillingDataForCurrentUser((responseData) => {
				this.billingDatas = responseData ? responseData : [];
			});
		}
	},
	mounted() {
		this.loadBillingDatas();
	},
	components: {
		BillingDataEditor
	},
	emits: ['onSaved']
};
</script>

<style>
.billing-title{
  font-weight:normal;
  font-size: 1.4rem;
  text-align:center;
  margin-top: 20px;
}

.billing-secondary-data {
	font-size: 13px;
}

.address-container {
	margin-bottom: auto;
	margin-top: 20px;
	height: auto;
	align-self: flex-start;
	width: 100%;
}

.td-btns {
  display: flex !important;
  justify-content: center;
  order: 1;
  margin-top: 10px;
}

@media (min-width: 320px) and (max-width: 580px) {
	.td {
		display: flex;
		flex-direction: column;
	}

	.default-span {
		font-size: 17px;
	}
}

@media screen and (min-width: 470px){
  .billing-title{
    text-align:left;
  }
}

</style>
