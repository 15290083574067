<template>
	<div class="loginCard" v-if="showLogin">
		<form class="login-form">
			<div class="email-pass-container">
				<label for="email">{{ $t('login.email') }}:</label>
				<input type="text" v-model.trim="email" class="form-control login-input" autocorrect="off" autocapitalize="none" />
			</div>
			<div class="email-pass-container">
				<label for="password">{{ $t('login.password') }}:</label>
        <Password
          toggleMask
          :feedback="false"
          v-model.trim="password"
          :style="{
          'min-width':'100%'}"
          :input-style="{
          'border-radius': '6px',
          'min-height': '42px'
          }"
          input-class="form-control w-100 login-input"
        />
			</div>
			<div class="d-flex flex-column align-items-center">
				<div class="text-center login-btn-container">
          <PrimeButton @click="login" type="button" v-on:keyup.enter="login" style="background-color: var(--newbtn-blue)" :style="{ width: '100%', height: '50px', 'border-radius': '6px', 'min-height': '40px', padding: '0px 20px' }" class="new-btn centered">
            <i class="pi pi-sign-in"></i>
            <span>{{ $t('login.loginButton') }}</span>
          </PrimeButton>
				</div>
			</div>
		</form>
		<div class="forgottpass-registration-container">
      <p class="mb-1"> {{$t('charger-view.no-account')}}</p>
      <router-link to="register">{{ $t('login.registerLink') }}</router-link>
			<router-link to="forgotten-password">{{ $t('login.forgottenPasswordLink') }}</router-link>
		</div>
		<div class="other-login-container my-4 mx-auto d-flex flex-column align-items-center" style="border: 1px solid #ddd; max-width: 320px">
			<div style="margin-bottom: 10px">
				{{ $t('login.socialLoginHeader') }}
			</div>
			<div v-if="showAppleNativeLoginButton">
				<button class="loginBtn loginBtn--apple mb-3" @click="appleLogin">
					{{ $t('login.appleLoginButton') }}
				</button>
			</div>

			<div style="margin-bottom: 12px">
				<div v-if="!nativeGoogle">
					<div id="g_id_onload" data-client_id="1089962333512-761ijatbd025mtd48dtcec70g0mjauqr.apps.googleusercontent.com" data-context="signin" data-ux_mode="popup" data-callback="googleLogin" data-auto_select="false" data-itp_support="true" :data-locale="$i18n.locale"></div>

					<div class="g_id_signin" data-type="standard" data-shape="rectangular" data-theme="outline" data-text="continue_with" data-size="large" data-logo_alignment="left" data-width="280" :data-locale="$i18n.locale"></div>
				</div>

				<button class="loginBtn loginBtn--google m-0" @click="googleNativeLogin" v-else>
					{{ $t('login.googleLoginButton') }}
				</button>
			</div>

			<div v-if="!showAppleNativeLoginButton" style="height: 44px; background-color: black; padding-top: 4px; padding-left: 4px; border-radius: 4px" id="appleid-signin" class="apple-signin" data-mode="left-align" data-type="sign-in" data-color="black" data-border="false" data-border-radius="8" data-width="276" data-height="36" data-logo-size="large" data-label-position="84"></div>

			<!--div>
        <button class="loginBtn loginBtn--facebook" @click="fbLogin">
          {{ $t('login.fbLoginButton') }}
        </button>
      </div-->
		</div>

		<i18n class="gdpr-container" path="login-view.accept-message" tag="div">
			<template v-slot:privacy-statement>
				<a href="javascript:void(0)" @click="showPrivacyPolicy()" class="px-2 py-0">{{ $t('aboutUs.privacyStatement') }}</a>
			</template>
			<template v-slot:gtc>
				<a href="javascript:void(0)" @click="showGTC()" class="px-2 py-0">{{ $t('aboutUs.termsAndConditions') }}</a>
			</template>
		</i18n>
	</div>
</template>

<script>
import GtcView from '@/views/GtcView.vue';
import GtcENView from '@/views/GtcENView.vue';
import PrivacyStatementView from '@/views/PrivacyStatementView.vue';
import PrivacyStatementENView from '@/views/PrivacyStatementENView.vue';

export default {
	name: 'LoginView',
	data() {
		return {
			app: null,
			signedIn: false,
			email: '',
			password: '',
			params: {
				client_id: '1089962333512-761ijatbd025mtd48dtcec70g0mjauqr.apps.googleusercontent.com'
			},
			nativeFacebook: 'ios' === window.platform || 'android' === window.platform,
			nativeGoogle: 'ios' === window.platform || 'android' === window.platform
		};
	},
	props: {
		loggedInUserData: Object
	},
	computed: {
		showLogin() {
			return this.loggedInUserData == null || !this.loggedInUserData.loggedInEmail;
		},
		showAppleNativeLoginButton() {
			return 'ios' === window.platform || 'android' === window.platform;
		}
	},
	methods: {
		showPrivacyPolicy() {
			let components = [{ component: this.$i18n.locale == 'hu' ? PrivacyStatementView : PrivacyStatementENView }];

			this.app.showBSModal(components, this.$t('aboutUs.privacyStatement'), null, true);
		},
		showGTC() {
			let components = [{ component: this.$i18n.locale == 'hu' ? GtcView : GtcENView }];

			this.app.showBSModal(components, this.$t('aboutUs.termsAndConditions'), null, true);
		},
		login() {
			this.$emit('login', this.email, this.password, (userData) => {
				this.email = null;
				this.password = null;
			});
		},
		googleNativeLogin() {
			window.loadAppLink('freecharger://google-login');
		},
		googleLoginFailure(p) {
			//console.log(p);
		},
		fbLogin() {
			if (this.nativeFacebook) {
				window.loadAppLink('freecharger://facebook-login');
			} else {
				window.FB.login(
					(resp) => {
						const authResponse = resp.authResponse;
						if (authResponse) {
							this.$emit('fbLogin', authResponse);
						}
					},
					{ scope: 'email', return_scope: true }
				);
			}
		},
		appleLogin() {
			window.loadAppLink('freecharger://apple-login');
		},
		createWebAppleLogin() {
			if (!window.AppleID) {
				console.log('"https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js" needs to be included as a <script>');
			}
			window.AppleID.auth.init({
				clientId: 'hu.r2g.freecharger.login',
				scope: 'name email',
				redirectURI: this.$rest.baseUrl + '/v1/user/apple-return',
				usePopup: true
			});
			document.addEventListener('AppleIDSignInOnSuccess', (event) => {
				const code = event.detail.authorization.code;
				const user = event.detail.authorization.user;
				this.$rest.loginWithApple(code, user, (response, extraData) => {
					this.$emit('appleWebLogin', response, extraData);
				});
			});
			document.addEventListener('AppleIDSignInOnFailure', (event) => {
				alert(this.$t('login.appleLoginFailed'));
			});
		}
	},
	created() {
		this.app = this.$root.$children[0];
	},
	mounted() {
		let gsiScript = document.createElement('script');
		gsiScript.setAttribute('src', 'https://accounts.google.com/gsi/client');
		document.head.appendChild(gsiScript);

		this.createWebAppleLogin();
		if ('ios' !== window.platform) {
			const fbScript = document.createElement('script');
			fbScript.setAttribute('src', 'https://connect.facebook.net/hu_HU/sdk.js#xfbml=1&version=v8.0&appId=3604530536246633&autoLogAppEvents=1');
			document.head.appendChild(fbScript);
		}
	},
	components: {},
	emits: ['login', 'fbLogin', 'googleLogin']
};
</script>

<style scoped>
@media (min-width: 320px) {
	.loginCard {
		padding-top: 20px;
		display: flex;
		flex-direction: column;
    border-radius: 12px;
	}

	.login-btn-container {
		margin-top: 20px;
		width: 100%;
		max-width: 400px;
	}

	.login-btn {
		min-width: 100%;
		min-height: 44px;
	}

	.email-pass-container {
		display: flex;
		align-items: center;
		flex-direction: column;
		margin-top: 10px;
	}

	.email-pass-container input {
		width: 100%;
	}

  .login-input {
    border-radius: 6px;
    min-height: 42px;
  }

	.email-pass-container label {
		font-size: 14px;
		align-self: baseline;
	}

	.forgottpass-registration-container {
		display: flex;
		flex-direction: column;
		gap: 5px;
		justify-content: center;
		align-items: center;
		margin-top: 20px;
	}

	.other-login-container {
		min-width: 280px;
		padding: 20px 0;
		border-radius: 5px;
	}

	.gdpr-container {
		width: 280px;
		text-align: center;
		margin: 0 auto 20px auto;
		font-size: 14px;
	}
}

@media (min-width: 380px) {
	.other-login-container {
		min-width: 340px;
		padding: 20px 0;
	}

	.gdpr-container {
		width: 350px;
		text-align: center;
		margin-bottom: 20px;
	}
}

@media (min-width: 450px) {
	.loginCard {
		max-width: 700px;
		margin: 0 auto;
		padding: 40px 0px 70px 0px;
		background-color: white;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
    border: 1px solid rgb(236, 236, 236);
  }

	.login-form {
		display: flex;
		flex-direction: column;
		gap: 10px;
		max-width: 400px;
		margin: 30px auto 15px auto;
	}

	.email-pass-container label {
		margin-bottom: 0;
		font-weight: 600;
		margin-left: 0;
	}

	.email-pass-container input {
		width: 400px;
	}

  .forgottpass-registration-container {
    margin-top: 10px;
  }

  .other-login-container {
    min-width: 400px;
    padding: 10px;
  }

	.gdpr-container {
		width: 400px;
		margin: 0 auto;
	}

	button.login-button svg {
		height: 15px;
		margin-top: -3px;
		margin-right: 3px;
	}
}
</style>
