<template>
	<div class="d-flex flex-column flex-grow-1">
		<div class="no-shadow-container profile-data-container centered rounded-lg">
			<div v-if="!deleteProfileVisible" class="col-lg-12 profile_form-center">
				<h2 class="header-common pt-3">{{ $t('profile.accountInfo') }}</h2>
				<div class="row">
					<div class="col-sm-12 form-group">
						<label for="email">{{ $t('profile.email') }}:</label>
						<EmailInput id="email" class="form-control mb-2 account-input" maxlength="255" :msgs="msgs" :readonly="!usedApple && !usedFacebook && !usedGoogle" v-model="email" />
						<h6 v-if="usedApple">{{ $t('profile-view.alternative-login', { name: 'Apple' }) }}</h6>
						<h6 v-if="usedFacebook">{{ $t('profile-view.alternative-login', { name: 'Facebook' }) }}</h6>
						<h6 v-if="usedGoogle">{{ $t('profile-view.alternative-login', { name: 'Google' }) }}</h6>
					</div>

					<div class="col-sm-12 form-group">
						<label for="phone">{{ $t('profile.phone') }}: *</label>
						<PhoneInput id="phone" v-model="phone" :msgs="msgs" class="form-control account-input" maxlength="255" :required="true" />
						<ComponentMessage :msgs="msgs" forComponent="phone" />
					</div>

					<div class="col-sm-12 form-group">
						<label for="lang">{{ $t('profile-view.language') }}:</label>
						<select id="lang" v-model="lang" class="form-control account-input">
							<option value="hu">Magyar</option>
							<option value="en">English</option>
						</select>
					</div>

					<div class="col-12 form-group media" v-if="email && hasPassword">
						<input id="showPasswordChangeFields" type="checkbox" v-model="passwordChangeVisible" class="switch form-check-input" />
						<label for="showPasswordChangeFields media-body" class="form-check-label">{{ $t('profile-view.want-to-change-password') }}</label>
					</div>

					<div class="col-sm-12" v-if="passwordChangeVisible || !hasPassword">
						<h3 class="header-common" v-if="hasPassword">{{ $t('profile-view.change-password') }}</h3>
						<h3 class="header-common" v-else>{{ $t('profile-view.set-password') }}</h3>

						<div class="col-sm-12 form-group" v-if="hasPassword">
							<label for="oldPassword">{{ $t('profile-view.current-password') }}</label>
							<PasswordInput id="oldPassword" v-model="oldPassword" :msgs="msgs" class="form-control" maxlength="255" />
							<ComponentMessage :msgs="msgs" forComponent="oldPassword" />
						</div>

						<div class="col-sm-12 form-group">
							<label for="password">{{ $t('profile.password') }}:</label>
							<PasswordInput id="password" v-model="password" :msgs="msgs" class="form-control" maxlength="255" />
							<ComponentMessage :msgs="msgs" forComponent="password" />
						</div>

						<div class="col-sm-12 form-group">
							<label for="password2">{{ $t('profile.password2') }}:</label>
							<PasswordInput id="password2" v-model="password2" :msgs="msgs" class="form-control" maxlength="255" :password1="password" />
							<ComponentMessage :msgs="msgs" forComponent="password2" />
						</div>
						<div class="col-sm-12">
							<div class="form-group text-right">
								<small>{{ $t('profile.mandatoryLabel') }}</small>
							</div>
						</div>
					</div>
					<div class="save-btn-container">
						<PrimeButton @click="save()" style="background-color: var(--newbtn-blue)" :style="{ width: '100%', height: '50px', 'border-radius': '6px', 'min-height': '40px', padding: '0px 20px' }" class="new-btn centered">
							{{ $t('billingDataEditor.save') }}
							<i class="pi pi-save"></i>
						</PrimeButton>
					</div>
					<div class="d-flex w-100 footer-btns">
						<div class="cancel-btn-container">
							<PrimeButton v-if="!wizardMode" style="background-color: var(--newbtn-blue)" @click="cancel()" :style="{ 'border-radius': '6px', 'min-height': '40px', padding: '0px 20px', border: 'none' }" class="new-btn centered">
								<i class="pi pi-arrow-left"></i>
								{{ $t('wizard.prev') }}
							</PrimeButton>
						</div>
						<div class="delete-btn-container" v-if="!wizardMode">
							<PrimeButton @click="deleteProfileVisible = true" v-if="!deleteProfileVisible" :style="{ 'border-radius': '6px', 'min-height': '40px', padding: '0px 20px', 'background-color': '#ef4444', border: 'none' }" class="new-btn centered">
								<i class="pi pi-user-minus"></i>
								{{ $t('profile-view.delete-profile') }}
							</PrimeButton>
						</div>
					</div>
				</div>
			</div>
			<div class="card" v-if="deleteProfileVisible">
				<div class="card-header">{{ $t('profile-view.delete-profile') }}</div>
				<div class="card-body" v-html="this.$t('profile-view.profile-delete-message')"></div>
				<div class="card-footer">
					<PrimeButton @click="deleteProfileVisible = false" :style="{ display: 'flex', gap: '5px' }">
						<i class="pi pi-arrow-left"></i>
						{{ $t('wizard.prev') }}
					</PrimeButton>
					<PrimeButton @click="deleteProfile()" :style="{ display: 'flex', gap: '5px', 'border-radius': '6px', 'min-height': '40px', padding: '0px 20px', 'background-color': '#ef4444', border: 'none' }">
						<i class="pi pi-exclamation-triangle"></i>
						{{ $t('profile-view.delete-profile') }}
					</PrimeButton>
				</div>
			</div>
		</div>
	</div>
</template>

<style>
.profile_form-center {
	max-width: 35rem !important;
	margin: auto;
}
</style>

<script>
export default {
	name: 'ProfileView',

	data() {
		return {
			passwordChangeVisible: false,
			hasPassword: true,
			usedGoogle: false,
			usedFacebook: false,
			usedApple: false,
			deleteProfileVisible: false,
			email: '',
			phone: '',
			password: '',
			password2: '',
			oldPassword: '',
			lang: this.$i18n.locale
		};
	},
	props: {
		msgs: Object,
		loggedInUserData: Object,
		wizardMode: Boolean
	},
	methods: {
		save() {
			this.$i18n.locale = this.lang;
			localStorage.setItem('Language', this.lang);

			this.$rest.saveProfile(this.email, this.phone, this.password, this.password2, this.oldPassword, this.lang, (response) => {
        //1.below function had a side effect which changed the SideMenu items as the user was logged out
				// this.$emit('reloadUser');

        //2.this function logged out the user seemingly unnecessary
        // if (response.data.messages[0].severity === 'error') {
        //   this.$emit('logoutWithRest');
        // }
			});
		},
		cancel() {
			this.$emit('cancel');
			this.$router.push('/');
		},
		loadUser() {
			this.$rest.getLoggedInUser((user, extra) => {
				this.email = user.email;
				this.phone = user.phone;
				this.hasPassword = extra.hasPassword;
				this.usedApple = extra.usedApple;
				this.usedFacebook = extra.usedFacebook;
				this.usedGoogle = extra.usedGoogle;
			});
		},
		deleteProfile() {
			if (confirm(this.$t('profile-view.profile-delete-accept'))) {
				this.$rest.deleteProfile((success) => {
					if (success) {
						this.$emit('logoutNoRest');
						this.$router.replace('/');
					}
				});
			}
		}
	},
	beforeMount() {
		this.loadUser();
	},
	emits: ['reloadUser', 'logoutNoRest']
};
</script>
<style scoped>
.profile-data-container {
	padding: 30px 10px;
}

.delete-btn-container {
	display: flex;
	justify-content: flex-end;
	margin-top: 20px;
	padding: 0 16px;
}

.save-btn-container {
	width: 100%;
	padding: 0 16px;
	margin: 0 auto;
}

.cancel-btn-container {
	margin-top: 20px;
	padding: 0 16px;
}

.card-footer {
	display: flex;
	justify-content: space-between;
}

@media (min-width: 320px) and (max-width: 450px) {
	.footer-btns {
		justify-content: center;
	}

	.cancel-btn-container {
		display: none;
	}
}

@media (min-width: 450px) {
	.footer-btns {
		justify-content: space-between;
	}
}

@media (min-width: 550px) {
	.profile-data-container {
		padding: 3rem;
	}
}
</style>
