<template>

	<div class="parking-code-con px-1">
		<div style="text-align: center !important;">
			<label for="parking-code" class="parking-code-title"><font-awesome-icon icon="parking" size="lg" class="mr-1" /> {{ $t('parkingView.parkingCode') }}</label>
		</div>
		<div class="parking-code">
			<span v-for="i in length" :key="i">
				<input
					type="text"
					v-model="code[i-1]"
					@input="change(i-1)"
					:ref="`input${i-1}`"
					@focus="focus(i-1)"
					@keydown="(e) => onKeyDown(i-1, e)"
					@click="onClick(i-1)"
				/>
			</span>
		</div>
		<span class="parking-code-desc" v-if="showDescription">
			<small>{{ $t('parkingView.parkingCodeDescription') }}</small>
		</span>
	</div>
</template>

<script>

export default {
	name: 'ParkingCodeInput',
	props:{
		length: {
			type: Number,
			default: 5
		},
		showDescription: {
			type: Boolean,
			default: true
		},
		loadParking: {
			type: Boolean,
			default: true
		},
		parkCode: {
			type: String,
			default: ''
		}
	},
	data() {
		return {
			code: {},
		}
	},
	computed: {
		parking() {
			return this.$store.state.parking
		}
	},
	mounted() {
		if(this.parking && this.loadParking) {
			const code = this.parking.chargeCode.split('')
			for(let i = 0; i < code.length; i++) {
				this.code = {
					...this.code,
					[i]: code[i]
				}
			}
		}
		else if(this.parkCode) {
			const code = this.parkCode.split('')
			for(let i = 0; i < code.length; i++) {
				this.code = {
					...this.code,
					[i]: code[i]
				}
			}
		}
		else {
			for(let i = 0; i < this.length; i++) {
				this.code = {
					...this.code,
					[i]: ''
				}
			}
		}

		const value = Object.values(this.code).join('')
		this.$emit('code', value)

		document.querySelectorAll('.parking-code input').forEach((input) => {
			input.addEventListener('paste', this.onPaste)
		})
	},
	methods: {
		onPaste(e) {
			e.preventDefault()
			const text = e.clipboardData.getData('text/plain').trim()
			if(text.length > this.length) {
				return
			}
			const valid = text.split('').every(char => char.match(/[0-9A-Z]/))
			if(!valid) return
			for(let i = 0; i < text.length; i++) {
				if(i < this.length) {
					this.code[i] = text[i]
					this.$refs[`input${i}`][0].focus()
					this.change(i)
				}
			}
		},
		change(index) {
			if(this.code[index].length > 1) {
				this.code[index] = this.code[index].slice(0, 1)
			}

			this.code[index] = this.code[index].replace(/[^0-9a-zA-Z]/g, '_')
			if(this.code[index].includes('_')) {
				this.code[index] = ''
				return
			}


			this.code[index] = this.code[index].toUpperCase()

			if(this.code[index].length === 1 && index < this.length-1) { // when a number is entered go forward
				this.$refs[`input${index + 1}`][0].focus()
			}

			// if(this.code[index].length === 0 && index > 0) { // when a number is deleted go back
			//   console.log(index, this.code[index], "asd")
			//   this.$refs[`input${index - 1}`][0].focus()
			// }

			const value = Object.values(this.code).join('')
			if(value.length === this.length) {
				this.$emit('code', value)
			}
			else {
				this.$emit('code', '')
			}

		},
		focus(index) {
			this.$refs[`input${index}`][0].select()
		},
		onClick() {
			// const minNotFilled = Math.min(...Object.keys(this.code).map(key => parseInt(key)).filter(key => this.code[key].length === 0))
			//
			// if(minNotFilled !== Infinity) {
			//   this.$refs[`input${minNotFilled}`][0].focus()
			// }
		},
		onKeyDown(index, e) {
			if(e.key === 'Backspace' && index > 0 && this.code[index].length === 0) { // when backspace is pressed go back
				//console.log(index, this.code[index])
				this.$refs[`input${index-1}`][0].focus()
				this.code[index] = ''
			}

			if(e.key === 'ArrowLeft' && index > 0) { // when left arrow is pressed go back
				this.$refs[`input${index - 1}`][0].focus()
			}

			if(e.key === 'ArrowRight' && index < this.length-1) { // when right arrow is pressed go forward
				this.$refs[`input${index + 1}`][0].focus()
			}
		}
	},
	emits: ['code']
}
</script>

<style scoped>

.parking-code-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 0rem;
}


.parking-code-desc {
  font-size: 0.8rem;
  color: #6c757d;
  text-align:center;
}

.parking-code-con {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
}

.parking-code {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.parking-code input {
  width: 50px;
  height: 50px;
  border: 1px solid #ced4da;
  border-radius: 5px;
  text-align: center;
  font-size: 1.5rem;
  margin: 0 5px;
}

.parking-code input:focus {
  outline: none;
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
}

.parking-code input::placeholder {
  color: #6c757d;
  opacity: 1;
}

.parking-code input:-ms-input-placeholder {
  color: #6c757d;
}

.parking-code input::-ms-input-placeholder {
  color: #6c757d;
}

.parking-code input[type="text"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.parking-code input[type="text"]::-webkit-outer-spin-button,
.parking-code input[type="text"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.showParkingButton {
	white-space: wrap;
	text-align: left;
	padding: .25rem 1.25rem;
}

</style>
