<template>
	<div
		class="alert media align-items-center"
		:class="{
			'alert-info': level == 'info', 
			'alert-warning': level == 'warning',
			'alert-danger': level == 'error'
		}"
	>
		<font-awesome-icon icon="info-circle" size="2x" class="mr-3" v-if="level == 'info'" />
		<font-awesome-icon icon="exclamation-triangle" size="2x" class="mr-3" v-if="level == 'warning'" />
		<font-awesome-icon icon="times-circle" size="2x" class="mr-3" v-if="level == 'error'" />
		<div class="media-body text-left">
			<slot />
		</div>
	</div>
</template>

<script>

export default {
	name: 'Alert',
	props: {
		level: {
			type: String,
			default: 'info'
		}
	}
}
</script>