<template>
	<div class="no-shadow-container overflow-hidden" :style="maxWidth ? { 'max-width': maxWidth + 'px' } : {}">
		<div class="index-container" v-if="displayIndex">
			{{ index + 1 + '.' }}
		</div>
		<h2 class="card-title my-0 py-3 charge-card-title">{{ round2(data.consumerEnergyInKw) }} kWh</h2>
		<div class="centered my-3" v-if="data.licencePlate">
			<b>{{ $t('charge-card.licence-plate') }}:</b>
			<p class="mb-0">{{ data.licencePlate }}</p>
		</div>
		<div class="mt-3">
			<div class="charge-card-text-area px-3">
				<div class="charge-card-row">
					<p class="mb-0">{{ $t('charge-card.date') }}:</p>
					<p class="mb-0">{{ data.chargeEnd }}</p>
				</div>

				<div class="charge-card-row">
					<p class="mb-0">{{ $t('charge-card.charge-time') }}:</p>
					<p class="mb-0">{{ data.chargeDurationInMinutes }} perc</p>
				</div>

				<div class="charge-card-row">
					<p class="mb-0">{{ (loggedInUserData && loggedInUserData.casualUser) || casualCharge ? $t('charge-card.pointsCouldGet') : $t('charge-card.points') }}:</p>
					<p class="mb-0">{{ data.earnedPoints }} pont</p>
				</div>

				<div class="charge-card-row" v-if="data.parkingPrice > 0">
					<p class="mb-0">{{ $t('charge-card.charge-cost') }}:</p>
					<p class="mb-0">{{ data.priceOfCharging }} forint</p>
				</div>

				<div class="charge-card-row" v-if="data.parkingPrice > 0">
					<p class="mb-0">{{ $t('charge-card.parking-cost') }}:</p>
					<p class="mb-0">{{ data.parkingPrice }} forint</p>
				</div>

				<div class="charge-card-row">
					<p class="mb-0">{{ $t('charge-card.sent-price') }}:</p>
					<p class="mb-0">{{ data.grossPrice }} forint</p>
				</div>

				<div class="charge-card-row" v-if="data.car">
					<p class="mb-0">{{ $t('charge-card.car') }}:</p>
					<p class="mb-0">{{ data.car }}</p>
				</div>

				<div class="mt-3">
					{{ data.charger }}
				</div>

				<div class="mt-4 text-center centered" style="margin-bottom: 2rem" v-if="data.chargerId">
					<PrimeButton @click="startNewCharge(data)" :style="{ 'border-radius': '30px', 'min-height': '52px', 'min-width': '200px', padding: '0px 20px', 'background-color': 'var(--newbtn-green)', border: 'none' }" class="new-btn centered">
						<i class="pi pi-bolt"></i>
						{{ $t('charge-card.startNewCharge') }}
					</PrimeButton>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'ChargeCard',
	props: ['data', 'casualCharge', 'displayIndex', 'index', 'maxWidth'],
	data() {
		return {
			app: null
		};
	},
	asyncComputed: {
		async loggedInUserData() {
			return await this.$store.getters.getLoggedInUserData;
		}
	},
	methods: {
		round2(num) {
			return +(Math.round(num + 'e+2') + 'e-2');
		},
		startNewCharge(data) {
			this.app.logoutCasualUserIfNeeded(true);
			this.$router.push({ name: 'index', params: { command: 'showLocation', locationId: data.locationId, tab: 'charger' } });
		}
	},
	created() {
		this.app = this.$root.$children[0];
	}
};
</script>

<style scoped>
/* .index-container {
	border-bottom: 1px solid gray;
	display: inline-block;
	margin-top: 10px;

} */

.index-container {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: var(--newbtn-green);
	color: white;
	width: 23px;
	height: 23px;
	border-radius: 50%;
	margin-top: 10px;
	margin-left: 10px;
}

.charge-card-text-area {
	font-size: 1.1rem;
	max-width: 35rem;
	margin: auto;
	display: flex;
	flex-direction: column;
	gap: 12px;
}

.charge-card-row {
	/* border: 1px solid rgb(231, 231, 231); */
	background-color: rgb(248, 248, 248);
	border: 1px solid rgb(247, 243, 243);
	border-radius: 12px;
	padding: 5px;
	border-radius: 5px;
	margin-bottom: 2px;
	display: flex;
	justify-content: space-between;
}

.charge-card-title {
	border-bottom: 1px solid rgb(223, 223, 223);
	/* background-color: rgb(248, 248, 248); */
	padding-bottom: 10px;
}
</style>
