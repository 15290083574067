<template>
	<div class="warning-container mt-2 mx-1" style="font-size: 1.1em; text-align: center">
		<i class="pi pi-exclamation-triangle" style="font-size: 1.4rem"></i>
		<span class="mx-1" style="font-size: 1.5em">{{ $t('noResult') }}</span>
	</div>
</template>

<script>
export default {
	name: 'NoResult'
};
</script>
