<template>
	<div class="no-shadow-container px-3" :style="maxWidth ? { 'max-width': maxWidth + 'px' } : {}">
		<div class="index-container" v-if="displayIndex">
			{{ index + 1 + '.' }}
		</div>
		<h1 class="my-3">{{ time }}</h1>
		<div class="">
			<div>
				<div class="row-container">
					<div class="gray-row">
						<p class="mb-0">{{ $t('parking-card.dateStart') }}:</p>
						<p class="mb-0" v-if="data.parkingFrom">{{ formatDate(data.parkingFrom) }}</p>
					</div>
					<div class="gray-row">
						<p class="mb-0">{{ $t('parking-card.parking-cost') }}:</p>
						<p class="mb-0">{{ data.amount }} forint</p>
					</div>
				</div>

				<div class="my-4 text-center">
					{{ data.parkingLot.location.address.full }}
				</div>

				<div class="mt-4 mb-2 lastcharge-btns" style="gap: 1rem" v-if="data.parkingLot.id">
					<!-- <button @click="startNewPark(data)" class="btn btn-success">
							<font-awesome-icon icon="parking" />
							{{ $t('parking-card.startNewPark') }}
						</button> -->
					<PrimeButton @click="startNewPark(data)" style="background-color: var(--active)" :style="{ 'border-radius': '30px', 'min-height': '52px', 'min-width': '200px', padding: '0px 20px', border: 'none' }" class="new-btn centered">
						<font-awesome-icon icon="parking" />
						{{ $t('parking-card.startNewPark') }}
					</PrimeButton>

					<!-- <button @click="startNewSurcharge(data)" class="btn btn-warning">
							<font-awesome-icon icon="parking" />
							{{ $t('parking-card.startSurcharge') }}
						</button> -->

					<PrimeButton @click="startNewSurcharge(data)" :style="{ 'border-radius': '30px', 'min-height': '52px', 'min-width': '200px', padding: '0px 20px', 'background-color': '#facc15', color: 'black', border: 'none' }" class="new-btn centered">
						<font-awesome-icon icon="parking" />
						{{ $t('parking-card.startSurcharge') }}
					</PrimeButton>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'ParkingCard',
	props: ['data', 'casualCharge', 'displayIndex', 'index', 'maxWidth'],
	data() {
		return {
			app: null
		};
	},
	created() {
		this.app = this.$root.$children[0];
	},
	asyncComputed: {
		async loggedInUserData() {
			return await this.$store.getters.getLoggedInUserData;
		}
	},
	computed: {
		time() {
			const start = new Date(this.data.parkingFrom);
			const end = new Date(this.data.parkingTo);
			const diff = end - start;
			const minutes = Math.floor(diff / 1000 / 60);

			let hours = Math.floor(minutes / 60);
			let minutesLeft = minutes - hours * 60;

			if (isNaN(hours)) hours = 0;
			if (isNaN(minutesLeft)) minutesLeft = 0;

			if (hours === 0) {
				return `${minutesLeft} perc`;
			}

			return `${hours} óra ${minutesLeft} perc`;
		}
	},
	methods: {
		formatDate(dateStr) {
			const date = new Date(dateStr);
			const formattedDate = `${date.getFullYear()}.${this.pad(date.getMonth() + 1)}.${this.pad(date.getDate())} ${this.pad(date.getHours())}:${this.pad(date.getMinutes())}`;
			return formattedDate;
		},
		round2(num) {
			return +(Math.round(num + 'e+2') + 'e-2');
		},
		pad(num) {
			return String(num).padStart(2, '0');
		},
		startNewPark(data) {
			this.app.logoutCasualUserIfNeeded(true);
			this.$router.push({ name: 'index', params: { command: 'showLocation', locationId: data.parkingLot.location.id } });
		},
		startNewSurcharge(data) {
			this.$router.push({ name: 'parking-surcharge', query: { email: this.data.email, parkCode: data.chargeCode } });
		}
	}
};
</script>

<style scoped>
/* .index-container {
	border-bottom: 1px solid gray;
	display: inline-block;
	margin-top: 10px;

} */

.index-container {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: var(--active);
	color: white;
	width: 23px;
	height: 23px;
	border-radius: 50%;
	margin-top: 10px;
	margin-left: 10px;
}

.row-container {
	display: flex;
	flex-direction: column;
	gap: 5px;
}

.lastcharge-btns {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	width: 90%;
	margin: 0 auto;
	padding-bottom: 20px;
	justify-content: center;
}
</style>
