<template>
	<div class="billing-data-editor shadow-container">
		<h4>
			{{ $t('billingDataEditor.headerAdd') }}
		</h4>
		<div class="form-group">
			<label>{{ $t('billingDataEditor.typeLabel') }}:</label>
			<div>
				<input id="type_person" type="radio" value="PERSON" v-model="billingData.type" class="form-check-input" @change="onTypeChanged()" />
				<label for="type_person" class="form-check-label">{{ $t('billingDataEditor.type.person') }}</label>
			</div>
			<div>
				<input id="type_company" type="radio" value="COMPANY" v-model="billingData.type" class="form-check-input" @change="onTypeChanged()" />
				<label for="type_company" class="form-check-label">{{ $t('billingDataEditor.type.company') }}</label>
			</div>
			<ComponentMessage :msgs="msgs" forComponent="type" />
		</div>
		<div class="row" v-if="billingData.type">
			<template v-if="billingData.type == 'PERSON'">
				<div class="col-md-6 form-group">
					<label for="lastName">{{ $t('billingDataEditor.lastName') }}: *</label>
					<TextInput id="lastName" v-model="billingData.lastName" :msgs="msgs" class="form-control" maxlength="255" :required="true" />
					<ComponentMessage :msgs="msgs" forComponent="lastName" />
				</div>

				<div class="col-md-6 form-group">
					<label for="firstName">{{ $t('billingDataEditor.firstName') }}: *</label>
					<TextInput id="firstName" v-model="billingData.firstName" :msgs="msgs" class="form-control" maxlength="255" :required="true" />
					<ComponentMessage :msgs="msgs" forComponent="firstName" />
				</div>
			</template>

			<template v-else>
				<div class="col-12 form-group">
					<label for="payerName">{{ $t('billingDataEditor.companyName') }}: *</label>
					<TextInput id="payerName" v-model="billingData.payerName" :msgs="msgs" class="form-control" maxlength="255" :required="true" />
					<ComponentMessage :msgs="msgs" forComponent="payerName" />
				</div>

				<div class="col-12 form-group" v-if="billingData.type == 'COMPANY'">
					<label for="taxNumber">{{ $t('billingDatas.taxNumber') }}: *</label>
					<TextInput id="taxNumber" v-model="billingData.taxNumber" :msgs="msgs" class="form-control" maxlength="255" :required="true" />
				</div>
				<ComponentMessage :msgs="msgs" forComponent="taxNumber" />
			</template>

			<div class="col-md-6 form-group">
				<label for="emailAddress">{{ $t('billingDataEditor.email') }}: *</label>
				<EmailInput id="emailAddress" v-model="billingData.emailAddress" :msgs="msgs" class="form-control" maxlength="255" :required="true" />
				<ComponentMessage :msgs="msgs" forComponent="emailAddress" />
			</div>

			<div class="col-md-6 form-group">
				<label for="phone">{{ $t('billingDataEditor.phone') }}: *</label>
				<PhoneInput id="phone" v-model="billingData.phone" :msgs="msgs" class="form-control" maxlength="255" :required="true" />
				<ComponentMessage :msgs="msgs" forComponent="phone" />
			</div>

			<div class="col-md-4 form-group">
				<label for="country">{{ $t('billingDataEditor.country') }}: *</label>
				<span class="p-fluid">
					<AutoComplete id="country" :msgs="msgs" style="max-height: 53%" maxlength="255" :required="true" v-model="selectedCountry" :suggestions="filteredCountries" :forceSelection="true" :autoHighlight="true" :dropdown="true" @complete="searchCountry($event)" field="name" />
					<ComponentMessage :msgs="msgs" forComponent="country" />
				</span>
			</div>

			<div class="col-md-4 form-group">
				<label for="zipCode">{{ $t('billingDataEditor.zipCode') }}: *</label>
				<NumberInput id="zipCode" v-model="billingData.address.zipCode" :msgs="msgs" class="form-control" maxlength="12" :required="true" />
				<ComponentMessage :msgs="msgs" forComponent="zipCode" />
			</div>

			<div class="col-md-4 form-group">
				<label for="city">{{ $t('billingDataEditor.city') }}: *</label>
				<TextInput id="city" v-model="billingData.address.city" :msgs="msgs" class="form-control" maxlength="255" :required="true" />
				<ComponentMessage :msgs="msgs" forComponent="city" />
			</div>

			<div class="col-md-3 form-group">
				<label for="street">{{ $t('billingDataEditor.street') }}: *</label>
				<TextInput id="street" v-model="billingData.address.street" :msgs="msgs" class="form-control" maxlength="255" :required="true" />
				<ComponentMessage :msgs="msgs" forComponent="street" />
			</div>

			<div class="col-md-3 form-group">
				<label for="addressType">{{ $t('billingDataEditor.addressType') }}: *</label>
				<span class="p-fluid" v-if="$i18n.locale == 'hu'">
					<AutoComplete id="addressType" :msgs="msgs" style="max-height: 53%" maxlength="255" :required="true" v-model="billingData.address.addressType" :suggestions="filteredAddressTypes" :forceSelection="true" :autoHighlight="true" :dropdown="true" @complete="searchAddressType($event)" :placeholder="$t('billingDataEditor.addressTypePlaceholder')" />
				</span>
				<TextInput id="addressType" v-model="billingData.address.addressType" :msgs="msgs" class="form-control" maxlength="255" :required="true" :placeholder="$t('billingDataEditor.addressTypePlaceholder')" v-else />
				<ComponentMessage :msgs="msgs" forComponent="addressType" />
			</div>

			<div class="col-md-2 form-group">
				<label for="houseNumber">{{ $t('billingDataEditor.houseNumber') }}: *</label>
				<TextInput id="houseNumber" v-model="billingData.address.houseNumber" :msgs="msgs" class="form-control" maxlength="255" :required="true" />
				<ComponentMessage :msgs="msgs" forComponent="houseNumber" />
			</div>

			<div class="col-md-2 form-group">
				<label for="floor">{{ $t('billingDataEditor.floor') }}:</label>
				<input id="floor" v-model.trim="billingData.address.floor" class="form-control" />
			</div>

			<div class="col-md-2 form-group">
				<label for="door">{{ $t('billingDataEditor.door') }}:</label>
				<input id="door" v-model.trim="billingData.address.door" class="form-control" />
			</div>

			<div class="col-12 media form-group">
				<input id="defaultBillingData" type="checkbox" v-model="billingData.defaultBillingData" class="switch form-check-input" />
				<label for="defaultBillingData" class="form-check-label media-body">{{ $t('billingDataEditor.defaultBillingData') }}</label>
			</div>

			<div class="col-12 form-group text-right">
				<small>{{ $t('profile.mandatoryLabel') }}</small>
			</div>
		</div>

		<div :class="billingData.type ? 'centered' : ''">
			<PrimeButton @click="$emit('cancel')" :style="{ 'border-radius': '6px', 'min-height': '40px', padding: '0px 20px', 'background-color': '#ef4444', border: 'none' }" class="new-btn centered">
				<i class="pi pi-arrow-left"></i>
				{{ $t('billingDataEditor.cancel') }}
			</PrimeButton>
			<PrimeButton v-if="billingData.type" @click="save" style="background-color: var(--active)" :style="{ 'border-radius': '6px', 'min-height': '40px', padding: '0px 20px' }" class="new-btn centered">
				{{ $t('billingDataEditor.save') }}
				<i class="pi pi-check-circle"></i>
			</PrimeButton>
		</div>
	</div>
</template>

<script>
import AutoComplete from 'primevue/autocomplete/AutoComplete';

export default {
	name: 'BillingDataEditor',
	props: {
		billingData: Object,
		loggedInUserData: Object,
		msgs: Object,
		shouldBeDefault: Boolean
	},
	components: { AutoComplete },
	emits: ['save', 'cancel', 'chooseType'],
	data() {
		return {
			addressTypes: ['árok', 'átjáró', 'dűlő', 'erdősor', 'fasor', 'forduló', 'gát', 'határsor', 'határút', 'kapu', 'körönd', 'körtér', 'körút', 'köz', 'lakótelep', 'lejáró', 'lejtő', 'lépcső', 'liget', 'mélyút', 'orom', 'ösvény', 'park', 'part', 'pincesor', 'rakpart', 'sétány', 'sikátor', 'sor', 'sugárút', 'tér', 'udvar', 'út', 'utca', 'üdülőpart'],
			countries: [],
			selectedCountry: null,
			filteredCountries: [],
			filteredAddressTypes: []
		};
	},
	methods: {
		save() {
			if (this.selectedCountry) {
				this.billingData.address.countryName = this.selectedCountry.name;
				this.billingData.address.countryCode = this.selectedCountry.code;
			} else {
				this.billingData.address.countryName = null;
				this.billingData.address.countryCode = null;
			}
			this.$emit('save');
		},
		searchCountry(event) {
			this.filteredCountries = [];
			const lang = localStorage.getItem('Language');
			const queryString = event.query;

			if (event.query) {
				this.countries.forEach((country) => {
					const name = country.name
						.trim()
						.toLowerCase()
						.normalize('NFD')
						.replace(/\p{Diacritic}/gu, '');

					let foundAll = true;
					for (let frag of event.query.split(' ')) {
						let _frag = frag
							.trim()
							.toLowerCase()
							.normalize('NFD')
							.replace(/\p{Diacritic}/gu, '');
						if (_frag.length > 0 && name.indexOf(_frag) < 0) {
							foundAll = false;
						}
					}

					if (foundAll) {
						this.filteredCountries.push({ name: country.name, code: country.alpha2code });
					}
				});
			} else {
				this.countries.forEach((country) => {
					this.filteredCountries.push({ name: country.name, code: country.alpha2code });
				});
			}

			this.filteredCountries.sort((c1, c2) => c1.name.localeCompare(c2.name));
		},
		searchAddressType(event) {
			if (!event.query) {
				this.filteredAddressTypes = this.addressTypes;
				return;
			}
			this.filteredAddressTypes = [];
			const lang = localStorage.getItem('Language');
			for (let at of this.addressTypes) {
				let atl = at
					.trim()
					.toLowerCase()
					.normalize('NFD')
					.replace(/\p{Diacritic}/gu, '');

				let foundAll = true;
				for (let frag of event.query.split(' ')) {
					let _frag = frag
						.trim()
						.toLowerCase()
						.normalize('NFD')
						.replace(/\p{Diacritic}/gu, '');
					if (_frag.length > 0 && atl.indexOf(_frag) < 0) {
						foundAll = false;
					}
				}

				if (foundAll) {
					this.filteredAddressTypes.push(at);
				}
			}
		},
		onTypeChanged() {
			if (this.loggedInUserData && this.billingData.type == 'PERSON') {
				if (!this.billingData.firstName) {
					this.billingData.firstName = this.loggedInUserData.firstName;
				}
				if (!this.billingData.lastName) {
					this.billingData.lastName = this.loggedInUserData.lastName;
				}
			} else {
				this.billingData.firstName = null;
				this.billingData.lastName = null;
			}
			this.$emit('chooseType');
		}
	},
	mounted() {
		this.$rest.getCountries((response) => {
			this.countries = response;
		});
		if (this.billingData) {
			if (this.loggedInUserData) {
				if (this.loggedInUserData.phone && !this.billingData.phone) {
					this.billingData.phone = this.loggedInUserData.phone;
				}

				if (!this.billingData.emailAddress && this.loggedInUserData.email) {
					this.billingData.emailAddress = this.loggedInUserData.email;
				}
				if (!this.billingData.firstName && this.loggedInUserData.firstName) {
					this.billingData.firstName = this.loggedInUserData.firstName;
				}
				if (!this.billingData.lastName && this.loggedInUserData.lastName) {
					this.billingData.lastName = this.loggedInUserData.lastName;
				}
			}
			if (this.billingData.address && this.billingData.address.countryName) {
				this.selectedCountry = { name: this.billingData.address.countryName, code: this.billingData.address.countryCode };
			}
			if (this.shouldBeDefault) {
				this.billingData.defaultBillingData = true;
			}
		}
	}
};
</script>
<style scoped>
.billing-data-editor {
	margin-top: 50px;
}

.cancel-save-btn {
	display: flex;
	justify-content: space-between;
}
</style>
