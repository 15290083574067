<template>
	<form class="register-form">
		<p class="register-title">{{ $t('register.registerButton') }}</p>
		<div class="row">
			<div class="col-sm-6 form-group">
				<label for="email">{{ $t('register.email') }}: *</label>
				<EmailInput id="email" v-model="email" :msgs="msgs" class="form-control login-input" maxlength="255" :required="true" />
				<ComponentMessage :msgs="msgs" forComponent="email" />
			</div>

			<div class="col-sm-6 form-group">
				<label for="phone">{{ $t('register.phone') }}: *</label>
				<PhoneInput id="phone" v-model="phone" :msgs="msgs" class="form-control login-input" maxlength="255" :required="true" />
				<ComponentMessage :msgs="msgs" forComponent="phone" />
			</div>
		</div>

		<div class="row">
			<div class="col-sm-6 form-group">
				<label for="password">{{ $t('register.password') }}: *</label>
				<PasswordInput id="password" v-model="password" :msgs="msgs" class="form-control login-input" maxlength="255" />
				<ComponentMessage :msgs="msgs" forComponent="password" />

				<small class="text-center">{{ $t('invalid.password') }}</small>
			</div>

			<div class="col-sm-6 form-group">
				<label for="password2">{{ $t('register.password2') }}: *</label>
				<PasswordInput id="password2" v-model="password2" :msgs="msgs" class="form-control login-input" maxlength="255" :password1="password" />
				<ComponentMessage :msgs="msgs" forComponent="password2" />
			</div>
		</div>

		<div class="form-group">
			<label for="captcha">{{ $t('register.captcha') }}: *</label>
			<div class="d-flex flex-wrap align-items-center">
				<div v-if="code">
					<div v-if="expired">{{ $t('register.captchaExpired') }}</div>
					<img :src="$rest.baseUrl + '/security/image?id=' + code" class="img-fluid" v-else />
				</div>
				<button class="btn ml-auto mr-0 p-0 pl-2" @click="getCode()">
					<font-awesome-icon icon="sync" size="lg" class="m-0" />
					{{ $t('register.captchaNewButton') }}
				</button>
			</div>
			<template v-if="code">
				<div>
					<small>{{ $t('register.captchaMessage') }}:</small>
				</div>
				<TextInput id="captcha" v-model="inputCode" class="form-control login-input" />
			</template>
			<ComponentMessage :msgs="msgs" forComponent="captcha" />
		</div>

		<div class="media">
			<input id="accepted" type="checkbox" v-model="accepted" class="switch form-check-input" />
			<i18n path="register.acceptMessage" tag="label" for="accepted" class="gdpr-container form-check-label media-body">
				<template v-slot:privacy-statement>
					<a href="javascript:void(0)" @click="showPrivacyPolicy()" class="px-2 py-0">{{ $t('aboutUs.privacyStatement') }}</a>
				</template>
				<template v-slot:gtc>
					<a href="javascript:void(0)" @click="showGTC()" class="px-2 py-0">{{ $t('aboutUs.termsAndConditions') }}</a>
				</template>
			</i18n>
		</div>
		<ComponentMessage :msgs="msgs" forComponent="accepted" />

		<div class="form-group text-center">
			<small>{{ $t('register.mandatoryLabel') }}</small>
		</div>

		<div class="register-btn-container">
			<PrimeButton @click="register()" type="submit" class="centered" style="background-color: var(--newbtn-blue)" :style="{ width: '100%', height: '50px', 'border-radius': '6px', 'min-height': '40px', padding: '0px 20px' }">
        <i class="pi pi-sign-in"></i>
				{{ $t('register.registerButton') }}
      </PrimeButton>
		</div>
	</form>
</template>

<script>
import GtcView from '@/views/GtcView.vue';
import GtcENView from '@/views/GtcENView.vue';
import PrivacyStatementView from '@/views/PrivacyStatementView.vue';
import PrivacyStatementENView from '@/views/PrivacyStatementENView.vue';

export default {
	name: 'RegisterView',
	data() {
		return {
			app: null,
			email: '',
			phone: '',
			password: '',
			password2: '',
			accepted: false,
			chargerId: null,
			gateId: null,
			chargerId: null,
			code: '',
			inputCode: '',
			expiresAt: null,
			timer: null,
			expired: false
		};
	},
	props: {
		msgs: Object
	},
	methods: {
		showPrivacyPolicy() {
			let components = [{ component: this.$i18n.locale == 'hu' ? PrivacyStatementView : PrivacyStatementENView }];

			this.app.showBSModal(components, this.$t('aboutUs.privacyStatement'), null, true);
		},
		showGTC() {
			let components = [{ component: this.$i18n.locale == 'hu' ? GtcView : GtcENView }];

			this.app.showBSModal(components, this.$t('aboutUs.termsAndConditions'), null, true);
		},
		register() {
			this.$rest.register(this.email, this.phone, this.password, this.password2, this.accepted, this.chargerId, this.gateId, this.code, this.inputCode, (responseData) => {
				this.$router.replace('login');
			});
		},
		async getCode() {
			this.code = await this.$rest.generateSecurityCode(this.code);
			const now = new Date();
			this.expired = false;
			this.expiresAt = new Date(now.getTime() + 14 * 60000);
		},
		killTimer() {
			if (this.timer) {
				clearInterval(this.timer);
				this.timer = null;
			}
		},
		createTimer() {
			this.killTimer();
			this.timer = setInterval(() => {
				if (this.expiresAt !== null) {
					const now = new Date();
					this.expired = now.getTime() >= this.expiresAt.getTime();
				}
			}, 15000);
		}
	},
	created() {
		this.app = this.$root.$children[0];
		this.chargerId = this.$route.params.chargerId;
		this.gateId = this.$route.params.gateId;
	},
	mounted() {
		this.getCode();
		this.createTimer();
	},
	beforeDestroy() {
		this.killTimer();
	}
};
</script>
<style scoped>
@media (min-width: 320px) {
	.register-form {
		margin-top: 30px;
	}

  .login-input {
    border-radius: 6px;
    min-height: 42px;
  }

	.register-title {
		text-align: center;
		font-size: 24px;
		font-weight: 500;
		margin-bottom: 30px;
	}

	.register-btn-container {
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
		margin: 20px auto 30px auto;
	}

	.register-btn {
		width: 100%;
		justify-content: center;
		height: 50px;
	}

	.gdpr-container {
		text-align: center;
		font-size: 12px;
	}
}

@media (min-width: 450px) {
	.register-form {

		border-radius: 10px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		max-width: 800px;
		margin: 0 auto;
    padding: 40px;
		background-color: white;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
    border: 1px solid rgb(236, 236, 236);
	}

	.register-btn-container {
		max-width: 450px;
	}
}
</style>
