import Vue from 'vue';

let wsUrl = 'wss://app.freecharger.hu/rest/update';

if (window.location.href.indexOf('http://localhost') === 0 || window.location.href.indexOf('file:///Users/') === 0) {
	wsUrl = 'ws://local.freecharger.hu:8080/rest/update';
} else if (window.location.href.indexOf('https://teszt.freecharger.hu') === 0) {
	wsUrl = 'wss://teszt.freecharger.hu/rest/update';
} else {
	wsUrl = 'wss://app.freecharger.hu/rest/update';
}
// wsUrl = 'ws://localhost:8080/rest/update';
wsUrl = 'wss://teszt.freecharger.hu/rest/update';

let ws;
let pingCheckTimer;
let subscriptions = {};
let onOpenCallbacks = [];
let onCloseCallbacks = [];

function connect() {
	const email = localStorage.getItem('loggedInEmail');
	const token = localStorage.getItem('loggedInUserToken');
	const accessToken = localStorage.getItem('loggedInAccessToken');

	let authorization = null;
	let type = 'basic';
	if (email && token) {
		authorization = btoa(`${email}:${token}`);
	} else if (accessToken) {
		type = 'bearer';
		authorization = btoa(accessToken);
	}

	ws = new WebSocket(`${wsUrl}?authorization=${authorization || ''}&type=${type}`);
	ws.onopen = () => {
		console.log('WS OPEN');

		onOpenCallbacks.forEach((callback) => {
			try {
				callback();
			} catch (e) {
				console.log('WS ERROR', callback);
				console.error(e);
			}
		});

		pingCheckTimer = setInterval(() => {
			send({
				msgType: 'command',
				command: 'ping'
			});
		}, 6000);
	};

	ws.onclose = () => {
		console.log('WS CLOSE');

		onCloseCallbacks.forEach((callback) => {
			try {
				callback();
			} catch (e) {
				console.log('WS ERROR', callback);
				console.error(e);
			}
		});

		clearInterval(pingCheckTimer);
		setTimeout(connect, 1000);
	};

	ws.onmessage = (event) => {
		let msg = JSON.parse(event.data);
		if (!subscriptions[msg.topic]) {
			return;
		}

		subscriptions[msg.topic].forEach((callback) => {
			try {
				callback(msg.data);
			} catch (e) {
				console.log('WS ERROR', callback, msg.topic);
				console.error(e);
			}
		});
	};

	ws.onerror = (event) => {
		console.log('WS ERROR', event);
	};
}

function disconnect() {
	clearInterval(pingCheckTimer);
	ws.close();
}

function subscribe(topic, callback) {
	if (!subscriptions[topic]) {
		subscriptions[topic] = [];
	}

	subscriptions[topic].push(callback);

	send({
		msgType: 'subscribe',
		topic: topic,
		userData: {
			browser: navigator.userAgent,
			language: navigator.language
		}
	});
}

function unsubscribe(topic, callback) {
	if (!subscriptions[topic]) {
		return;
	}

	let index = subscriptions[topic].indexOf(callback);
	if (index !== -1) {
		subscriptions[topic].splice(index, 1);
	}

	if (subscriptions[topic].length === 0) {
		delete subscriptions[topic];
		send({
			msgType: 'unsubscribe',
			topic: topic
		});
	}
}

function onOpen(callback) {
	onOpenCallbacks.push(callback);
}

function onClose(callback) {
	onCloseCallbacks.push(callback);
}

function removeOnOpen(callback) {
	const index = onOpenCallbacks.indexOf(callback);
	if (index !== -1) {
		onOpenCallbacks.splice(index, 1);
	}
}

function removeOnClose(callback) {
	const index = onCloseCallbacks.indexOf(callback);
	if (index !== -1) {
		onCloseCallbacks.splice(index, 1);
	}
}

/**
 * @param {string} command
 * @param {any} [msg]
 */
function command(command, msg) {
	send({
		msgType: 'command',
		command: command,
		msg: msg
	});
}

/**
 * @param {({
 *   msgType: ('command'|'subscribe'|'unsubscribe'),
 *   topic: string?
 *   command: string?,
 *   msg: any?
 * })} msg
 */
function send(msg) {
	if (!ws) {
		console.log('WS NOT CONNECTED');
		setTimeout(() => {
			send(msg);
		}, 1000 + Math.random() * 1000);
		return;
	}
	if (ws.readyState === 1) {
		ws.send(JSON.stringify(msg));
	} else {
		console.log('WS NOT READY');
	}
}

const _ws = {
	connect,
	disconnect,
	subscribe,
	unsubscribe,
	send,
	command,
	onOpen,
	onClose,
	removeOnOpen,
	removeOnClose
};

const WebSocketManager = {
	install(Vue, options) {
		Object.defineProperty(Vue.prototype, '$ws', {
			get() {
				return _ws;
			}
		});
	}
};

Vue.use(WebSocketManager);
if (typeof window !== 'undefined' && window.Vue) {
	window.Vue.use(WebSocketManager);
}

export default _ws;
