<template>
	<div class="charging-survey mt-3">
		<div class="warning-container mx-3 form-group text-center">
			<div>
				<i class="pi pi-exclamation-triangle" :style="'font-size:1.2rem'"></i>
				{{ $t('casual-charging.messageLogin') }}
			</div>
			<!-- <div class="mt-2">
				<router-link to="/login" class="btn btn-success">
					<font-awesome-icon icon="sign-in-alt" />
					{{ $t('index.continueToLogin') }}
				</router-link>
			</div> -->
		</div>

		<div class="login-nologin">
			<div @click="navigate('/login')" class="login-nologin-item" :class="isLargeScreen ? 'flex2' : ''">
				<div class="item-title-active centered">
					<i class="pi pi-sign-in" style="font-size: 1.6rem"></i>
					<h3 class="py-2">
            {{$t('charger-view.start-charge-with-login')}}
					</h3>
				</div>
				<div class="mt-5">
					<router-link to="/login">
						<PrimeButton :style="{ 'background-color': 'var(--newbtn-blue)' }" class="d-flex gap-2 mx-auto mb-3 p-button-rounded p-button-raised">
							<i class="pi pi-sign-in"></i>
							<span>{{$t('login.loginButton')}}</span>
						</PrimeButton>
					</router-link>
				</div>
				<ul class="text-left list-unstyled my-3 d-flex flex-column" :style="{ width: '90%', gap: '20px' }">
					<li class="w-full d-flex align-items-center" :style="{ gap: '10px' }">
						<i class="pi pi-check-circle" style="color: green"></i>
						<p class="mb-0">{{$t('charger-view.receipt')}}</p>
					</li>
					<li class="w-full d-flex align-items-center" :style="{ gap: '10px' }">
						<i class="pi pi-check-circle" style="color: green"></i>
						<p class="mb-0">
              {{$t('charger-view.charging-history')}}
						</p>
					</li>
					<li class="w-full d-flex align-items-center" :style="{ gap: '10px' }">
						<i class="pi pi-check-circle" style="color: green"></i>
						<p class="mb-0">
              {{$t('charger-view.all-possible-amounts')}}
						</p>
					</li>
					<li class="w-full d-flex align-items-center" :style="{ gap: '10px' }">
						<i class="pi pi-check-circle" style="color: green"></i>
						<p class="mb-0">
              {{$t('charger-view.charge-by-time')}}
            </p>
					</li>
					<li class="w-full d-flex align-items-center" :style="{ gap: '10px' }">
						<i class="pi pi-check-circle" style="color: green"></i>
						<p class="mb-0">
              {{$t('charger-view.collect-coupon')}}
						</p>
					</li>
				</ul>
			</div>
			<div @click="selectOnetimeCharge()" class="login-nologin-item" :class="isLargeScreen ? 'flex1' : ''">
				<div class="item-title green-linear centered">
					<i class="pi pi-bolt" style="font-size: 2rem"></i>
					<h3 class="py-2">
            {{$t('charger-view.start-charge-without-login')}}
          </h3>
				</div>
				<div class="mt-5">
					<PrimeButton :style="{ 'background-color': 'var(--mode-color)', border: 'none' }" class="d-flex gap-2 mx-auto mb-3 p-button-rounded p-button-raised">
						<i class="pi pi-bolt"></i>
						<span>{{ $t('casual-charging.buttonStart') }}</span>
					</PrimeButton>
				</div>
				<ul class="text-left list-unstyled my-3 d-flex flex-column" :style="{ width: '90%', gap: '10px' }">
					<li class="w-full d-flex align-items-center" :style="{ gap: '10px' }">
						<i class="pi pi-check-circle" style="color: green"></i>
						<p class="mb-0">
              {{$t('charger-view.e-receipt')}}
            </p>
					</li>
					<li class="w-full d-flex align-items-center" :style="{ gap: '10px' }">
						<i class="pi pi-info-circle" style="color: var(--sky-500)"></i>
						<div>
							<p class="mb-0">{{$t('charger-view.least-5000')}}</p>
							<small class="text-center">{{$t('charger-view.least-5000-small')}}</small>
						</div>
					</li>
					<li class="w-full d-flex align-items-center" :style="{ gap: '10px' }">
						<i class="pi pi-ban" style="color: red"></i>
						<p class="mb-0">{{$t('charger-view.receipt')}}</p>
					</li>
					<li class="w-full d-flex align-items-center" :style="{ gap: '10px' }">
						<i class="pi pi-ban" style="color: red"></i>
						<p class="mb-0">
              {{$t('charger-view.collect-coupon')}}
            </p>
					</li>
				</ul>
			</div>
		</div>

		<div class="mx-auto centered flex-column my-4" :style="{ 'max-width': '300px' }">
			<p class="mb-0"> {{$t('charger-view.no-account')}}</p>
			<router-link to="/register" :style="{ display: 'flex', 'justify-content': 'center', 'align-items': 'center', gap: '3px' }">
				<PrimeButton :style="{ 'border-radius': '30px', 'min-height': '45px', 'min-width': '170px', padding: '0px 20px', 'background-color': '#fde047', color: 'black', border: 'none' }" class="new-btn centered">
					<i class="pi pi-user"></i>
					<span>{{$t('charger-view.register')}}</span>
				</PrimeButton>
			</router-link>
		</div>

		<!-- <h3 class="text-center">{{ $t('casual-charging.header') }}</h3> -->

		<!-- <div class="form-group text-center">
			{{ $t('casual-charging.messageProvideData') }}
		</div> -->

		<div class="form-group text-center">
			<!-- <div class="mb-1">
				<small>{{ $t('casual-charging.messagePoints') }}</small>
			</div> -->
			<!-- <label>{{ $t('casualSurvey.wantToCollectPoints') }}</label>
			<div>
				<button @click="setCollectPoints(true)" class="btn btn-success mr-2" :class="{ 'btn-inactive': collectPoints === false }">
					<font-awesome-icon icon="check" />
					{{ $t('yes') }}
				</button>
				<button @click="setCollectPoints(false)" class="btn btn-danger" :class="{ 'btn-inactive': collectPoints === true }">
					<font-awesome-icon icon="times" />
					{{ $t('no') }}
				</button>
			</div> -->
		</div>

		<!-- <div class="form-group text-center mt-2">
			<div class="mb-1">
				<small>{{ $t('casual-charging.messageInvoice') }}</small>
			</div>
			<label>{{ $t('casualSurvey.wantToGetInvoice') }}</label>
			<div>
				<button @click="setGettingInvoice(true)" class="btn btn-success mr-2" :class="{ 'btn-inactive': gettingInvoice === false }">
					<font-awesome-icon icon="check" />
					{{ $t('yes') }}
				</button>
				<button @click="setGettingInvoice(false)" class="btn btn-danger" :class="{ 'btn-inactive': gettingInvoice === true }">
					<font-awesome-icon icon="times" />
					{{ $t('no') }}
				</button>
			</div>
			<a name="step2"></a>
		</div> -->

		<div class="form-group text-center mt-2" v-if="onetimeCharge">
			<ParkingAlertPanel :priceSettings="priceSettings" />

			<div v-if="!priceSettings.freeCharging">
				<div :style="{ 'max-width': '90%', margin: '0 auto' }">
					<PaymentSummaryCard :data="paymentSummary" :price-settings="priceSettings" />
				</div>

				<div class="form-group text-left d-flex flex-column mb-3" :style="{ 'max-width': '90%', margin: '0 auto', gap: '5px' }">
					<div class="blockquote-footer step-list">
						{{ $t('charger-view.amount-disclaimer-1') }}
					</div>
					<div class="blockquote-footer step-list">
						{{ $t('charger-view.amount-disclaimer-2') }}
					</div>
					<div class="blockquote-footer step-list">
						{{ $t('charger-view.amount-disclaimer-3') }}
					</div>
					<div class="blockquote-footer step-list">
						{{ $t('charger-view.amount-disclaimer-4') }}
					</div>
					<div class="blockquote-footer step-list" v-if="priceSettings.convenienceFeeGross > 0">
						<b>{{ $t('charger-view.amount-disclaimer-5', [priceSettings.convenienceFeeGross]) }}</b>
					</div>
					<div class="blockquote-footer step-list">
						<b>{{ $t('charger-view.amount-disclaimer-6', [priceSettings.unitChargingGross]) }}</b>
					</div>
				</div>
			</div>

			<ParkingCodeInput class="my-3" @code="($e) => (parkCode = $e)" v-if="charger.locationHasParking" style="margin-left: -21px; margin-right: -21px" />

			<!-- <EmailInput id="email" v-model="email" :msgs="msgs" class="form-control email-input" maxlength="255" :placeholder="$t('casualSurvey.email')" :required="true" /> -->
			<div :style="{ 'max-width': '90%', margin: '0 auto' }">
				<div class="email-pass-container" :style="{ 'max-width': '400px', margin: '0 auto' }">
					<label :style="{ 'font-weight': 'normal' }">{{ $t('casualSurvey.addEmailAddress') }}</label>
					<input type="text" v-model="email" class="form-control" autocorrect="off" autocapitalize="none" />
				</div>
				<ComponentMessage :msgs="msgs" forComponent="email" />

				<a name="step3"></a>

				<PrimeButton @click="sendEmail()" class="w-100 centered gap-2 mx-auto my-3 p-button-raised" :style="{ 'min-height': '50px', 'max-width': '400px', margin: '0 auto', 'background-color': 'var(--mode-color)', border: 'none' }">
					<i class="pi pi-bolt"></i>
					{{ $t('casual-charging.buttonStart') }}
				</PrimeButton>
			</div>

			<!-- <button @click="sendEmail()" class="btn btn-success mt-3" style="margin: auto">
				<font-awesome-icon icon="play" />
				{{ $t('casual-charging.buttonStart') }}
			</button> -->
		</div>
	</div>
</template>

<script>
import * as utils from '@/plugins/utils.js';
import ParkingAlertPanel from '@/components/ParkingAlertPanel.vue';
import PaymentSummaryCard from '@/components/PaymentSummaryCard.vue';
import ParkingCodeInput from '@/components/ParkingCodeInput.vue';

export default {
	name: 'CasualChargingSurvey',
	props: {
		msgs: Object,
		priceSettings: Object,
		charger: Object
	},
	data() {
		return {
			app: null,
			collectPointsAnswered: false,
			collectPoints: null,
			gettingInvoicePointsAnswered: false,
			gettingInvoice: null,
			chargerId: null,
			email: null,
			parkCode: '',
			paymentSummary: {
				reservedEnergy: this.calculateKwFromPrice(5000),
				time: null,
				amount: 5000
			},
			isLargeScreen: false,
			onetimeCharge: false
		};
	},
	components: {
		ParkingCodeInput,
		ParkingAlertPanel,
		PaymentSummaryCard
	},
	methods: {
		navigate(path) {
			this.$router.push(path);
		},
		checkScreenSize() {
			this.isLargeScreen = window.innerWidth > 725;
		},
		onCode(code) {
			this.parkCode = code;
		},
		selectOnetimeCharge() {
			this.onetimeCharge = true;
			this.scrollToBottom();
		},
		scrollToBottom() {
			setTimeout(() => {
				window.scrollTo({ top: document.body.scrollHeight, left: 0, behavior: 'smooth' });
			}, 0);
		},
		setGettingInvoice(wantToGetInvoice) {
			this.gettingInvoice = wantToGetInvoice;
			this.gettingInvoicePointsAnswered = true;
			if (wantToGetInvoice) {
				this.$router.push({ name: 'register', arams: { chargerId: this.chargerId } });
			} else {
				utils.scrollToAnchor('step3');
			}
		},
		sendEmail() {
			this.$rest.startCasualCharging(
				this.chargerId,
				this.email,
				this.parkCode,
				(responseData) => {
					if (responseData.result) {
						if (responseData.extraData) {
							localStorage.setItem('loggedInUserId', responseData.extraData.data.user.id);
							localStorage.setItem('loggedInEmail', responseData.extraData.data.user.email);
							localStorage.setItem('loggedInUserToken', responseData.extraData.data.token);
							this.app.setLoggedInUserData(responseData.extraData.data.user);
						}
						if (responseData.data) {
							window.location.href = responseData.data;
						} else {
							this.$router.push('/charge-history');
						}
					}
				},
				(error) => {
					if (error && error.response && error.response.data) {
						this.showErrorMessage(error);
					} else {
						alert(this.$t('casual-charging.errorStart'));
					}
				}
			);
		},
		showErrorMessage(error) {
			if (error.response.data.responseCode === 'CHARGER_NOT_ACTIVE') {
				alert(this.$t('charger-view.charger-not-available'));
			} else if (error.response.data.responseCode === 'SIMPLEPAY_INVALID_PASSWORD') {
				alert(this.$t('charger-view.wrong-secret-code'));
			} else if (error.response.data.responseCode === 'NO_ACTIVE_TARIFF') {
				alert(this.$t('charger-view.no-tariff'));
			} else {
				alert(this.$t('charger-view.unsuccessful-start'));
			}
		},
		calculateKwFromPrice(price) {
			const kw = (price - this.priceSettings.convenienceFeeGross) / this.priceSettings.unitChargingGross;
			return kw.toFixed(2);
		}
	},
	created() {
		this.app = this.$root.$children[0];
		this.chargerId = this.$route.params.chargerId;
	},
	beforeMount() {
		this.checkScreenSize();
		window.addEventListener('resize', this.checkScreenSize);
	}
};
</script>

<style scoped>
.charging-survey {
	padding: 0;
}

.btn-inactive {
	opacity: 0.5;
}

.email-input {
	border: 2px solid var(--mode-color);
	background-color: #c2edd2;
	max-width: 320px;
	margin: auto;
}

.email-input::placeholder {
	color: #0b6b2e;
	opacity: 0.75;
}

.email-input:-ms-input-placeholder {
	color: #0b6b2e;
}

.email-input::-ms-input-placeholder {
	color: #0b6b2e;
}

.login-nologin {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	gap: 20px;
	padding: 0;
	border-radius: 10px;
}

.login-nologin-item {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	min-height: 592px;
	width: 90%;
	min-width: 270px;
	max-width: 345px;
	border: 1px solid rgb(225, 225, 225);
	border-radius: 6px;
	overflow: hidden;
	cursor: pointer;
	transition: all 0.2s ease-in-out;
}

.login-nologin-item:hover {
	transform: scale(1.03);
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}

.login-nologin-item .item-title {
	background-color: var(--mode-color);
	color: white;
	padding: 0 10px;
	height: 200px;
	width: 100%;
	background: linear-gradient(to bottom left, var(--nologged-in-card1), var(--nologged-in-card2));
	border-radius: 100% 0% 100% 0% / 0% 50% 50% 100%;
	display: flex;
	flex-direction: column;
	place-items: center;
}

.login-nologin-item .item-title-active {
	background-color: var(--active);
	color: white;
	padding: 0 10px;
	height: 200px;
	width: 100%;
	background: linear-gradient(to bottom left, #32a0ea, #5e7ef2);
	border-radius: 100% 0% 100% 0% / 0% 50% 50% 100%;
	display: flex;
	flex-direction: column;
	place-items: center;
}

.login-nologin-item h3 {
	margin: 0;
	font-weight: normal;
	font-size: 1.5rem;
	color: rgb(250, 250, 250);
}
</style>

<style>
.card {
	background: transparent;
}

.flex1 {
	order: 1;
}

.flex2 {
	order: 2;
}

@media screen and(min-width: 450px) {
	.charging-survey {
		padding: 1rem;
	}

	.login-nologin {
		padding: 20px;
	}
}
</style>
