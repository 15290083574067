<template>
	<div class="support-container">
		<div class="mx-auto text-center mb-5" style="max-width: 480px">
			<img alt="FreeCharger" src="@/assets/logo.svg" class="img-fluid" />
		</div>
		<table>
			<tr>
				<td class="table-label">
					{{ $t('support-view.phone') }}
				</td>
				<td>
					<a class="d" href="tel:+36203678407">
						<font-awesome-icon icon="phone" size="lg" class="mr-2" />
						+36 20 367 84 07
					</a>
				</td>
			</tr>

			<tr>
				<td class="table-label">
					{{ $t('support-view.email') }}
				</td>
				<td>
					<a href="mailto:support@freecharger.hu">
						<font-awesome-icon icon="envelope" size="lg" class="mr-2" />
						support@freecharger.hu
					</a>
				</td>
			</tr>
		</table>
	</div>
</template>
<script>
export default {
	name: 'SupportView'
};
</script>
<style scoped>
@media (min-width: 320px) {
	.support-container {
		min-width: 80%;
		max-width: 1000px;
		min-height: 500px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		margin: 0 auto;
		padding: 10px;
		background-color: white;
		border-radius: 5px;
		box-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
	}

	table {
		margin-left: auto;
		margin-right: auto;
	}

	.table-label {
		font-weight: 400;
	}

	td:first-child {
		padding-right: 1rem;
		text-align: right;
	}

	tr > td {
		padding-top: 1rem;
	}

	tr:first-child > td {
		padding-top: 0;
	}
}
</style>
