import Vue from 'vue';

const formatter = new Intl.NumberFormat('hu-HU', { style: 'currency', currency: 'HUF', maximumFractionDigits: 0 });

export function formatCurrency(number) {
	//this function gets a number and return a formatted Ft value
	// 500 --> 500 Ft
	// 10000 --> 10.000 Ft
	return formatter.format(number);
}

export default Vue.filter('formatCurrency', formatCurrency);
