<template>
	<div class="newspost-modal">
		<div v-for="post in posts" v-bind:key="post.id" class="mb-5 border-bottom ">
			<img :src="`${$rest.baseUrl}/file?type=news&subdir=${post.id}/&name=${post.secondaryImage}&random=${Math.random()}`" class="post-image" v-if="post.secondaryImage" />
			<div class="my-3 overflow-hidden">
				<h3 class="mb-0">{{ post.title }}</h3>
				<small class="text-muted">{{ post.date }}</small>
				<p class="text-muted" v-if="!post.content || post.content.trim().length == 0">{{ post.description }}</p>
				<div class="mt-2" v-html="post.content"></div>

				<button class="btn btn-success float-right" @click="markPostAsRead(post)" v-if="post.onlyDisappearsWhenClicked">
					<font-awesome-icon icon="check"/> Többet ne jelenjen meg
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import NewsPostView from '@/views/NewsPostView.vue'

export default {
	name: 'NewsPostModal',
	components: { NewsPostView },
	data() {
		return {
			posts: null
		}
	},
	props: {
		parent: Object,
		news: Array
	},
	methods: {
		markPostAsRead(post) {
			for(let i = this.posts.length - 1; i >= 0; i--) {
				if(post.id == this.posts[i].id) {
					this.posts.splice(i, 1)

					const shownNewsMap = {}
					const shownNewsList = []

					for(const nId of (localStorage.getItem('shownNews') || '').split(',')) {
						shownNewsMap[nId] = true
						shownNewsList.push(nId)
					}

					if(!shownNewsMap[post.publicationId]) {
						shownNewsList.push(post.publicationId)
					}
					
					localStorage.setItem('shownNews', shownNewsList.join(','))

					if(this.posts.length == 0) {
						this.$emit('event', 'newsListEmptied')
					}

					return
				}
			}
		}
	},
	created() {
		this.posts = this.news
	},
	mounted() {
		//console.log("modal", this.news)
	},
	emits: ['newsListEmptied']
}
</script>

<style scoped>
.newspost-modal {
	overflow-y: auto;
	height: 80vh;
}

.post-image {
	width: 100%;
	max-height: 300px;
	object-fit: cover;
	object-position: center;
	flex: 0;
}

@media screen and (max-width: 576px) {
	.post-image {
		max-height: 200px;
	}
}
</style>
