<template>
	<div v-if="cardAlert && cardAlertVisible" id="cardRegResult" class="warning-container alert-dismissible fade show" role="alert">
		<div>
			<strong>{{ cardAlert.header }}</strong>
		</div>
		<div style="white-space: pre-line">{{ cardAlert.text }}</div>
	</div>
</template>

<script>
export default {
	name: 'CreditCardsProcessAlert',
	data() {
		return {
			cardAlert: null,
			cardAlertVisible: false
		};
	},
	methods: {
		parseSimplePayResponseFromUrl() {
			const responseBase64 = this.$route.query.r;
			//console.log(1, this.$route.query.r)
			if (responseBase64) {
				const responseJson = JSON.parse(atob(responseBase64));
				const status = responseJson.e;
				let header = '';
				let result = '';
				const orderId = responseJson.o;
				const transactionId = responseJson.t;
				if (status === 'CANCEL') {
					header = this.$t('cards.denied-payment');
					result = this.$t('cards.stopped-payment');
				} else if (status === 'TIMEOUT') {
					header = this.$t('cards.denied-payment');
					result = this.$t('cards.transaction-timeout');
				} else if (status === 'FAIL') {
					header = this.$t('cards.denied-transaction');
					result = `${this.$t('cards.simplepay-transaction-id')} ${transactionId}
                    ${this.$t('cards.receipt-id')} ${orderId}
                    ${this.$t('cards.please-check-transaction-details')}`;
				} else if (status === 'SUCCESS') {
					header = this.$t('cards.successful-payment');
					result = `${this.$t('cards.simplepay-transaction-id')} ${transactionId}
                    ${this.$t('cards.receipt-id')} ${orderId}`;
				} else {
					result = this.$t('cards.unknown-status');
				}
				this.cardAlert = {
					header: header,
					text: result
				};
				this.cardAlertVisible = true;
			}
		}
	},
	beforeMount() {
		this.parseSimplePayResponseFromUrl();
	}
};
</script>
