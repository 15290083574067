<template>
	<transition :leave-active-class="fullscreen ? 'animate__animated animate__fadeOut' : ''">
		<div
			class="alert media"
			:class="{
				'alert-info': message.severity == 'info',
				'alert-warning': message.severity == 'warn',
				'alert-danger': message.severity == 'error',
				'fullscreen': fullscreen
			}"
			v-if="show"
		>
			<font-awesome-icon
				:icon="message.severity == 'info' ? 'info-circle' : 'exclamation-triangle'"
				class="fa-2x mr-3"
			/>
			<div class="media-body align-self-center">
				{{ message.text }}
			</div>
		</div>
	</transition>
</template>

<script>

export default {
	name: 'Message',
	data() {
		return {
			show: true
		}
	},
	props:  {
		message: Object,
		fullscreen: Boolean
	},
	mounted() {
		if(this.fullscreen) {
			setTimeout(() => {
				this.show = false
			}, 5000)
		}
	}
}
</script>

<style>
	.alert.alert-danger {
		background-color: #df2726;
		color: #fff;
	}

	.alert.fullscreen {
		width: 320px;
		margin: 15px auto 0 auto;
		opacity: 0.95;
	}
</style>
