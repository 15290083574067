<template>
	<div>
		<router-link to="/news" class="btn btn-outline-secondary mr-2 mb-2">
			<font-awesome-icon icon="chevron-left"/>
			{{ $t('newsView.back') }}
		</router-link>
		<div v-if="post != null">
			<img :src="`${$rest.baseUrl}/file?type=news&subdir=${post.id}/&name=${post.secondaryImage}&random=${Math.random()}`" class="post-image" v-if="post.secondaryImage" />
			<div class="mt-3">
				<h3 class="mb-0">{{ post.title }}</h3>
				<small class="text-muted">{{ post.date }}</small>
				<p class="text-muted" v-if="!post.content || post.content.trim().length == 0">{{ post.description }}</p>

				<div class="mt-5" v-html="post.content">

				</div>
			</div>
		</div>
	</div>
</template>

<style scoped>
.post-image{
  width: 100%;
  max-height: 300px;
  object-fit: cover;
  object-position: center;
  flex: 0;
}

@media screen and (max-width: 576px) {
  .post-image{
    max-height: 200px;
  }
}
</style>

<script>

export default {
	name: 'NewsPostView',
	data() {
		return {
			post: null,
		}
	},
	props: {
		msgs: Object
	},
	methods: {
		loadNewsPost() {
			this.id = this.$route.params.id
			this.$rest.loadNewsPost(this.id, (data) => {
				this.post = data
			})
		}
	},
	beforeMount() {
		this.loadNewsPost()
	}
}
</script>
