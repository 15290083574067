import Vue from 'vue';
import App from './App.vue';
import router from './router';
import i18n from './i18n';

import { store } from './store/store';
import PrimeVue from 'primevue/config';

import './plugins/fontawesome';
import './plugins/axios';
import './plugins/ws';

import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import 'animate.css/animate.min.css';

import './css/main.css';

import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';

import FastClick from 'vue-fastclick';
FastClick.attach(document.body);

Vue.config.productionTip = false;

import ComponentMessage from './components/ComponentMessage.vue';
Vue.component('ComponentMessage', ComponentMessage);

import TextInput from './components/TextInput.vue';
Vue.component('TextInput', TextInput);

import NumberInput from './components/NumberInput.vue';
Vue.component('NumberInput', NumberInput);

import DecimalInput from './components/DecimalInput.vue';
Vue.component('DecimalInput', DecimalInput);

import EmailInput from './components/EmailInput.vue';
Vue.component('EmailInput', EmailInput);

import PhoneInput from './components/PhoneInput.vue';
Vue.component('PhoneInput', PhoneInput);

import PasswordInput from './components/PasswordInput.vue';
Vue.component('PasswordInput', PasswordInput);



import Alert from './components/Alert.vue';
Vue.component('Alert', Alert);

Vue.use(PrimeVue);

import AutoComplete from 'primevue/autocomplete/AutoComplete';
Vue.component('AutoComplete', AutoComplete);

import Button from 'primevue/button/Button';
Vue.component('PrimeButton', Button);

import Password from 'primevue/password/Password';
Vue.component('Password', Password);

import AsyncComputed from 'vue-async-computed';
Vue.use(AsyncComputed);

import ConfirmationService from 'primevue/confirmationservice';
Vue.use(ConfirmationService);

import vueDebounce from 'vue2-debounce';

import VueCarousel from 'vue-carousel';
Vue.use(VueCarousel);

function setCookie(name, value, days) {
	var expires = '';
	if (days) {
		var date = new Date();
		date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
		expires = '; expires=' + date.toUTCString();
	}
	document.cookie = name + '=' + (value || '') + expires + '; path=/';
}

function getCookie(name) {
	var nameEQ = name + '=';
	var ca = document.cookie.split(';');
	for (var i = 0; i < ca.length; i++) {
		var c = ca[i];
		while (c.charAt(0) == ' ') c = c.substring(1, c.length);
		if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
	}
	return null;
}

window.setCookie = setCookie;
window.getCookie = getCookie;

export function getParameterByName(name, url = window.location.href) {
	name = name.replace(/[[\]]/g, '\\$&');
	var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
		results = regex.exec(url);
	if (!results) return null;
	if (!results[2]) return '';
	return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

window.platform = 'web';
if (window.location.href.indexOf('file:///') === 0) {
	let platform = getParameterByName('platform');
	if ('ios' === platform || 'android' === platform) {
		window.platform = platform;
	}
}

window.loadAppLink = function (link) {
	var iframe = document.createElement('iframe');
	iframe.setAttribute('src', link);
	document.documentElement.appendChild(iframe);
	iframe.parentNode.removeChild(iframe);
};

const modalContent = Vue.observable({ modalContent: [] });
Object.defineProperty(Vue.prototype, '$modalContent', {
	get() {
		return modalContent.modalContent;
	},
	set(value) {
		modalContent.modalContent = value;
	}
});

Vue.use(vueDebounce, {
	listenTo: ['input', 'keyup'],
	defaultTime: '500ms'
});

new Vue({
	store: store,
	router,
	i18n,
	render: (h) => h(App)
}).$mount('#app');
